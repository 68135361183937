import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  EditIconV2,
  SettingIcon,
  CopyIconV2,
  OpenNewTab,
  ShareIconV2,
  CheckCircleIcon,
} from "@share/icons";
import { Button, Image, Toast } from "antd-mobile";
import { useAppSelector } from "@app/hooks";
import { COUNTRY_PH, PACKAGE_TYPE } from "@share/configs/const";
import FullScreenPopup from "@share/components/full-screen-popup";
import PersonalDomainSetting from "../body/component/SiteSetting/PersonalDomainSetting";
import PersonalBrandSetting from "../body/component/SiteSetting/PersonalBrandSetting";
import gaEvents, {
  ACTION_CONFIG,
  CATEGORY_CONFIG,
  EVENT_CONFIG,
} from "@share/lib/ga-events";
import { handleCapitalizeFirstLetter } from "@share/lib";
import cls from "classnames";
import { SETTING_TOUR_CLASS_NAME } from "../body/component/SettingTourOpening/SettingTour/setting-tour-config";
import SettingTourOpening from "../body/component/SettingTourOpening";
import style from "./index.module.scss";
import {
  CardAtmIcon,
  CrownFreeIcon,
  CrownIcon,
  CrownPremiumIcon,
  DollarIcon,
  GlobeIcon,
  SoundIcon,
  SwatchBookIcon,
} from "@share/icons/solid-icon";
import { CoppyAltIcon, EditAltIcon, ShareIcon } from "@share/icons/line-icon";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import UpgradeAccount from "@features/design/components/UpgradeAccount";
import PopupFull from "@share/components/PopupFull";

const Heading = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state?.user);
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);
  const websiteConfig = useAppSelector((state) => state.websiteConfig);
  const urlFavicon = websiteConfig?.favicon;
  const metaTitle = websiteConfig?.meta_title;
  const metaDescription = websiteConfig?.meta_description;
  const kol = user?.kol;
  const domain = kol?.domain;
  const country = kol?.country;
  const package_type = kol?.package_type || null;
  const [openSite, setOpenSite] = useState(false);
  const [openFavicon, setOpenFavicon] = useState(false);
  const [isOpenPopupUpgradeAccount, setIsOpenPopupUpgradeAccount] =
    useState(false);
  const [packageType, setPackageType] = useState(package_type);

  const handleClickToLink = (link) => {
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.SETTINGS,
      `Settings_${handleCapitalizeFirstLetter(
        ACTION_CONFIG.VIEW_SITE_IN_BROWSER
      )}`,
      domain,
      ACTION_CONFIG.VIEW_SITE_IN_BROWSER
    );
    const castWindow = window as any;
    if (castWindow.flutter_inappwebview !== undefined) {
      const args = [{ url: `https://${link}` }];
      castWindow.flutter_inappwebview.callHandler("openInBrowser", ...args);
    } else {
      window.open(`https://${link}`, "_blank");
    }
  };

  const handleCopy = (domain) => {
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.SETTINGS,
      `Settings_${handleCapitalizeFirstLetter(ACTION_CONFIG.COPY_DOMAIN)}`,
      domain,
      ACTION_CONFIG.COPY_DOMAIN
    );
    const castWindow = window as any;
    const text = `https://${domain}`;
    const args2 = [{ text: text }];
    if (castWindow.flutter_inappwebview !== undefined) {
      castWindow.flutter_inappwebview.callHandler("copyToClipboard", ...args2);
    } else {
      navigator?.clipboard.writeText(`https://${domain}`);
    }
  };

  const handleShare = (url) => {
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.SETTINGS,
      `Settings_${handleCapitalizeFirstLetter(ACTION_CONFIG.SHARE_SITE)}`,
      domain,
      ACTION_CONFIG.SHARE_SITE
    );
    const castWindow = window as any;
    const args2 = [{ url }];
    if (castWindow.flutter_inappwebview !== undefined) {
      castWindow.flutter_inappwebview.callHandler("share", ...args2);
    } else if (!!navigator?.share) {
      navigator?.share({
        title: metaTitle,
        text: metaDescription,
        url: `https://${domain}`,
      });
    }
  };

  const handleClickFaviconIcon = () => {
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.SETTINGS,
      `Settings_${handleCapitalizeFirstLetter(ACTION_CONFIG.FAVICON_ICON)}`,
      domain,
      ACTION_CONFIG.FAVICON_ICON
    );
    setOpenFavicon(true);
  };

  const handleClickEditDomain = () => {
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.SETTINGS,
      `Settings_${handleCapitalizeFirstLetter(ACTION_CONFIG.EDIT_DOMAIN)}`,
      domain,
      ACTION_CONFIG.EDIT_DOMAIN
    );
    setOpenSite(true);
  };
  const listItemSwiper = [
    {
      icon: (
        <CrownIcon
          width={20}
          height={20}
        />
      ),
      title: t("ss_builder_swiper_setting_premium_title_1"),
      description: t("ss_builder_swiper_setting_premium_description_1"),
    },
    {
      icon: (
        <SwatchBookIcon
          width={20}
          height={20}
        />
      ),
      title: t("ss_builder_swiper_setting_premium_title_2"),
    },
    {
      icon: (
        <GlobeIcon
          width={20}
          height={20}
        />
      ),
      title: t("ss_builder_swiper_setting_premium_title_3"),
    },
    {
      icon: (
        <SoundIcon
          width={20}
          height={20}
        />
      ),
      title: t("ss_builder_swiper_setting_premium_title_4"),
    },
    {
      icon: (
        <CardAtmIcon
          width={20}
          height={20}
        />
      ),
      title: t("ss_builder_swiper_setting_premium_title_5"),
    },
    {
      icon: (
        <DollarIcon
          width={20}
          height={20}
        />
      ),
      title: t("ss_builder_swiper_setting_premium_title_6"),
    },
  ];
  const pagination = {
    clickable: true,
  };
  const handleOpenPopupUpgradeAccount = (val) => {
    if (val) {
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.SETTINGS,
        `${EVENT_CONFIG.SETTINGS}_${handleCapitalizeFirstLetter(
          ACTION_CONFIG.SETTING_UPGRADE_PLAN
        )}`,
        "",
        ACTION_CONFIG.SETTING_UPGRADE_PLAN
      );
    }
    setIsOpenPopupUpgradeAccount(val);
  };

  const handleSetPackageType = (val) => {
    setPackageType(val);
  };
  useEffect(() => {
    handleSetPackageType(package_type);
  }, [isOpenPopupUpgradeAccount, package_type]);

  const LabelSettingAccount = () => {
    return (
      <div className={`${style.labelSettingBox}`}>
        <div
          className={cls(
            "flex w-full gap-3 items-center p-4",
            SETTING_TOUR_CLASS_NAME.STEP_1
          )}
        >
          <Image
            src={urlFavicon}
            fit="cover"
            onClick={handleClickFaviconIcon}
            style={{
              "--width": "60px",
              "--height": "60px",
              borderRadius: 8,
            }}
          />
          <div
            className={`d-flex justify-between items-center w-full ${style.headerRight}`}
          >
            <div>
              {packageType === PACKAGE_TYPE.PREMIUM ? (
                <Button
                  size="mini"
                  fill="solid"
                  className={`${style.crown}`}
                  style={{
                    "--border-color": "#FA8C16",
                    "--background-color": "#FA8C16",
                    "--text-color": "var(--adm-color-white)",
                  }}
                >
                  <CrownPremiumIcon />
                  <span className={`${style.btnTextSpan}`}>
                    {t("ss_builder_site_setting_premium")}
                  </span>
                </Button>
              ) : (
                <Button
                  size="mini"
                  fill="solid"
                  className={`${style.crown}`}
                  style={{
                    "--border-color": "var(--adm-color-white)",
                    "--background-color": "var(--adm-color-white)",
                    "--text-color": "#595959",
                  }}
                >
                  <CrownFreeIcon />
                  <span className={`${style.btnTextSpan}`}>
                    {t("ss_builder_site_setting_standard")}
                  </span>
                </Button>
              )}

              <div className={`${style.domain}`}>{domain}</div>
            </div>
            <div
              className={`${style.btnEditDomain}`}
              onClick={handleClickEditDomain}
            >
              <EditAltIcon
                width={20}
                height={20}
                fillColor="#fff"
              />
            </div>
          </div>
        </div>
        {packageType !== PACKAGE_TYPE.PREMIUM && (
          <div className="px-4">
            <div className={`${style.wrapperPremium}`}>
              <div className={`swiperPremium ${style.swiperPremium}`}>
                <Swiper
                  modules={[Pagination]}
                  pagination={pagination}
                >
                  {listItemSwiper?.map((item, index) => (
                    <SwiperSlide>
                      <div className={`${style.itemSwiperPremium}`}>
                        <div className={`${style.icon}`}>{item.icon}</div>
                        <div className={`${style.introItem}`}>
                          <div className={`${style.titleItem}`}>
                            {item.title}
                          </div>
                          {item.description && (
                            <div className={`${style.descriptionItem}`}>
                              {item.description}
                            </div>
                          )}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>

              <div className={`${style.btnUpgradeBox}`}>
                <Button
                  size="mini"
                  fill="solid"
                  className={`${style.btnUpgrade}`}
                  onClick={() => handleOpenPopupUpgradeAccount(true)}
                  style={{
                    "--border-radius": "999px",
                    "--background-color": "#FA8C16",
                    "--border-color": "#FA8C16",
                    "--text-color": "var(--adm-color-white)",
                  }}
                >
                  {t("ss_builder_site_setting_upgrade")}
                </Button>
              </div>
            </div>
          </div>
        )}

        <div className={`${style.action}`}>
          <div
            className={`cursor-pointer basis-1/3 ${style.actionItem}`}
            onClick={() => {
              handleCopy(domain);
              Toast.show({
                icon: (
                  <CheckCircleIcon
                    className="text-center inline-block w-[30px] h-[30px]"
                    fillColor="#00B578"
                  />
                ),
                content: (
                  <div className="text-center">
                    {t("ss_builder_personal_link_coppy_successfully_label")}
                  </div>
                ),
              });
            }}
          >
            <CoppyAltIcon />
            <div className={`${style.textAction}`}>
              {t("ss_builder_site_setting_coppy_label")}
            </div>
          </div>

          <div
            className={`cursor-pointer basis-1/3 ${style.actionItem}`}
            onClick={() => {
              domain ? handleClickToLink(domain) : null;
            }}
          >
            <GlobeIcon fillColor="#333" />
            <div className={`${style.textAction}`}>
              {t("ss_builder_site_setting_browser_label")}
            </div>
          </div>
          {typeof navigator != "undefined" &&
            typeof navigator?.share === "function" && (
              <div
                className={`cursor-pointer basis-1/3 ${style.actionItem}`}
                onClick={() => {
                  handleShare(`https://${domain}`);
                }}
              >
                <ShareIcon />
                <div className={`${style.textAction}`}>
                  {t("ss_builder_site_setting_share_label")}
                </div>
              </div>
            )}
        </div>
      </div>
    );
  };

  const handleCancelPersonalBrandSetting = () => {
    setOpenFavicon(false);
  };

  return (
    <div style={{ backgroundColor: "#FFFFFF" }}>
      <div
        className="text-lg text-[#333333] font-normal flex text-center items-center gap-1 bg-white p-3 justify-center relative"
        style={{
          borderBottom: "1px solid #EEEEEE",
          padding: "12px 12px 10px 12px",
        }}
      >
        <SettingIcon fillColor="#333333" />
        <div style={{ fontStyle: "18px" }}>
          {t("ss_builder_settings_label")}
        </div>
        <div className="absolute top-[50%] right-3 translate-y-[-50%]">
          <SettingTourOpening />
        </div>
      </div>
      {country === COUNTRY_PH ? (
        <LabelSettingAccount />
      ) : (
        <div className="px-2">
          <div
            className={cls(
              "flex w-full pt-4 pb-3 px-1",
              SETTING_TOUR_CLASS_NAME.STEP_1
            )}
          >
            <Image
              src={urlFavicon}
              fit="cover"
              style={{
                borderRadius: "8px",
                width: "64px",
                height: "64px",
                cursor: "pointer",
              }}
              onClick={handleClickFaviconIcon}
            />

            <div style={{ marginLeft: "12px", flexGrow: 1 }}>
              <div
                className="flex "
                style={{
                  width: "100%",
                  height: "28px",
                  marginBottom: "8px",
                }}
              >
                <div
                  style={{
                    width: "auto",
                    height: "28px",
                    background: "#F5F5F5",
                    borderRadius: "4px",
                    padding: "4px 12px",
                    flexGrow: 1,
                    fontSize: "16px",
                    lineHeight: "1.3",
                  }}
                >
                  {domain}
                </div>
                <span
                  style={{
                    marginLeft: "12px",
                    cursor: "pointer",
                  }}
                  onClick={handleClickEditDomain}
                >
                  <EditIconV2
                    width={28}
                    height={28}
                  />
                </span>
              </div>

              <div className="flex gap-2">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleCopy(domain);
                    Toast.show({
                      icon: (
                        <CheckCircleIcon
                          className="text-center inline-block w-[30px] h-[30px]"
                          fillColor="#00B578"
                        />
                      ),
                      content: (
                        <div className="text-center">
                          {t(
                            "ss_builder_personal_link_coppy_successfully_label"
                          )}
                        </div>
                      ),
                    });
                  }}
                >
                  {" "}
                  <CopyIconV2 />{" "}
                </span>

                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    domain ? handleClickToLink(domain) : null;
                  }}
                >
                  <OpenNewTab />
                </span>
                {typeof navigator != "undefined" &&
                  typeof navigator?.share === "function" && (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleShare(`https://${domain}`);
                      }}
                    >
                      <ShareIconV2 />
                    </span>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}

      <FullScreenPopup
        visible={openSite}
        onMaskClick={() => {
          setOpenSite(false);
        }}
      >
        <PersonalDomainSetting
          onCancelPersonalDomainSetting={() => setOpenSite(false)}
        />
      </FullScreenPopup>
      <FullScreenPopup
        visible={openFavicon}
        onMaskClick={() => {
          setOpenFavicon(false);
        }}
      >
        <PersonalBrandSetting
          onCancelPersonalBrandSetting={handleCancelPersonalBrandSetting}
        />
      </FullScreenPopup>
      <PopupFull
        visible={isOpenPopupUpgradeAccount}
        onMaskClick={() => handleOpenPopupUpgradeAccount(false)}
        reduceMotionAnt={true}
      >
        <UpgradeAccount
          visble={isOpenPopupUpgradeAccount}
          user={user}
          onClose={() => handleOpenPopupUpgradeAccount(false)}
          handleSetPackageType={() => handleSetPackageType}
        />
      </PopupFull>
    </div>
  );
};
export default Heading;
