import { useAppSelector } from "@app/hooks";
import { useHasChange, useOnCancel } from "@features/setting/hooks";
import {
  FONT_AVAILABLE,
  FONT_GOOGLE,
  FONT_OPTIONS,
  PARAGRAPH_SETTING_DEFAULT_VALUE,
} from "@share/configs/const";
import { handleCapitalizeFirstLetter } from "@share/lib";
import {
  CheckCircleIcon,
  DownIcon,
  DropDownIcon1,
  DropDownIcon2,
  RightArrowIcon,
} from "@share/icons";
import { saveThemeParagraphSetting } from "@share/thunk/setting";
import {
  Button,
  Collapse,
  Divider,
  Input,
  Selector,
  Slider,
} from "antd-mobile";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ParagraphIcon, StyleIcon, TIcon } from "../../Icons";
import { ColorPickerV2 } from "../ColorPicker";
import CommonFontSelector from "../CommonFontSelector";
import { FontOptionV2 } from "../FontOption";
import ScaleSeletor, { SCALE_OPTIONS } from "../ScaleSelector";
import SettingHeader from "../SettingHeader";
import * as generateColor from "@share/lib/generateColor";
import Preview from "./components/Preview";
import DisplayColor from "@share/components/DisplayColor";
import gaEvents, { CATEGORY_CONFIG, LABEL_CONFIG } from "@share/lib/ga-events";
import PopupComponent from "@share/components/PopupComponent";
import { useToast } from "@share/hooks/toast";
import { FontGoogle } from "./components/icon";
import styles from "./styele.module.scss";
import cls from "classnames";
import {
  setDataFont,
  setFontLoading,
} from "@features/design/store/externalStateSlice";
import startCase from "lodash/startCase";
import { useDebounce } from "usehooks-ts";

interface ParagraphSettingInteface {
  font_family?: string;
  font_color?: string;
  font_size?: number;
  font_weight?: number;
  line_height?: number;
  scale?: string;
  using_font_custom?: number;
  font_family_custom?: string;
}

export default function ParagraphSetting(props) {
  const { backToAllSetting, type } = props;
  const { t } = useTranslation();
  const { SuccessToast } = useToast();
  const defaultSiteSetting = useAppSelector(
    (state) => state?.siteSettings?.display_setting ?? {}
  );
  const defaultParagraphSetting = useAppSelector(
    (state) => state?.siteSettings?.display_setting?.paragraph_setting ?? {}
  );

  const isValidFont = useAppSelector(
    (state) => state?.externalState?.is_valid_font || false
  );

  const isFontLoading = useAppSelector(
    (state) => state?.externalState?.is_font_loading || false
  );

  const [paragraphSettingData, setParagraphSettingData] =
    useState<ParagraphSettingInteface>(defaultParagraphSetting);

  const fontWeightAvailable = useAppSelector(
    (state) => state.externalState.font_weight_available
  );

  const {
    font_family = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_FAMILY,
    font_color = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_COLOR,
    scale = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_SCALE,
    font_size = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_SIZE,
    font_weight = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_WEIGHT,
    line_height = PARAGRAPH_SETTING_DEFAULT_VALUE.LINE_HEIGHT,
    using_font_custom = PARAGRAPH_SETTING_DEFAULT_VALUE.USING_FONT_CUSTOM,
    font_family_custom = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_FAMILY_CUSTOM,
  } = paragraphSettingData;

  const debounceValueFontCustom = useDebounce(
    using_font_custom === FONT_GOOGLE
      ? startCase(font_family_custom?.toString()?.trim())
      : startCase(font_family?.toString()?.trim()),
    500
  );

  useEffect(() => {
    if (debounceValueFontCustom) {
      dispatch(setFontLoading(true));
    }
  }, [debounceValueFontCustom]);

  const [isOpenFontOption, setIsOpenFontOption] = useState(false);
  const [isOpenTextColorPicker, setIsOpenTextColorPicker] = useState(false);
  const [isOpenScaleSelector, setIsOpenScaleSelector] = useState(false);
  const [hasHandler, setHasHandler] = useState(false);

  useEffect(() => {
    if (!isFontLoading) {
      setHasHandler(false);
    }
  }, [isFontLoading]);

  const hasChange = useHasChange([paragraphSettingData]);

  const isDisabledBtnSave = useMemo(() => {
    if (using_font_custom === FONT_AVAILABLE) {
      return !hasChange;
    }
    return (
      !hasChange ||
      !font_family_custom?.trim() ||
      !isValidFont ||
      isFontLoading ||
      hasHandler
    );
  }, [
    hasChange,
    font_family_custom?.trim(),
    isValidFont,
    isFontLoading,
    hasHandler,
  ]);

  const handleBackToSetting = () => {
    dispatch(
      setDataFont({
        title: "",
        paragraph: "",
      })
    );
    backToAllSetting();
  };
  const onCancel = useOnCancel(hasChange, handleBackToSetting, type);
  const fontFamilyTitle = useMemo(() => {
    const selectedOne = FONT_OPTIONS.find((item) => item.value == font_family);
    return selectedOne?.label ?? font_family;
  }, [font_family]);

  //generate Heading color
  const COLOR_LENGTH_DEFAULT = 9;

  const FONT_SETTING_OPTIONS = [
    {
      label: t("ss_builder_paragraph_setting_font_available"),
      value: FONT_AVAILABLE, // font available = 1
    },
    {
      label: t("ss_builder_paragraph_setting_font_custom"),
      value: FONT_GOOGLE, // font google = 2
    },
  ];

  const opacityTextColor =
    font_color.length == COLOR_LENGTH_DEFAULT ? font_color.slice(-2) : "";
  const parseColorValuesText = generateColor.parseColorValues(font_color);
  const calculateShadesText = generateColor.calculateShades(
    parseColorValuesText[0] ?? font_color.replace("#", "")
  );
  const calculateTintsText = generateColor.calculateTints(
    parseColorValuesText[0] ?? font_color.replace("#", "")
  );
  const calculateShadesAndTintsText = [
    ...calculateTintsText.reverse(),
    ...calculateShadesText,
  ];
  const textColorGenerate = useMemo(() => {
    const textColorObj = {};
    calculateShadesAndTintsText.forEach((ele, index) => {
      textColorObj[`text-color-${index + 1}`] = ele + opacityTextColor;
    });
    return textColorObj;
  }, [calculateShadesAndTintsText]);

  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);

  const onUpdateSettingData = (key, value) => {
    setParagraphSettingData((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const dispatch = useDispatch();

  const onSave = () => {
    dispatch(
      saveThemeParagraphSetting(
        {
          ...paragraphSettingData,
          font_family_custom:
            startCase(paragraphSettingData?.font_family_custom?.trim()) ||
            PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_FAMILY_CUSTOM,
          font_weight:
            paragraphSettingData?.font_weight || fontWeightAvailable[0],
          is_new: true,
        },
        textColorGenerate
      )
    );
    dispatch(
      setDataFont({
        title: "",
        paragraph: "",
      })
    );
    backToAllSetting();
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.THEME_SETTINGS,
      `ThemeSettings_${handleCapitalizeFirstLetter(type)}_${LABEL_CONFIG.EDIT}`,
      LABEL_CONFIG.EDIT,
      handleCapitalizeFirstLetter(type)
    );
    SuccessToast(t("ss_builder_save_succesfully_label"));
  };

  const goToGGFont = () => {
    window.open("https://fonts.google.com/", "_blank");
  };

  const handleChangeFontGG = (val) => {
    onUpdateSettingData("font_family_custom", val);
    setHasHandler(true);
  };

  const marksFontWeight = useMemo(() => {
    if (fontWeightAvailable?.length) {
      const fisrtValue = fontWeightAvailable[0] || 400;
      const lastValue =
        fontWeightAvailable[fontWeightAvailable?.length - 1] || 900;
      return fontWeightAvailable?.reduce((a, b) => {
        if (b === fisrtValue) {
          return { ...a, [b]: t("ss_builder_medium") };
        }
        if (b === lastValue) {
          return { ...a, [b]: t("ss_builder_bold") };
        }
        return { ...a, [b]: "" };
      }, {});
    }
    return { 400: t("ss_builder_medium") };
  }, [fontWeightAvailable]);

  useEffect(() => {
    if (debounceValueFontCustom) {
      dispatch(
        setDataFont({
          title: "",
          paragraph: debounceValueFontCustom,
        })
      );
    }
  }, [debounceValueFontCustom]);

  const fontWeightval = useMemo(() => {
    if (fontWeightAvailable?.includes(font_weight)) {
      return font_weight;
    } else return fontWeightAvailable[0];
  }, [fontWeightAvailable, font_weight]);

  return (
    <>
      <div className="w-full pb-14">
        <SettingHeader
          title={t("ss_builder_paragraph_setting_title")}
          Icon={ParagraphIcon}
        />
        <div className="sticky z-[999] top-0">
          <Preview
            paragraphSettingData={{
              ...paragraphSettingData,
              font_weight: fontWeightval,
              font_family_preview: debounceValueFontCustom,
            }}
            background={defaultSiteSetting?.background || {}}
            blockSetting={defaultSiteSetting?.block_setting || {}}
          ></Preview>
        </div>

        <Collapse
          defaultActiveKey={["style", "font"]}
          accordion={false}
          arrow={(active) => (active ? <DropDownIcon1 /> : <DropDownIcon2 />)}
          className={cls(styles.customCollapse)}
        >
          <Collapse.Panel
            key="style"
            title={
              <>
                <div className="flex">
                  <span className="mr-1">
                    <StyleIcon />
                  </span>
                  <span>{t("ss_builder_theme_setting_block_style")}</span>
                </div>
              </>
            }
          >
            <div className="text-base mb-1">
              {t("ss_builder_paragraph_setting_option")}
            </div>
            <Selector
              options={FONT_SETTING_OPTIONS}
              columns={2}
              onChange={(val) =>
                onUpdateSettingData("using_font_custom", val[0])
              }
              value={[using_font_custom]}
              style={{
                "--border": "solid transparent 1px",
                "--checked-border": "solid var(--adm-color-primary) 1px",
                "--checked-color": "#FFFFFF",
              }}
            />
            <Divider className="!my-3" />

            {using_font_custom === FONT_AVAILABLE ? (
              <div>
                <div className="text-base mb-4">
                  {t("ss_builder_setting_text_font")}
                </div>
                <div
                  className="w-[100%] p-3 bg-[#F5F5F5] rounded border-0 text-[#333333] text-[13px] leading-[17px] flex flex-row justify-between items-center mb-3 cursor-pointer"
                  onClick={() => setIsOpenFontOption(true)}
                >
                  <div style={{ fontFamily: font_family }}>
                    {fontFamilyTitle}
                  </div>
                  <DownIcon className="w-[9px] h-[9px]" />
                </div>
                <div className="mb-3">
                  <div className="text-base mb-4">
                    {t("ss_builder_popular_font_families")}
                  </div>
                  <CommonFontSelector
                    selectedValue={font_family}
                    onSelect={(val) => onUpdateSettingData("font_family", val)}
                  />
                </div>
              </div>
            ) : (
              <div className="relative">
                <div className="flex justify-between text-base">
                  <div>{t("ss_builder_enter_font_name")}</div>
                  <div
                    className="flex gap-2 py-[3px] cursor-pointer"
                    onClick={goToGGFont}
                  >
                    <FontGoogle className="my-auto" />
                    <div className="text-[#333]">
                      {t("ss_builder_google_font")}
                    </div>
                    <RightArrowIcon
                      width={13}
                      height={13}
                      className="my-auto"
                    />
                  </div>
                </div>
                <Input
                  value={font_family_custom}
                  onChange={(value) => handleChangeFontGG(value)}
                  placeholder={t("ss_bp_enter_font_name")}
                />
                {!isValidFont && (
                  <div className="text-[13px] leading-[17px] text-[#FF3141]">
                    {t("ss_builder_font_name_not_exist")}
                  </div>
                )}
                {font_family_custom?.trim() && isValidFont && (
                  <CheckCircleIcon
                    width={24}
                    height={24}
                    className="absolute right-[16px] bottom-[14px] p-1"
                    fillColor="#00B578"
                  />
                )}
                <Divider className="!my-3" />
              </div>
            )}
            <div className="mb-3">
              <div className="text-base mb-4">
                {t("ss_builder_title_color")}
              </div>
              <DisplayColor
                backgroundColor={font_color || "black"}
                onClickOpenColorPicker={setIsOpenTextColorPicker}
              />
            </div>
          </Collapse.Panel>
          <Collapse.Panel
            key="font"
            title={
              <>
                <div className="flex">
                  <span className="mr-1">
                    <TIcon />
                  </span>
                  <span>{t("ss_builder_theme_setting_font_title")}</span>
                </div>
              </>
            }
          >
            <div className="mb-3">
              <div className="text-base mb-4">
                {t("ss_builder_setting_text_size")}
              </div>
              <Slider
                onChange={(val) =>
                  onUpdateSettingData("font_size", parseInt(val.toString()))
                }
                ticks={true}
                marks={{
                  13: 13,
                  14: 14,
                  15: 15,
                  16: 16,
                  17: 17,
                  18: 18,
                  19: 19,
                  20: 20,
                }}
                min={13}
                step={1}
                max={20}
                value={font_size}
              />
            </div>
            <div className="mb-3">
              <div className="text-base mb-4">
                {t("ss_builder_setting_text_scale")}
              </div>
              <div
                className="w-[100%] p-3 bg-[#F5F5F5] rounded border-0 text-[#333333] text-[13px] leading-[17px] flex flex-row justify-between items-center mb-3 cursor-pointer"
                onClick={() => setIsOpenScaleSelector(true)}
              >
                <div>
                  {SCALE_OPTIONS[0].find((item) => item.value == scale)?.label}
                </div>
                <DownIcon className="w-[9px] h-[9px]" />
              </div>
            </div>
            <div className="mb-3">
              <div className="text-base mb-4">
                {t("ss_builder_setting_font_weight")}
              </div>
              <Slider
                onChange={(val) =>
                  onUpdateSettingData("font_weight", parseInt(val.toString()))
                }
                ticks={true}
                marks={marksFontWeight}
                min={fontWeightAvailable[0] || 400}
                max={
                  fontWeightAvailable[fontWeightAvailable?.length - 1] || 900
                }
                value={fontWeightval || 400}
                popover
              />
            </div>
            <div className="mb-3">
              <div className="text-base mb-4">
                {t("ss_builder_setting_line_height")}
              </div>
              <Slider
                onChange={(val) =>
                  onUpdateSettingData("line_height", parseFloat(val.toString()))
                }
                ticks={true}
                marks={{
                  1: 1,
                  1.1: 1.1,
                  1.2: 1.2,
                  1.3: 1.3,
                  1.4: 1.4,
                  1.5: 1.5,
                  1.6: 1.6,
                  1.7: 1.7,
                  1.8: 1.8,
                  1.9: 1.9,
                  2: 2,
                }}
                min={1}
                step={0.1}
                max={2}
                value={line_height}
              />
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
      <div className="max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[10] bg-[#FFFFFF]">
        <div className="w-1/2 text-center">
          <Button
            onClick={onCancel}
            className="adm-button adm-button-default adm-button-shape-default w-full"
          >
            {t("ss_builder_close_panel")}
          </Button>
        </div>
        <div className="w-1/2 text-center">
          <Button
            color="primary"
            disabled={isDisabledBtnSave}
            className="adm-button adm-button-primary adm-button-shape-default w-full"
            onClick={() => onSave()}
          >
            {t("ss_builder_save")}
          </Button>
        </div>
      </div>
      <PopupComponent
        visible={isOpenFontOption}
        onMaskClick={() => setIsOpenFontOption(false)}
        title={t("ss_builder_font_option_panel_title")}
        onClose={() => setIsOpenFontOption(false)}
        classNameBody="h-[60vh] popup-hard"
      >
        <FontOptionV2
          selectedValue={font_family}
          onSelectFont={(val) => onUpdateSettingData("font_family", val)}
          callbackCancelFontOption={() => setIsOpenFontOption(false)}
        />
      </PopupComponent>

      <PopupComponent
        visible={isOpenTextColorPicker}
        onMaskClick={() => setIsOpenTextColorPicker(false)}
        title={t("ss_builder_color_picker_panel_title")}
        onClose={() => setIsOpenTextColorPicker(false)}
        classNameBody="h-[60vh] popup-hard"
      >
        <ColorPickerV2
          defaultColor={font_color}
          onCancel={() => {
            setIsOpenTextColorPicker(false);
          }}
          onColorSelected={(data) => onUpdateSettingData("font_color", data)}
        />
      </PopupComponent>

      <ScaleSeletor
        visible={isOpenScaleSelector}
        value={scale}
        onClose={() => setIsOpenScaleSelector(false)}
        onSelect={(val) => onUpdateSettingData("scale", val)}
      />
    </>
  );
}
