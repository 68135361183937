import { useAppDispatch, useAppSelector } from "@app/hooks";
import { AwardIcon } from "@features/setting/components/Icons";
import { requestSaveSiteSetting, requestUploadImage } from "@share/api";
import {
  CREATOR_FOURTEEN_DEFAULT_ICON,
  PASSIO_DEFAULT_ICON,
} from "@share/configs/const";
import { useToast } from "@share/hooks/toast";
import {
  InformationIcon,
  InformationTriangleIcon,
  TrashIcon,
} from "@share/icons";
import { mockUpload } from "@share/lib";
import gaEvents, {
  ACTION_CONFIG,
  CATEGORY_CONFIG,
  LABEL_CONFIG,
} from "@share/lib/ga-events";
import {
  Button,
  Divider,
  Image,
  ImageUploader,
  Input,
  Modal,
  TextArea,
} from "antd-mobile";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CREATOR_TYPE_ENUM } from "@features/main/store/interface";
import { setWebsiteConfig } from "@features/main/store/websiteConfigSlice";

const HEADING_MAX_LENGTH = 60;
const SITE_TITLE_MAX_LENGTH = 30;
const SUBTITLE_MAX_LENGTH = 155;

type Props = {
  onCancelPersonalBrandSetting: any;
};

const PersonalBrandSetting = (props: Props) => {
  const { onCancelPersonalBrandSetting } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { SuccessToast } = useToast();

  const siteSettings = useAppSelector((state) => state?.siteSettings);
  const websiteConfig = useAppSelector((state) => state?.websiteConfig);
  const faviconDefault = websiteConfig?.favicon;
  const metaTitleDefault = websiteConfig?.meta_title;
  const metaDescriptionDefault = websiteConfig?.meta_description;
  const siteTitleDefault = websiteConfig?.site_title;
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);
  const creatorType = passioPageProfile?.creator_type;
  const [uploadedImageArr, setUploadedImageArr] = useState([]);
  const [valueUrlImage, setValueUrlImage] = useState(faviconDefault);
  const [valueMetaTitle, setValueMetaTitle] = useState(metaTitleDefault);
  const [valueSiteTitle, setValueSiteTitle] = useState(siteTitleDefault);
  const [valueMetaDescription, setValueMetaDescription] = useState(
    metaDescriptionDefault
  );
  const [valueNameImage, setValueNameImage] = useState(null);
  const [valueImageBase64, setValueImageBase64] = useState(null);
  const [isValidFileFormat, setIsValidFileFormat] = useState(false);
  const [isValidFileSize, setIsValidFileSize] = useState(false);
  const [isInvalidMinimumSize, setIsInvalidMinimumSize] = useState(false);

  const isDisableButtonSave = useMemo(() => {
    if (
      valueSiteTitle &&
      valueSiteTitle.trim().length > 0 &&
      valueMetaTitle &&
      valueMetaTitle.trim().length > 0 &&
      valueMetaDescription &&
      valueMetaDescription.trim().length > 0 &&
      (valueMetaTitle !== metaTitleDefault ||
        valueSiteTitle !== siteTitleDefault ||
        valueMetaDescription !== metaDescriptionDefault ||
        valueUrlImage !== faviconDefault)
    ) {
      return false;
    } else return true;
  }, [
    valueSiteTitle,
    siteTitleDefault,
    valueMetaDescription,
    valueMetaTitle,
    metaTitleDefault,
    metaDescriptionDefault,
    faviconDefault,
    valueUrlImage,
  ]);

  const isEditDataStatus = useMemo(() => {
    if (
      valueSiteTitle !== siteTitleDefault ||
      valueMetaTitle !== metaTitleDefault ||
      valueMetaDescription !== metaDescriptionDefault ||
      valueUrlImage !== faviconDefault
    ) {
      return true;
    } else return false;
  }, [
    valueSiteTitle,
    siteTitleDefault,
    valueMetaDescription,
    valueMetaTitle,
    metaTitleDefault,
    metaDescriptionDefault,
    faviconDefault,
    valueUrlImage,
  ]);

  const handleDeleteImage = () => {
    setValueUrlImage(
      creatorType === CREATOR_TYPE_ENUM.FOURTEEN
        ? CREATOR_FOURTEEN_DEFAULT_ICON
        : PASSIO_DEFAULT_ICON
    );
    setValueImageBase64(null);
    setValueNameImage(null);
    setUploadedImageArr([]);
    setIsValidFileFormat(false);
    setIsValidFileSize(false);
    setIsInvalidMinimumSize(false);
  };

  const handleImageUrl = (value) => {
    const imgFileItems = value && value[value.length - 1];
    if (imgFileItems?.isValidFormat) {
      setIsValidFileFormat(true);
      setIsValidFileSize(false);
      setIsInvalidMinimumSize(false);
    } else if (imgFileItems?.isValidSize) {
      setIsValidFileFormat(false);
      setIsValidFileSize(true);
      setIsInvalidMinimumSize(false);
    } else if (imgFileItems?.isInvalidMinimumSize) {
      setIsValidFileFormat(false);
      setIsValidFileSize(false);
      setIsInvalidMinimumSize(true);
    } else {
      setUploadedImageArr(value);
      setValueImageBase64(imgFileItems.dataBase64);
      setValueUrlImage(imgFileItems.url);
      setValueNameImage(imgFileItems.fileName);
      setIsValidFileFormat(false);
      setIsValidFileSize(false);
      setIsInvalidMinimumSize(false);
    }
  };

  const handleSaveSiteSetting = async () => {
    const dataResquestUploadImage = {
      id: siteSettings.id,
      user_id: siteSettings.user_id,
      name: valueNameImage,
      content: valueImageBase64 || valueUrlImage,
    };
    let response = null;
    let responseImage = null;
    if (valueNameImage) {
      try {
        responseImage = await requestUploadImage(dataResquestUploadImage);
      } catch (error) {
        console.log(error);
      }
      if (responseImage) {
        const dataResquest = {
          user_id: siteSettings.user_id,
          data: {
            favicon: responseImage?.url,
            site_title: valueSiteTitle,
            meta_title: valueMetaTitle,
            meta_description: valueMetaDescription,
          },
        };
        try {
          response = await requestSaveSiteSetting(dataResquest);
        } catch (error) {
          console.log(error);
        }
        if (response && /^20[0-9]$/.test(response.status.toString())) {
          updateWebsiteConfigFromResponse(response);
          SuccessToast(t("ss_builder_save_succesfully_label"));
          onCancelPersonalBrandSetting();
        } else handleShowFailAlert();
      }
    } else {
      const dataResquest = {
        user_id: siteSettings.user_id,
        data: {
          favicon: valueUrlImage,
          site_title: valueSiteTitle,
          meta_title: valueMetaTitle,
          meta_description: valueMetaDescription,
        },
      };
      try {
        response = await requestSaveSiteSetting(dataResquest);
      } catch (error) {
        console.log(error);
      }
      if (response && /^20[0-9]$/.test(response.status.toString())) {
        updateWebsiteConfigFromResponse(response);
        SuccessToast(t("ss_builder_save_succesfully_label"));
        onCancelPersonalBrandSetting();
        gaEvents.submitEventV2(
          passioPageProfile,
          CATEGORY_CONFIG.SITE_SETTINGS,
          `SiteSettings_PersonalBrand_${LABEL_CONFIG.EDIT}`,
          LABEL_CONFIG.EDIT,
          ACTION_CONFIG.PERSONAL_BRAND
        );
      } else handleShowFailAlert();
    }
  };

  const updateWebsiteConfigFromResponse = (response) => {
    const newWebsiteConfig = {
      id: response?.data?.id,
      favicon: response?.data?.favicon,
      meta_title: response?.data?.meta_title,
      meta_description: response?.data?.meta_description,
      site_title: response?.data?.site_title,
    };
    dispatch(
      setWebsiteConfig({
        data: newWebsiteConfig,
      })
    );
  };

  const handleShowFailAlert = () => {
    Modal.alert({
      header: (
        <InformationIcon
          className="w-11 h-11"
          fillColor="var(--adm-color-primary)"
          onClick={() => {}}
        />
      ),
      title: <div>{t("ss_builder_theme_setting_save_failed_title")}</div>,
      content: (
        <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
          {t("ss_builder_theme_setting_save_failed_content")}
        </div>
      ),
      confirmText: t("ss_builder_close_panel_edit_text_block"),
      onConfirm: () => {
        return;
      },
    });
  };

  const handleCancelBrandSetting = useCallback(() => {
    if (isEditDataStatus) {
      Modal.confirm({
        header: (
          <InformationIcon
            className="w-11 h-11"
            fillColor="#FF8F1F"
            onClick={() => {}}
          />
        ),
        title: <div>{t("ss_builder_cancel_edit_block_title")}</div>,
        content: (
          <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
            {t("ss_builder_cancel_edit_block_content")}
          </div>
        ),
        confirmText: t("ss_builder_continue_edit_block_label"),
        cancelText: t("ss_builder_cancel_block_btn_label"),
        onConfirm: () => {
          return;
        },
        onCancel: () => {
          gaEvents.submitEventV2(
            passioPageProfile,
            CATEGORY_CONFIG.SITE_SETTINGS,
            `SiteSettings_PersonalBrand_${LABEL_CONFIG.CANCEL}`,
            LABEL_CONFIG.CANCEL,
            ACTION_CONFIG.PERSONAL_BRAND
          );
          onCancelPersonalBrandSetting();
        },
      });
    } else {
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.SITE_SETTINGS,
        `SiteSettings_PersonalBrand_${LABEL_CONFIG.CANCEL}`,
        LABEL_CONFIG.CANCEL,
        ACTION_CONFIG.PERSONAL_BRAND
      );
      onCancelPersonalBrandSetting();
    }
  }, [isEditDataStatus]);

  return (
    <div className="relative h-full">
      <div
        className="text-lg text-[#333333] font-normal flex text-center items-center gap-1 bg-white p-3 justify-center"
        style={{
          boxShadow: "inset 0px -1px 0px #EEEEEE",
        }}
      >
        <AwardIcon className="inline-block" />
        {t("ss_builder_site_brand_setting_label")}
      </div>
      <div className="px-4 py-[13px] bg-white">
        <div className="">
          <div className="text-[15px] leading-[150%] text-[#666666]">
            {t("ss_builder_site_setting_favicon_title")}
          </div>
          <div className="grid grid-cols-[120px_auto] gap-4">
            <Image
              src={valueUrlImage}
              fit="cover"
              width={120}
              height={120}
              style={{
                borderRadius: "8px",
                maxWidth: "120px",
                maxHeight: "120px",
                backgroundColor: "#EEEEEE",
                border: "1px solid #EEEEEE",
              }}
            />
            <div>
              <div className="mb-8 leading-4 text-[#8C8C8C] text-xs">
                {t("ss_builder_site_setting_favicon_upload_description")}
              </div>
              <div className="flex gap-2">
                <Button
                  size="middle"
                  color={
                    valueUrlImage !== PASSIO_DEFAULT_ICON ||
                    valueUrlImage !== CREATOR_FOURTEEN_DEFAULT_ICON
                      ? "primary"
                      : "default"
                  }
                  fill="solid"
                  className=""
                  onClick={handleDeleteImage}
                >
                  <TrashIcon
                    className="w-[17px] h-[17px]"
                    fillColor={
                      valueUrlImage !== PASSIO_DEFAULT_ICON ||
                      valueUrlImage !== CREATOR_FOURTEEN_DEFAULT_ICON
                        ? "#FFFFFF"
                        : "#CCCCCC"
                    }
                  />
                </Button>
                <ImageUploader
                  onChange={handleImageUrl}
                  upload={(file) => mockUpload(file, 256, 256, [], 2, true)}
                  className="flex-grow"
                  value={uploadedImageArr}
                  children={
                    <Button
                      color="primary"
                      fill="solid"
                      size="middle"
                      className="w-full"
                    >
                      {t("ss_builder_upload_button_label")}
                    </Button>
                  }
                />
              </div>
            </div>
          </div>
          {isValidFileFormat && (
            <div className="flex flex-row gap-1 items-center mt-1">
              <InformationTriangleIcon
                fillColor="#FF3141"
                className="w-4 h-4"
              />
              <div className="text-[#FF3141]">
                {t("ss_builder_upload_file_format_invalid_content")}
              </div>
            </div>
          )}
          {isValidFileSize && (
            <div className="flex flex-row gap-1 items-center mt-1">
              <InformationTriangleIcon
                fillColor="#FF3141"
                className="w-4 h-4"
              />
              <div className="text-[#FF3141]">
                {t("ss_builder_upload_file_size_invalid_content")}
              </div>
            </div>
          )}
          {isInvalidMinimumSize && (
            <div className="flex flex-row gap-1 items-center mt-1">
              <InformationTriangleIcon
                fillColor="#FF3141"
                className="w-4 h-4"
              />
              <div className="text-[#FF3141]">
                {t("ss_builder_upload_file_size_invalid_content_min_256")}
              </div>
            </div>
          )}
        </div>
        <div>
          <Divider
            style={{
              marginTop: "12px",
              marginBottom: "12px",
            }}
          />

          <div className="text-[15px] leading-[150%] text-[#666666]">
            <span className="text-red-600">*</span>
            {t("ss_builder_website_config_site_title_label")}
          </div>
          <div className="text-xs text-[#999999] leading-4 mb-1">
            {t("ss_builder_website_config_site_title_description")}
          </div>
          <Input
            value={valueSiteTitle || ""}
            onChange={(val) => {
              if (val.length <= SITE_TITLE_MAX_LENGTH) setValueSiteTitle(val);
            }}
            maxLength={SITE_TITLE_MAX_LENGTH}
            placeholder={t("ss_builder_website_config_site_title_placeholder")}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
            valueSiteTitle ? valueSiteTitle.length : 0
          }/${SITE_TITLE_MAX_LENGTH}`}</div>
        </div>
        <div>
          <Divider
            style={{
              marginTop: "12px",
              marginBottom: "12px",
            }}
          />

          <div className="text-[15px] leading-[150%] text-[#666666]">
            <span className="text-red-600">*</span>
            {t("ss_builder_heading_site_setting_label")}
          </div>
          <div className="text-xs text-[#999999] leading-4 mb-1">
            {t("ss_builder_heading_site_setting_description")}
          </div>
          <Input
            value={valueMetaTitle || ""}
            onChange={(val) => {
              if (val.length <= HEADING_MAX_LENGTH) setValueMetaTitle(val);
            }}
            maxLength={HEADING_MAX_LENGTH}
            placeholder={t(
              "ss_builder_affiliate_product_btn_see_more_placeholder"
            )}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
            valueMetaTitle ? valueMetaTitle.length : 0
          }/${HEADING_MAX_LENGTH}`}</div>
        </div>
        <Divider
          style={{
            marginTop: "12px",
            marginBottom: "12px",
          }}
        />
        <div className="mb-1">
          <div className="text-[15px] leading-[150%] text-[#666666]">
            <span className="text-red-600">*</span>
            {t("ss_builder_subtitle_site_setting_label")}
          </div>
          <div className="text-xs text-[#999999] leading-4 mb-1">
            {t("ss_builder_subtitle_site_setting_description")}
          </div>
          <TextArea
            value={valueMetaDescription || ""}
            onChange={(val) => {
              if (val.length <= SUBTITLE_MAX_LENGTH)
                setValueMetaDescription(val);
            }}
            maxLength={SUBTITLE_MAX_LENGTH}
            placeholder={t(
              "ss_builder_affiliate_product_btn_see_more_placeholder"
            )}
            rows={6}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
            valueMetaDescription ? valueMetaDescription.length : 0
          }/${SUBTITLE_MAX_LENGTH}`}</div>
        </div>
      </div>
      <div className="max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[10] bg-[#FFFFFF]">
        <div className="w-1/2 text-center">
          <Button
            onClick={handleCancelBrandSetting}
            className="adm-button adm-button-default adm-button-shape-default w-full"
          >
            {t("ss_builder_close_panel")}
          </Button>
        </div>
        <div className="w-1/2 text-center">
          <Button
            color="primary"
            disabled={isDisableButtonSave}
            className="adm-button adm-button-primary adm-button-shape-default w-full"
            onClick={() => handleSaveSiteSetting()}
          >
            {t("ss_builder_save")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PersonalBrandSetting;
