import { useTranslation } from "react-i18next";
import { CopyIcon, ReplaceIcon, RestoreIcon } from "../../share/icons/index";
import { Action } from "antd-mobile/es/components/popover";
import { Position } from "@features/main/store/interface";
import { hasDigitalProductOnSite } from "../libs";

const useGetHeaderMoreActions = (
  isAdminRole: boolean,
  positions: Position[]
) => {
  const { t } = useTranslation();
  const hasDigitalProduct = hasDigitalProductOnSite(positions);
  const actions: Action[] = [
    {
      key: "restore",
      icon: (
        <RestoreIcon
          className="w-5 h-5"
          fillColor="#FFFFFF"
        />
      ),
      text: t("ss_builder_restore_theme_label"),
    },
  ];
  !hasDigitalProduct &&
    actions.push({
      key: "replace-theme",
      icon: (
        <ReplaceIcon
          className="w-5 h-5"
          fillColor="#FFFFFF"
        />
      ),
      text: t("ss_builder_replace_theme_label"),
    });
  isAdminRole &&
    actions.push({
      key: "create-new-theme",
      icon: (
        <CopyIcon
          className="w-5 h-5"
          fillColor="#FFFFFF"
        />
      ),
      text: t("ss_builder_create_new_theme_template_label"),
    });

  return actions;
};

export default useGetHeaderMoreActions;
