import { useAppSelector } from "@app/hooks";
import { sendRating } from "@share/api";
import { CheckCircleIcon } from "@share/icons";
import { StarFillIcon } from "@share/icons/line-icon";
import { Rate, Selector, TextArea, Toast } from "antd-mobile";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonConfirmPopup from "../ButtonConfirmPopup";
import gaEvents, { ACTION_CONFIG, CATEGORY_CONFIG, EVENT_CONFIG } from "@share/lib/ga-events";
import { useSelector } from "react-redux";
import { selectProfile } from "@features/user/userSlice";

const RATING_STAR = {
  STAR_1: 1,
  STAR_2: 2,
  STAR_3: 3,
  STAR_4: 4,
  STAR_5: 5,
};
const RATING_DESCRIPTION = {
  CATEGORY_1: "unsatisfactory",
  CATEGORY_2: "need_improvement",
  CATEGORY_3: "not_bad",
  CATEGORY_4: "satisfactory",
  CATEGORY_5: "good",
};

const RATING_CATEGORY = {
  CATEGORY_1: "user_interface",
  CATEGORY_2: "loading_time",
  CATEGORY_3: "user_experience",
  CATEGORY_4: "support",
  CATEGORY_5: "other",
};
const DESCRIPTION_MAXIMUM = 500;

export const RatingStarComponent = ({
  ratingDefault = null,
  isAuto = false,
  onClose,
}: {
  ratingDefault?: number;
  onClose?: () => void;
  isAuto?: boolean;
}) => {
  const userAgent = window.navigator.userAgent;
  const user = useAppSelector((state) => state.user);
  const userId = user?.kol?._id;
  const country = user?.kol?.country;
  const username = user?.kol?.username;
  const domain = user?.kol?.domain;
  const { t } = useTranslation();
  const [titleStar, setTitleStar] = useState(null);
  const [descriptionStar, setDescriptionStar] = useState(null);
  const [rating, setRating] = useState(ratingDefault);
  const [category, setCategory] = useState([]);
  const [valueDescription, setValueDescription] = useState("");
  const [isDisableBtn, setIsDisableBtn] = useState(true);
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);
  const [isPendingRating, setIsPendingRating] = useState(false);

  const getTitleStar = (star) => {
    switch (star) {
      case RATING_STAR.STAR_1:
        return t("ss_builder_block_rating_star_1");
        break;
      case RATING_STAR.STAR_2:
        return t("ss_builder_block_rating_star_2");
        break;
      case RATING_STAR.STAR_3:
        return t("ss_builder_block_rating_star_3");
        break;
      case RATING_STAR.STAR_4:
        return t("ss_builder_block_rating_star_4");
        break;
      case RATING_STAR.STAR_5:
        return t("ss_builder_block_rating_star_5");
        break;
      default:
        return t("ss_builder_block_rating_star_5");
        break;
    }
  };
  const getDescriptionStar = (star: number) => {
    switch (star) {
      case RATING_STAR.STAR_1:
        return t("ss_builder_block_rating_description_star_1");
        break;
      case RATING_STAR.STAR_2:
        return t("ss_builder_block_rating_description_star_2");
        break;
      case RATING_STAR.STAR_3:
        return t("ss_builder_block_rating_description_star_3");
        break;
      case RATING_STAR.STAR_4:
        return t("ss_builder_block_rating_description_star_4");
        break;
      case RATING_STAR.STAR_5:
        return t("ss_builder_block_rating_description_star_5");
        break;
      default:
        return t("ss_builder_block_rating_description_star_5");
        break;
    }
  };
  const handleTitleStar = (star: number) => {
    setTitleStar(getTitleStar(star));
  };
  const handleDescriptioStar = (star: number) => {
    setDescriptionStar(getDescriptionStar(star));
  };
  const handleRatingStar = (value: number) => {
    if (value > 0) {
      setRating(value);
      handleTitleStar(value);
      handleDescriptioStar(value);
      setIsDisableBtn(false);
    }
  };

  const handleSetCategory = (arr) => {
    if (arr && arr.length) {
      setCategory(arr);
    }
  };
  const handleClose = () => {
    if (isAuto) {
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.ASK_TO_REVIEW,
        EVENT_CONFIG.ASK_TO_REVIEW_CLOSE_REVIEW_FORM,
        '',
        ACTION_CONFIG.CLOSE_REVIEW_FORM
      )    
    } else {
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.CREATOR_SEND_REVIEW,
        EVENT_CONFIG.CREATOR_SEND_REVIEW_CLOSE_REVIEW_FORM,
        '',
        ACTION_CONFIG.CLOSE_REVIEW_FORM
      )    
    }
    onClose();
  };
  const handleDescriptionCategory = (star: number) => {
    switch (star) {
      case RATING_STAR.STAR_1:
        return RATING_DESCRIPTION.CATEGORY_1;
        break;
      case RATING_STAR.STAR_2:
        return RATING_DESCRIPTION.CATEGORY_2;
        break;
      case RATING_STAR.STAR_3:
        return RATING_DESCRIPTION.CATEGORY_3;
        break;
      case RATING_STAR.STAR_4:
        return RATING_DESCRIPTION.CATEGORY_4;
        break;
      case RATING_STAR.STAR_5:
        return RATING_DESCRIPTION.CATEGORY_5;
        break;
      default:
        return RATING_DESCRIPTION.CATEGORY_5;
        break;
    }
  };
  const handleSubmitRatingStar = async () => {
    const dataRequest = {
      user_id: userId,
      username: username,
      domain: domain,
      country: country,
      user_agent: userAgent,
      rating_star: rating,
      rating_description: handleDescriptionCategory(rating),
      description: valueDescription,
      rating_category: category.toString(),
    };
    let response;
    try {
      handlePendingRating(true);
      response = await sendRating(dataRequest);
      if (isAuto) {
        gaEvents.submitEventV2(
          passioPageProfile,
          CATEGORY_CONFIG.ASK_TO_REVIEW,
          EVENT_CONFIG.ASK_TO_REVIEW_SUBMIT_REVIEW,
          '',
          ACTION_CONFIG.SUBMIT_REVIEW_FORM
        )
      } else {
        gaEvents.submitEventV2(
          passioPageProfile,
          CATEGORY_CONFIG.CREATOR_SEND_REVIEW,
          EVENT_CONFIG.CREATOR_SEND_REVIEW_SUBMIT_REVIEW,
          '',
          ACTION_CONFIG.SUBMIT_REVIEW_FORM
        )
      }

    } catch (err) {
      console.log(err);
    }
    handlePendingRating(false);
    if (response.success) {
      Toast.show({
        icon: (
          <CheckCircleIcon
            className="text-center inline-block w-[30px] h-[30px]"
            fillColor="#00B578"
          />
        ),
        content: (
          <div className="text-center">
            {t("ss_builder_block_rating_toast_success_rating")}
          </div>
        ),
      });
      handleClose();
    }
  };
  const options = [
    {
      label: t("ss_builder_block_rating_answer_1"),
      value: RATING_CATEGORY.CATEGORY_1,
    },
    {
      label: t("ss_builder_block_rating_answer_2"),
      value: RATING_CATEGORY.CATEGORY_2,
    },
    {
      label: t("ss_builder_block_rating_answer_3"),
      value: RATING_CATEGORY.CATEGORY_3,
    },
    {
      label: t("ss_builder_block_rating_answer_4"),
      value: RATING_CATEGORY.CATEGORY_4,
    },
    {
      label: t("ss_builder_block_rating_answer_5"),
      value: RATING_CATEGORY.CATEGORY_5,
    },
  ];
  const handleChangeBlockDescription = (val) => {
    if (val?.length <= DESCRIPTION_MAXIMUM) {
      setValueDescription(val);
    }
  };
  const handlePendingRating = (value) => {
    setIsPendingRating(value);
  }

  return (
    <div className="rating-popup-content h-full">
      <div className="max-h-full overflow-auto">
        <div className="rating-star form-item">
          <Rate
            className="w-full"
            onChange={(val) => handleRatingStar(val)}
            value={rating}
            character={
              <StarFillIcon
                width={30}
                height={30}
                fillColor="var(--adm-color-light)"
              />
            }
            style={{
              "--inactive-color": "var(--adm-color-light)",
              "--active-color": "#ffd21e",
            }}
          />
          {titleStar && <div className="title-star">{titleStar}</div>}
        </div>

        {rating && (
          <div className="category mt-3 form-item">
            <div className="text-[#666] text-[15px] leading-[1.5] mb-1">
              {descriptionStar}
            </div>
            <Selector
              className="selector-category-star"
              options={options}
              multiple={true}
              onChange={(arr, extend) => handleSetCategory(arr)}
              style={{
                "--border-radius": "4px",
                "--checked-color": "#fff",
                "--padding": "8px 16px",
              }}
            />
          </div>
        )}

        <div className="input-text-box form-item">
          <div className="pt-3 px-4">
            <div className="text-[15px] leading-[1.5] text-[#666666] mb-1">
              {t("ss_builder_block_rating_content_rating")}
            </div>
            <TextArea
              placeholder={t(
                "ss_builder_block_rating_content_rating_placeholder"
              )}
              onChange={handleChangeBlockDescription}
              value={valueDescription}
              maxLength={DESCRIPTION_MAXIMUM}
              rows={5}
            />
            <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${valueDescription ? valueDescription.length : 0
              }/${DESCRIPTION_MAXIMUM}`}</div>
          </div>
        </div>
      </div>
      <ButtonConfirmPopup
        onConfirm={rating ? handleSubmitRatingStar : () => {}}
        isDisable={rating && !isPendingRating ? false : true}
        title={t("ss_builder_block_rating_button_submit_rating")}
      />
    </div>
  );
};

export default RatingStarComponent;
