import React, { FC, useEffect, useRef, useState } from "react";
import { Tabs, Modal } from "antd-mobile";
import { SwiperRef } from "antd-mobile/es/components/swiper";
import MobileLayout from "@share/components/layouts/mobile-layout";
import {
  DesignIcon,
  InformationIcon,
  SettingIcon,
  ThemeStoreIcon,
  BackgroundIcon,
} from "@share/icons";
import Design from "@features/design/components";
import { getSite } from "@share/thunk";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { useTranslation } from "react-i18next";
import ResultPublishComponent from "@features/design/components/ResultPublish";
import Setting from "@features/setting/components";
import { setDynamicParams, setParamsBlock } from "../store/dynamicSlice";
import {
  TAB_BAR_INDEX,
  THEME_SETTING_BACKGROUND_TYPE,
  BACKGROUND_SETTING_DEFAULT_VALUE,
  TAB_BAR_KEY,
  COUNTRY_TH,
} from "@share/configs/const";
import { dataService } from "@share/configs/rxjs";
import BlockEdit from "@features/block/components/BlockEditV2";
import Portal from "@share/HOC/portal";
import cls from "classnames";
import ThemeStyle from "@share/components/theme-style";
import GenerateColor from "@share/components/generate-color";
import ThemeTemplateStore from "@features/design/components/ThemeTemplateHandler/ThemeTemplateStore";
import BackgroundSetting from "@features/setting/components/ThemeSetting/BackgroundSetting";
import { PRODUCT_TOUR_CLASS_NAME } from "@features/design/components/ProductTour/product-tour-config";
import PopupComponent from "@share/components/PopupComponent";
import RatingStarAutoComponent from "@share/components/RatingStarAutoComponent";
import { setSiteInfo } from "@features/main/store/siteSettingSlice";
import {
  fetchCreatorProfile,
  fetchPassioPageProfile,
  sendRequestNoNeedToRating,
} from "@share/api";
import gaEvents, {
  ACTION_CONFIG,
  CATEGORY_CONFIG,
  EVENT_CONFIG,
} from "@share/lib/ga-events";
import { setPassioPageProfile } from "@features/main/store/passioPageProfileSlice";
import { useModal } from "@share/hooks/modal";
import { ManagementIcon } from "@share/icons/line-icon";
import ManagementServiceComponent from "@share/components/ManagementService";
import { RootState } from "@app/store";
import { useSelector } from "react-redux";

interface data {
  indexDesign: number;
  isOpenColorPicker: boolean;
  isOpenThemeSetting: boolean;
  isOpenFontOption: boolean;
  actionKey: string;
  indexColor: number;
  isOpenSelectedTabIcon;
}

const TabsElement = (props) => {
  return (
    <div className={cls("min-w-[45px] ", props?.className)}>
      {props.children}
      <h1
        className="text-[11px] text-[#999999]"
        style={{
          marginBottom: "0px",
        }}
      >
        {props.title}
      </h1>
    </div>
  );
};

const main: FC = () => {
  const dispatch = useAppDispatch();
  const country = useSelector((state: RootState) => state?.user?.kol?.country);
  const { t } = useTranslation();
  const { ErrorModal } = useModal();
  const dynamicConfig = useAppSelector<any>((state) => state.dynamicConfig);
  const siteSettings = useAppSelector<any>((state) => state.siteSettings);
  const isOpenFloatingPanel =
    dynamicConfig && dynamicConfig.isOpenFloatingPanel;
  const isErrorNetwork = dynamicConfig && dynamicConfig.isErrorNetwork;
  const display_setting = siteSettings && siteSettings.display_setting;
  const is_open_popup_rating =
    siteSettings && siteSettings?.is_open_popup_rating;
  const {
    enable_overlay,
    overlay_color,
    type,
    blur: blurBackgroundSetting = BACKGROUND_SETTING_DEFAULT_VALUE.BLUR,
  } = display_setting?.background || {};

  const user = useAppSelector((state) => state.user);
  const userId = user && user?.kol && user?.kol?._id;
  const alertError = () => {
    const onClose = () => {
      window.location.reload();
    };
    ErrorModal({
      title: t("ss_builder_error_network_title"),
      description: t("ss_builder_error_network_content"),
      confirmText: t("ss_builder_reload_label"),
      onClose,
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      const passioPageProfile = await fetchPassioPageProfile(userId);

      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.BUILDER_ACCESS,
        `BuilderAccess_SessionStart`,
        "",
        ACTION_CONFIG.SESSION_START
      );

      dispatch(setPassioPageProfile({ data: passioPageProfile }));
    };

    if (userId) {
      fetchData();
    }
  }, [userId]);

  useEffect(() => {
    const checkDataCreator = async () => {
      const creatorProfile = await fetchCreatorProfile();
      if (!creatorProfile) {
        alertError();
      }
    };
    if (userId) {
      checkDataCreator();
    }
  }, [userId]);

  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);

  const swiperRef = useRef<SwiperRef>(null);

  const [activeIndex, setActiveIndex] = useState(TAB_BAR_INDEX.DESIGN);
  const [isOpenFloatingAddBlock, setOpenFloatingAddBlock] = useState(false);
  const [isPublishSuccess, setIsPublishSuccess] = useState(false);

  const [isOpenPositionSelectedList, setIsOpenPositionSelectedList] = useState(
    isOpenFloatingAddBlock
  );
  const [isOpenAddBlockFloatingPanel, setIsOpenAddBlockFloatingPanel] =
    useState(false);
  const [isOpenPopupRating, setIsOpenPopupRating] = useState(false);
  const [isLoginEnoughTime, setIsLoginEnoughTime] = useState(false);
  const [isOpenPopupManagementService, setIsOpenPopupManagementService] =
    useState(false);
  const styleBackgroundImage =
    type === THEME_SETTING_BACKGROUND_TYPE.IMAGE
      ? {
          backgroundPosition: "center center",
          filter: `blur(${blurBackgroundSetting}px) `,
          WebkitFilter: `blur(${blurBackgroundSetting}px) `,
          width: `calc(100% + ${blurBackgroundSetting * 2}px) `,
          height: `calc(100% + ${blurBackgroundSetting * 2}px) `,
          transform: `translate(-${blurBackgroundSetting}px, -${blurBackgroundSetting}px)`,
        }
      : "";
  useEffect(() => {
    if (isErrorNetwork) {
      Modal.alert({
        header: (
          <InformationIcon
            className="w-11 h-11"
            fillColor="#FF8F1F"
            onClick={() => {}}
          />
        ),
        content: (
          <div className="flex flex-col text-center items-center">
            <div className="text-lg font-bold leading-[25px]">
              {t("ss_builder_error_network_title")}
            </div>
            <div className="">{t("ss_builder_error_network_content")}</div>
          </div>
        ),
        confirmText: t("ss_builder_error_network_confirm_btn_label"),
        onConfirm: () => {
          dispatch(
            setDynamicParams({
              data: { isErrorNetwork: false },
            })
          );
          return;
        },
      });
    }
  }, [isErrorNetwork]);

  const getTabItems = (indexKey) => {
    const items = [
      {
        key: "design",
        title: (
          <TabsElement
            title={t("ss_builder_design_label")}
            className={PRODUCT_TOUR_CLASS_NAME.STEP_1}
          >
            <DesignIcon
              fillColor={
                indexKey === TAB_BAR_INDEX.DESIGN ? "#EE3244" : undefined
              }
              className="m-auto"
            />
          </TabsElement>
        ),
      },
      {
        key: "background",
        title: (
          <TabsElement
            title={t("ss_builder_theme_setting_background")}
            className={PRODUCT_TOUR_CLASS_NAME.STEP_14}
          >
            <BackgroundIcon
              fillColor={
                indexKey === TAB_BAR_INDEX.BACKGROUND ? "#EE3244" : undefined
              }
              className="m-auto"
            />
          </TabsElement>
        ),
      },
      {
        key: "theme-store",
        title: (
          <TabsElement
            title={t("ss_builder_theme_store_label")}
            className={PRODUCT_TOUR_CLASS_NAME.STEP_15}
          >
            <ThemeStoreIcon
              fillColor={
                indexKey === TAB_BAR_INDEX.TEMPLATE ? "#EE3244" : undefined
              }
              className="m-auto"
            />
          </TabsElement>
        ),
      },
      {
        key: "setting",
        title: (
          <TabsElement
            title={t("ss_builder_settings_label")}
            className={PRODUCT_TOUR_CLASS_NAME.STEP_16}
          >
            <SettingIcon
              fillColor={
                indexKey === TAB_BAR_INDEX.SETTING ? "#EE3244" : undefined
              }
              className="m-auto"
            />
          </TabsElement>
        ),
      },
    ];
    if (country !== COUNTRY_TH) {
      items.push({
        key: "management",
        title: (
          <TabsElement title={t("ss_builder_management_label")}>
            <ManagementIcon
              fillColor={
                indexKey === TAB_BAR_INDEX.MANAGEMENT ? "#EE3244" : undefined
              }
              className="m-auto"
            />
          </TabsElement>
        ),
      });
    }
    return items;
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && userId) dispatch(getSite());
    return () => {
      isMounted = false;
    };
  }, [userId]);

  useEffect(() => {
    const subscription = dataService.getObservable().subscribe((data: data) => {
      if (data) {
        data.indexDesign !== undefined && handleSwitchTab(data.indexDesign);
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  const tabItems = getTabItems(activeIndex);
  const handleCallback = (data) => {
    dispatch(
      setParamsBlock({
        data: {
          positionSlug: data.positionSlug,
          listBlocksData: data.listBlocksData,
          tabUid: data.activeKeyTab,
        },
      })
    );
  };

  const handleSwitchTab = (index: number) => {
    setActiveIndex(index);
    swiperRef.current?.swipeTo(index);
  };

  const handleRedirectTabs = () => {
    handleSwitchTab(0);
  };

  const handleCloseFloatingAddBlock = () => {
    setIsOpenAddBlockFloatingPanel(false);
    setIsOpenPositionSelectedList(false);
    setOpenFloatingAddBlock(true);
  };
  const handleOpenFloatingAddBlock = () => {
    setOpenFloatingAddBlock(true);
  };
  const callBackCloseFloating = () => {
    // dispatch(
    //   setDynamicParams({ data: { isOpenFloatingPanel: !isOpenFloatingPanel } })
    // );
    setOpenFloatingAddBlock(false);
  };
  useEffect(() => {
    let timeOut;
    timeOut = setTimeout(() => {
      handleLoginEnoughTime(true);
    }, 30000);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);
  useEffect(() => {
    if (
      is_open_popup_rating &&
      activeIndex === TAB_BAR_INDEX.DESIGN &&
      isLoginEnoughTime
    ) {
      handleOpenPopupRating(true);
    }
  }, [is_open_popup_rating, isLoginEnoughTime, activeIndex]);

  const tabContentRender = () => {
    let element = null;
    switch (activeIndex) {
      case TAB_BAR_INDEX.DESIGN:
        element = (
          <Design
            callbackShowResultPublish={(data) => setIsPublishSuccess(data)}
            activeIndex={activeIndex}
            handleRedirectTabs={handleRedirectTabs}
            isOpenFloatingAddBlock={isOpenFloatingAddBlock}
            callBackCloseFloating={callBackCloseFloating}
            setIsOpenPositionSelectedList={setIsOpenPositionSelectedList}
            setIsOpenAddBlockFloatingPanel={setIsOpenAddBlockFloatingPanel}
            isOpenPositionSelectedList={isOpenPositionSelectedList}
            isOpenAddBlockFloatingPanel={isOpenAddBlockFloatingPanel}
          />
        );
        break;
      case TAB_BAR_INDEX.BACKGROUND:
        element = (
          <BackgroundSetting
            backToAllSetting={() => null}
            activeIndex={activeIndex}
          />
        );
        break;
      case TAB_BAR_INDEX.TEMPLATE:
        element = (
          <ThemeTemplateStore
            handleRedirectTabs={handleRedirectTabs}
            siteSettings={siteSettings}
            user={user}
            activeIndex={activeIndex}
          />
        );
        break;
      case TAB_BAR_INDEX.SETTING:
        element = <Setting />;
        break;
    }
    return element;
  };
  const fetchSiteInfo = async () => {
    const newSiteSettings = {
      ...siteSettings,
      is_open_popup_rating: false,
    };
    dispatch(setSiteInfo({ sites: newSiteSettings }));
  };
  const handleNoNeedToRating = async () => {
    const dataRequest = {
      user_id: userId,
      is_need_to_rating: false,
    };
    let response;
    try {
      response = await sendRequestNoNeedToRating(dataRequest);
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.CREATOR_SEND_REVIEW,
        EVENT_CONFIG.CREATOR_SEND_REVIEW_CLOSE_REVIEW_FORM,
        "",
        ACTION_CONFIG.CLOSE_REVIEW_FORM
      );
    } catch (err) {
      console.log(err);
    }
    if (response.success) {
      fetchSiteInfo();
    }
  };
  const handleOpenPopupRating = (value) => {
    setIsOpenPopupRating(value);
    if (!value) {
      handleNoNeedToRating();
    }
  };
  const handleOpenPopupManagementService = (value) => {
    setIsOpenPopupManagementService(value);
  };
  const handleLoginEnoughTime = (value) => {
    setIsLoginEnoughTime(value);
  };
  return (
    <>
      <MobileLayout>
        {tabContentRender()}
        <div
          style={{
            position: "fixed",
            bottom: "0",
            width: "inherit",
            backgroundColor: "#FFFFFF",
            borderTop: "1px solid #EEEEEE",
            zIndex: 998,
            left: "50%",
            transform: "translateX(-50%)",
            display: isOpenFloatingPanel ? "none" : "",
          }}
        >
          <Tabs
            activeKey={tabItems[activeIndex] && tabItems[activeIndex].key}
            onChange={(key) => {
              const index = tabItems.findIndex((item) => item?.key === key);

              if (key != TAB_BAR_KEY.MANAGEMENT) {
                handleSwitchTab(index);
                setActiveIndex(index);
              } else {
                handleOpenPopupManagementService(true);
              }
            }}
            style={{
              "--active-line-color": "#FFFFFF",
              margin: "auto",
            }}
            className="tab-bar-footer max-width-content-layout"
          >
            {tabItems.map((item) => (
              <Tabs.Tab
                title={item.title}
                key={item.key}
                className="text-[#999999] text-center"
              />
            ))}
          </Tabs>
        </div>
        {isPublishSuccess && (
          // <FullScreenPopup
          //   visible={isPublishSuccess}
          //   onMaskClick={() => setIsPublishSuccess(false)}
          // >
          //   <ResultPublishComponent
          //     callbackClosePublishResult={() => setIsPublishSuccess(false)}
          //   />
          // </FullScreenPopup>
          <Portal elementId="modalPortal">
            <Modal
              visible={isPublishSuccess}
              content={
                <ResultPublishComponent
                  callbackClosePublishResult={() => setIsPublishSuccess(false)}
                />
              }
              closeOnAction
              closeOnMaskClick={true}
              onClose={() => {
                setIsPublishSuccess(false);
              }}
              className="modal-publish-success"
            />
          </Portal>
        )}
        <BlockEdit
          handleRedirectTabs={handleRedirectTabs}
          handleCloseFloatingAddBlock={handleCloseFloatingAddBlock}
          handleOpenFloatingAddBlock={handleOpenFloatingAddBlock}
        />
        {activeIndex === TAB_BAR_INDEX.DESIGN && (
          <>
            <Portal elementId="myBackgroundViewPortal">
              <div
                style={{
                  position: "fixed",
                  width: "100%",
                  height: "100%",
                  top: "0px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  overflow: "hidden",
                  paddingBottom: "65px",
                  paddingTop: "48px",
                }}
              >
                <div
                  className="w-full h-full background-image background-color"
                  style={{
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    objectFit: "cover",
                    ...styleBackgroundImage,
                  }}
                ></div>
              </div>
              {type === THEME_SETTING_BACKGROUND_TYPE.IMAGE &&
                enable_overlay && (
                  <div
                    className="absolute top-0 left-[50%] translate-x-[-50%] block w-full h-full z-[2]"
                    style={{
                      backgroundColor: overlay_color,
                    }}
                  ></div>
                )}
            </Portal>
            <div
              id="tabBarHeaderPortal"
              style={{
                display:
                  tabItems[activeIndex] &&
                  tabItems[activeIndex].key !== "design"
                    ? "none"
                    : "",
              }}
            />
            <div
              id="tabMenuDesignPortal"
              className="design-class"
              style={{
                display:
                  tabItems[activeIndex] &&
                  tabItems[activeIndex].key !== "design"
                    ? "none"
                    : "",
              }}
            ></div>
            <ThemeStyle displaySetting={display_setting} />
          </>
        )}
        <div id="themeTemplateHandlerPortal"></div>
        <GenerateColor displaySetting={display_setting} />
        <div
          id="newBlockPortal"
          className=""
          style={{
            display:
              tabItems[activeIndex] && tabItems[activeIndex].key !== "design"
                ? "none"
                : "",
          }}
        ></div>
        <div
          id="modalPortal"
          className="relative z-[9999]"
        ></div>
      </MobileLayout>
      <PopupComponent
        visible={isOpenPopupRating}
        onMaskClick={() => {}}
        title={t("ss_builder_block_rating_title")}
        onClose={() => handleOpenPopupRating(false)}
        classNameBody="max-h-[60vh] h-[30vh] popup-hard"
      >
        <RatingStarAutoComponent onClose={() => handleOpenPopupRating(false)} />
      </PopupComponent>
      <PopupComponent
        visible={isOpenPopupManagementService}
        onMaskClick={() => {}}
        title={t("ss_builder_popup_management_title")}
        onClose={() => handleOpenPopupManagementService(false)}
        classNameBody="h-[40vh] popup-hard"
      >
        <ManagementServiceComponent
          onClose={() => handleOpenPopupManagementService(false)}
        />
      </PopupComponent>
    </>
  );
};

export default React.memo(main);
