import { XIconV2 } from "@share/icons";
import React, { useEffect, useState } from "react";
import { getPackageOfUser, getPackageSass } from "@share/api";
import { setProfile } from "@features/user/userSlice";
import { useDispatch } from "react-redux";

export const MESSAGE_START_PURCHASE = "start-purchase";
export const MESSAGE_SUCCESS_PURCHASE = "purchase-success";
export const KOL_PRICING = process.env.NEXT_PUBLIC_PASSIO_SASS_KOL_PRICING;
export const NEXT_PUBLIC_PASSIO_ACCOUNT =
  process.env.NEXT_PUBLIC_PASSIO_ACCOUNT;

const UpgradeAccount = ({
  user,
  onClose,
  handleSetPackageType,
}: {
  handleSetPackageType;
  onClose?: () => void;
  [x: string]: any;
}) => {
  const dispatch = useDispatch();

  const [packageUser, setPackageUser] = useState({
    package_type: user?.kol?.package_type,
    package_name: user?.kol?.package_name,
  });
  const [visible, setVisible] = useState(false);
  const [visibleIcon, setVisibleIcon] = useState(true);
  const handleMessage = async (event) => {
    const data = event.data?.key;
    const isMatchDomain = event.origin === NEXT_PUBLIC_PASSIO_ACCOUNT;
    if (data === MESSAGE_START_PURCHASE) {
      handleVisibleIcon(false);
    }
    if (data === MESSAGE_SUCCESS_PURCHASE && isMatchDomain) {
      try {
        const getPackageOfUse = await getPackageOfUser();
        const packageOfUser = {
          package_type: getPackageOfUse?.data?.data?.language?.id,
          package_name: getPackageOfUse?.data?.data?.language?.name,
        };
        dispatch(setProfile({ ...user?.kol, ...packageOfUser }));
        handleSetPackageType(packageOfUser.package_type);
        if (getPackageOfUse) {
          handleClosePopup();
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  const handleVisibleIcon = (value) => {
    setVisibleIcon(value);
  };
  const handleClosePopup = () => {
    setVisible(false);
    onClose();
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage, false);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  return (
    <div className="h-full w-full relative max-w-[414px] m-auto">
      <iframe
        id="iframe-sass"
        src={KOL_PRICING}
        className="h-full w-full"
      />
      {visibleIcon && (
        <div
          className="absolute top-4 right-4 cursor-pointer"
          onClick={() => handleClosePopup()}
        >
          <XIconV2 />
        </div>
      )}
    </div>
  );
};
export default UpgradeAccount;
