import React from "react";
import SiteSetting from "@features/setting/components/body/component/SiteSetting";
import ThemeSetting from "@features/setting/components/body/component/ThemeSetting";
import RatingStar from "./component/RatingStar";
import JoinCampaign from "./component/JoinCampaign";

const BodySetting = (props) => {
  return (
    <div>
      <JoinCampaign />
      <ThemeSetting />
      <SiteSetting />
      <RatingStar />
    </div>
  );
};
export default BodySetting;
