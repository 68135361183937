import { RootState } from "@app/store";
import { BLOCK_TYPE } from "@features/block/block.conf";
import { runMiddleware } from "@features/block/components/BlockEditV2";
import { POSITION_TYPE } from "@features/block/config/interface";
import {
  BLOCK_TYPE_BY_GROUP,
  COUNT_OF_BLOCK_MAXIMUM,
  COUNT_OF_FLOATING_BLOCK_MAXIMUM,
} from "@features/design/design.config";
import {
  FloatingIntroStatus,
  setFloatingBlockIntroductionState,
  setFloatingIntroductionState,
} from "@features/design/store/externalStateSlice";
import { FLOATING } from "@features/design/store/externalStateSlice";
import { addBlock, getBlocksBySlug } from "@features/main/store/positionsSlice";
import { COUNTRY_VN } from "@features/mini-store/block/TruthOrDare/sideFnc";
import { usePopup } from "@share/hooks";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import ModalWaringFloatingFull from "@features/design/components/IntroductionNewFeature/BrandRatingFloat/ModalWaringFloatingFull";
import ModalBrandRatingGolive from "@features/design/components/IntroductionNewFeature/BrandRatingFloat/ModalBrandRatingGolive";
import { getParentId } from "@features/block/store/helper";

enum BRAND_RATING_BLOCK {
  NOT_SHOW = "NOT_SHOW",
  SHOW_INTRO = "SHOW_INTRO",
  SHOW_WARNING = "SHOW_WARNING",
}

const BrandRatingIntroduction: React.FunctionComponent = (props) => {
  const [state, setState] = useState<BRAND_RATING_BLOCK>(
    BRAND_RATING_BLOCK.NOT_SHOW
  );
  const dispatch = useDispatch();

  const allPositions = useSelector<RootState, any>((state) => state.positions);
  const productTourState = useSelector<RootState, any>(
    (state) => state.passioPageProfile.finish_product_tour
  );
  const modalIntroBrandRatingState = useSelector<RootState, any>(
    (state) =>
      state.externalState?.floating_block &&
      state.externalState?.floating_block[FLOATING.BRAND_RATING_BLOCK]
  );
  const modalIntroBrandGiftState = useSelector<RootState, any>(
    (state) =>
      state.externalState?.floating_block &&
      state.externalState?.floating_icon[FLOATING.BRAND_GIFT_FOOTER]
  );
  const userId = useSelector<RootState, any>(
    (state) => state.siteSettings.user_id
  );

  const country = useSelector<RootState, any>(
    (state) => state?.user?.kol?.country
  );

  const floatingBlocksBody = useSelector(getBlocksBySlug(POSITION_TYPE.BODY));
  const floatingBlocksHeader = useSelector(
    getBlocksBySlug(POSITION_TYPE.HEADER)
  );
  const floatingBlocksFooter = useSelector(
    getBlocksBySlug(POSITION_TYPE.FOOTER)
  );
  const floatingBlocks = floatingBlocksHeader?.concat(
    floatingBlocksBody?.concat(floatingBlocksFooter)
  );
  const hideAllModal = () => {
    setState(BRAND_RATING_BLOCK.NOT_SHOW);
    dispatch(
      setFloatingBlockIntroductionState({
        [FLOATING.BRAND_RATING_BLOCK]: FloatingIntroStatus.DISABLE,
      })
    );
  };

  const addBrandRatingFooterBlock = async () => {
    const isBrandGiftFooterExist = floatingBlocks.find(
      (block) => block.block_type === BLOCK_TYPE.BRAND_RATING
    );
    if (!isBrandGiftFooterExist) {
      const UID = `brand-rating-${uuidv4()}`;
      const body = allPositions.find(
        (position) => position.slug === POSITION_TYPE.BODY
      );
      const tabBlock = body.block_list[0] || ({} as any);
      const tabList = tabBlock?.content_attributes?.tabs || [];
      const parentId = getParentId(allPositions, {
        positionSlug: POSITION_TYPE.BODY,
        tabUid: tabList[0]?.uid,
      });

      const defaultBrandRating = {
        uid: UID,
        sort_order: 1,
        enable: 1,
        block_type: BLOCK_TYPE.BRAND_RATING,
        title: "Brand Rating",
        content_attributes: {},
        style_attributes: {},
      };

      const blockData = {
        ...defaultBrandRating,
        tab_uid: tabList[0]?.uid,
        parent_id: parentId,
        sort_order: 1,
        position_id: "0",
      };
      dispatch(
        addBlock({
          tabUid: blockData.tab_uid,
          positionSlug: POSITION_TYPE.BODY,
          blockData: blockData,
        })
      );
    }
    hideAllModalAndScrollToFloating();
  };

  const checkConditionToAddBrandGift = () => {
    const isFloatingNotFull = floatingBlocks.length < COUNT_OF_BLOCK_MAXIMUM;
    if (isFloatingNotFull) {
      addBrandRatingFooterBlock();
      // hideAllModal();
    } else {
      setState(BRAND_RATING_BLOCK.SHOW_WARNING);
    }
  };

  const hideAllModalAndScrollToFloating = () => {
    hideAllModal();
    const floatingAreaElement = document.getElementById("content_blocks_body");

    setTimeout(() => {
      floatingAreaElement.scrollIntoView({
        behavior: "smooth",
      });
    }, 300);
  };
  useEffect(() => {
    if (country !== COUNTRY_VN) {
      return;
    }

    const isProductTourFinished = productTourState === 1;
    const isBrandRatingExist = floatingBlocks?.find(
      (block) => block.block_type === BLOCK_TYPE.BRAND_RATING
    );
    if (
      modalIntroBrandRatingState !== FloatingIntroStatus.DISABLE &&
      isBrandRatingExist
    ) {
      dispatch(
        setFloatingBlockIntroductionState({
          [FLOATING.BRAND_RATING_BLOCK]: FloatingIntroStatus.DISABLE,
        })
      );
      return;
    }
    if (
      modalIntroBrandRatingState !== FloatingIntroStatus.DISABLE &&
      isProductTourFinished &&
      userId &&
      modalIntroBrandGiftState === FloatingIntroStatus.DISABLE
    ) {
      setState(BRAND_RATING_BLOCK.SHOW_INTRO);
    }
  }, [modalIntroBrandRatingState, userId, productTourState]);
  return (
    <>
      <ModalBrandRatingGolive
        visible={state === BRAND_RATING_BLOCK.SHOW_INTRO}
        onAddBlock={checkConditionToAddBrandGift}
        onReject={hideAllModal}
      />
      <ModalWaringFloatingFull
        visible={state === BRAND_RATING_BLOCK.SHOW_WARNING}
        onContinue={hideAllModalAndScrollToFloating}
        onReject={hideAllModal}
      />
    </>
  );
};

export default BrandRatingIntroduction;
