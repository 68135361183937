import { requestSaveThemeTemplate, requestUploadImage } from "@share/api";
import FullScreenPopup from "@share/components/full-screen-popup";
import { THEME_TEMPLATE_KEY } from "@share/configs/const";
import {
  CheckCircleIcon,
  InfomationCircleIcon,
  InformationIcon,
  InformationTriangleIcon,
} from "@share/icons";
import { Modal, Toast } from "antd-mobile";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ThemeTemplateOverwrite from "./ThemeTemplateOverwrite";
import ThemeTemplateSaving from "./ThemeTemplateSaving";
import ThemeTemplateStore from "./ThemeTemplateStore";

const ThemeTemplateHandler = (props) => {
  const {
    isOpenAddNewThemeSelectionPanel,
    handleCancelAddNewThemeSelectionPanel,
    siteSettings,
    user,
    handleRedirectTabs,
  } = props;

  const { t } = useTranslation();

  const [isOpenThemeTemplateOverwrite, setIsOpenThemeTemplateOverwrite] =
    useState(false);
  const [isOpenAddNewTheme, setIsOpenAddNewTheme] = useState(false);
  const [isOpenThemeTemplateStore, setIsOpenThemeTemplateStore] =
    useState(false);

  const handleOverwriteTheme = () => {
    setIsOpenThemeTemplateOverwrite(true);
    handleCancelAddNewThemeSelectionPanel();
  };

  const handleAddNewTheme = () => {
    setIsOpenAddNewTheme(true);
    handleCancelAddNewThemeSelectionPanel();
  };

  const handleConfirmAddNewTheme = async (data) => {
    const { image, image_name, title, tags = [] } = data;
    let responseImage = null;
    let reponseSaveTheme = null;
    if (image) {
      const dataResquestUploadImage = {
        id: siteSettings.id,
        user_id: siteSettings.user_id,
        name: image_name,
        content: image,
      };
      try {
        responseImage = await requestUploadImage(dataResquestUploadImage);
      } catch (error) {
        console.log(error);
      }
      if (responseImage) {
        const dataRequest = {
          user_id: siteSettings.user_id,
          site_setting_id: siteSettings.id,
          title: title,
          image: responseImage.url,
          tags: tags ?? [],
        };
        try {
          reponseSaveTheme = await requestSaveThemeTemplate(dataRequest);
        } catch (error) {
          console.log(error);
        }
        if (/^20[0-9]$/.test(reponseSaveTheme?.status.toString())) {
          setIsOpenAddNewTheme(false);
          setIsOpenThemeTemplateStore(true);
          Toast.show({
            icon: (
              <CheckCircleIcon
                className="text-center inline-block"
                fillColor="#00B578"
              />
            ),
            content: (
              <div className="text-center">
                {t("ss_builder_save_new_theme_template_successfully_label")}
              </div>
            ),
          });
        }
      }
    }
  };

  const handleRequestOverwriteTheme = async (
    title,
    image,
    id,
    tags,
    handleCancelOverwriteThemeSaving
  ) => {
    let reponseSaveTheme = null;
    const dataRequest = {
      user_id: siteSettings.user_id,
      site_setting_id: siteSettings.id,
      title: title,
      image: image,
      id: id,
      tags: tags,
    };
    try {
      reponseSaveTheme = await requestSaveThemeTemplate(dataRequest);
    } catch (error) {
      console.log(error);
      const statusCode = error?.response?.status;
      if (statusCode == 404) {
        Modal.show({
          header: (
            <InformationIcon
              className="w-11 h-11"
              fillColor="#FF8F1F"
              onClick={() => {}}
            />
          ),
          content: (
            <div className="flex flex-col text-center items-center">
              <div className="text-lg font-bold leading-[25px]">
                {t("ss_builder_dont_apply_theme_content_modal_label")}
              </div>
              <div className="">
                {t("ss_builder_dont_apply_theme_content_modal_description")}
              </div>
            </div>
          ),
          className: "modal-error-class",
          closeOnAction: true,
          actions: [
            {
              key: "confirm",
              text: t(
                "ss_builder_dont_apply_theme_content_modal_btn_confirm_label"
              ),
            },
          ],
        });
      }
    }
    if (/^20[0-9]$/.test(reponseSaveTheme?.status.toString())) {
      setIsOpenThemeTemplateOverwrite(false);
      setIsOpenThemeTemplateStore(true);
      handleCancelOverwriteThemeSaving();
      Toast.show({
        icon: (
          <CheckCircleIcon
            className="text-center inline-block"
            fillColor="#00B578"
          />
        ),
        content: (
          <div className="text-center">
            {t("ss_builder_overwrite_theme_template_successfully_label")}
          </div>
        ),
      });
    }
  };

  const handleOverwriteThemeTemplate = async (
    data,
    handleCancelOverwriteThemeSaving
  ) => {
    const { image, image_name, title, id, tags = [] } = data;
    let responseImage = null;
    if (!image?.startsWith("http")) {
      const dataResquestUploadImage = {
        id: siteSettings.id,
        user_id: siteSettings.user_id,
        name: image_name,
        content: image,
      };
      try {
        responseImage = await requestUploadImage(dataResquestUploadImage);
      } catch (error) {
        console.log(error);
      }
      if (responseImage) {
        const imageUrl = responseImage.url;
        handleRequestOverwriteTheme(
          title,
          imageUrl,
          id,
          tags,
          handleCancelOverwriteThemeSaving
        );
      }
    } else {
      handleRequestOverwriteTheme(
        title,
        image,
        id,
        tags,
        handleCancelOverwriteThemeSaving
      );
    }
  };

  return (
    <div>
      <Modal
        bodyClassName="theme-template-selection-modal"
        header={
          <InfomationCircleIcon
            className="w-11 h-11"
            fillColor="#1890FF"
          />
        }
        visible={isOpenAddNewThemeSelectionPanel}
        content={
          <div className="flex flex-col text-center items-center">
            <div className="text-lg font-bold leading-[25px]">
              {t("ss_builder_add_new_theme_selection_panel_label")}
            </div>
            <div className="">
              {t("ss_builder_add_new_theme_selection_panel_description")}
            </div>
          </div>
        }
        actions={[
          {
            key: "add-new",
            text: t("ss_builder_add_new_theme_btn_label"),
            primary: true,
            onClick: handleAddNewTheme,
            style: {
              padding: "7px 0",
            },
          },
          {
            key: "overwrite",
            text: t("ss_builder_overwrite_theme_btn_label"),
            style: {
              border: "1px solid #EE3244",
              padding: "7px 0",
            },
            onClick: handleOverwriteTheme,
          },
          {
            key: "cancel",
            text: t("ss_builder_cancel_theme_selection_panel_label"),
            onClick: () => handleCancelAddNewThemeSelectionPanel(),
          },
        ]}
      />
      {isOpenAddNewTheme && (
        <FullScreenPopup
          visible={isOpenAddNewTheme}
          onMaskClick={() => {
            setIsOpenAddNewTheme(false);
          }}
        >
          <ThemeTemplateSaving
            handleCancelThemeSaving={() => setIsOpenAddNewTheme(false)}
            KEY={THEME_TEMPLATE_KEY.SAVE_NEW}
            handleConfirmThemeSaving={handleConfirmAddNewTheme}
          />
        </FullScreenPopup>
      )}
      {isOpenThemeTemplateOverwrite && (
        <FullScreenPopup
          visible={isOpenThemeTemplateOverwrite}
          onMaskClick={() => {
            setIsOpenThemeTemplateOverwrite(false);
          }}
        >
          <ThemeTemplateOverwrite
            onCancelThemeTemplateOverwrite={() =>
              setIsOpenThemeTemplateOverwrite(false)
            }
            onSaveThemeTemplateOverwrite={handleOverwriteThemeTemplate}
            user={user}
          />
        </FullScreenPopup>
      )}
      {isOpenThemeTemplateStore && (
        <FullScreenPopup
          visible={isOpenThemeTemplateStore}
          onMaskClick={() => {
            setIsOpenThemeTemplateStore(false);
          }}
        >
          <ThemeTemplateStore
            onCancelThemeTemplateStore={() =>
              setIsOpenThemeTemplateStore(false)
            }
            handleRedirectTabs={handleRedirectTabs}
            siteSettings={siteSettings}
            user={user}
          />
        </FullScreenPopup>
      )}
    </div>
  );
};

export default ThemeTemplateHandler;
