import { stepsInterface } from "@share/components/ProductTourComponent/interface";

export const THEME_TOUR_CLASS_NAME = {
  STEP_1: "theme-store-tour-step-1",
  STEP_2: "theme-store-tour-step-2",
};

export const STEP_INDEX = {
  STEP_1: 0,
  STEP_2: 1,
};

export const steps: stepsInterface[] = [
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/theme-store-tour/theme-store-tour-step-1.gif",
    title_key: "ss_builder_theme_store_tour_title_step_1",
    intro_key: "ss_builder_theme_store_tour_content_step_1",
    element_class: THEME_TOUR_CLASS_NAME.STEP_1,
    tooltip_class: "show-label-text",
    position: "bottom",
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/theme-store-tour/theme-store-tour-step-2.gif",
    title_key: "ss_builder_theme_store_tour_title_step_2",
    intro_key: "ss_builder_theme_store_tour_content_step_2",
    element_class: THEME_TOUR_CLASS_NAME.STEP_2,
    tooltip_class: "show-label-text product-tour-custom",
    position: "bottom-middle-aligned",
  },
];
