import ModalInfo, { ShowedContent } from "@share/components/ModalInfo";
import { useState } from "react";
import React from "react";

interface PositionInfoProps {
  actionBtn: React.ReactNode;
  info: ShowedContent;
}

const PositionInfo: React.FunctionComponent<PositionInfoProps> = (props) => {
  const { actionBtn, info } = props;
  const [visible, setVisible] = useState<boolean>(false);

  const openModal = () => setVisible(true);
  const closeModal = () => setVisible(false);

  return (
    <>
      <span onClick={openModal}>{actionBtn}</span>
      <ModalInfo
        visible={visible}
        info={info}
        onClose={closeModal}
      />
    </>
  );
};

export default PositionInfo;
