import { useAppSelector } from "@app/hooks";
import React from "react";
import Logged from "../Logged";
import style from "./style.module.scss";
import cls from "classnames";
import {
  BACKGROUND_SETTING_DEFAULT_VALUE,
  THEME_SETTING_BACKGROUND_TYPE,
} from "@share/configs/const";

interface Props {
  children: any;
}

export default function MobileLayout(props: Props) {
  const dynamicConfig = useAppSelector((state) => state.dynamicConfig);
  const isOpenFloatingPanel =
    dynamicConfig && dynamicConfig.isOpenFloatingPanel;
  return (
    <Logged>
      <div
        className={cls(
          `${style.mobileContainer} mobile-layout`,
          isOpenFloatingPanel ? "overflow-hidden" : ""
        )}
        // style={{height: "calc(100vh)"}}
      >
        {/* Background view element */}
        <div id="myBackgroundViewPortal"></div>
        <div
          className={cls(
            `layout-content ${style.mobileContent}`,
            !isOpenFloatingPanel && "pb-[84px]"
          )}
          id="passio-layout-content"
        >
          {props.children}
          <div id="myFloatingPanelPortal"></div>
        </div>
      </div>
    </Logged>
  );
}
