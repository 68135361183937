import { GENERAL_SETTING_DEFAULT_VALUE } from "@share/configs/const";
import React, { useEffect } from "react";

const GeneratorStyle = ({ generatorSetting }) => {
  const { primary_color, secondary_color } = generatorSetting || {};
  useEffect(() => {
    const designElement = document.querySelector(
      ".design-class"
    ) as HTMLElement;
    const tabMenuPortalElement = document.querySelector(
      "#tabMenuDesignPortal"
    ) as HTMLElement;
    // const designContentPortalElement = document.querySelector(
    //   "#myDesignContentPortal"
    // ) as HTMLElement;
    //Primary color
    designElement?.style.setProperty(
      "--adm-color-primary",
      primary_color ?? GENERAL_SETTING_DEFAULT_VALUE.PRIMARY_COLOR
    );
    tabMenuPortalElement?.style.setProperty(
      "--adm-color-primary",
      primary_color ?? GENERAL_SETTING_DEFAULT_VALUE.PRIMARY_COLOR
    );
    // designContentPortalElement.style.setProperty(
    //   "--adm-color-primary",
    //   primary_color ?? GENERAL_SETTING_DEFAULT_VALUE.PRIMARY_COLOR
    // );
    //Secondary color
    document.documentElement.style.setProperty(
      "--secondary-color",
      secondary_color ?? GENERAL_SETTING_DEFAULT_VALUE.SECONDARY_COLOR
    );
  }, [primary_color, secondary_color]);
  return null;
};

export default GeneratorStyle;
