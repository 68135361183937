import React, { useEffect } from "react";

const ButtonBgColor = ({ buttonSetting }) => {
  useEffect(() => {
    if (buttonSetting) {
      const { button_bg_color = {}, button_border_color = {} } = buttonSetting;
      for (var i = 1; i <= 10; i++) {
        if (button_bg_color[`button-bg-color-${i}`]) {
          document.documentElement.style.setProperty(
            `--button-bg-color-${i}`,
            button_bg_color[`button-bg-color-${i}`]
          );
        }
        if (button_border_color[`button-border-color-${i}`]) {
          document.documentElement.style.setProperty(
            `--button-border-color-${i}`,
            button_border_color[`button-border-color-${i}`]
          );
        }
      }
    }
  }, [buttonSetting]);
  return null;
};

export default ButtonBgColor;
