import Portal from "@share/HOC/portal";
import React from "react";
import { Modal, Button } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { WarningIconIntroBrandGift } from "@share/icons";

interface ModalWaringFloatingFullProps {
  visible: boolean;
  onReject: () => void;
  onContinue: () => void;
  onOpenIntroductionBrandGift: () => void;
}
const ModalWaringFloatingFull: React.FunctionComponent<
  ModalWaringFloatingFullProps
> = (props) => {
  const { visible, onContinue, onReject, onOpenIntroductionBrandGift } = props;
  const { t } = useTranslation();

  const ModalContent = () => (
    <>
      <div className="h-full w-full bg-[#fff] text-center">
        <div className="mb-2 flex justify-center align-items">
          <WarningIconIntroBrandGift />
        </div>
        <div className="mb-2 text-[18px] leading-[25px] font-bold text-[#333333]">
          {t("ss_builder_warning_brand_gift_floating_title")}
        </div>
        <p className="text-[15px] font-normal leading-[140%] text-textColorDefault text-[#333333]">
          {t("ss_builder_warning_brand_gift_floating_description")}
          <span
            className="pl-1 text-[#1890ff] cursor-pointer"
            onClick={onOpenIntroductionBrandGift}
          >
            {t("ss_builder_warning_brand_gift_floating_description_link")}
          </span>
        </p>
        <div className="mt-5">
          <Button
            color="primary"
            size="middle"
            fill="solid"
            block
            onClick={onContinue}
          >
            <div className="flex flex-row justify-center items-center">
              <span>
                {t("ss_builder_warning_brand_gift_floating_continue")}
              </span>
            </div>
          </Button>
          <Button
            style={{
              marginTop: "8px",
            }}
            color="primary"
            size="middle"
            block
            onClick={onReject}
            fill="none"
          >
            <div className="flex flex-row justify-center items-center">
              <span>{t("ss_builder_warning_brand_gift_floating_reject")}</span>
            </div>
          </Button>
        </div>
      </div>
    </>
  );
  return (
    <>
      <Portal elementId="modalPortal">
        <Modal
          className="modal-brand-gift-golive"
          visible={visible}
          forceRender={true}
          onClose={onContinue}
          closeOnMaskClick={false}
          //   image={image}
          content={<ModalContent />}
        />
      </Portal>
    </>
  );
};

export default ModalWaringFloatingFull;
