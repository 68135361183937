import { BLOCK_TYPE } from "@features/block/block.conf";
import { POSITION_SLUG, Position } from "@features/main/store/interface";

export function hasDigitalProductOnSite(positions: Position[]): boolean {
  for (const position of positions) {
    const blockLists =
      position?.slug === POSITION_SLUG.BODY
        ? position?.block_list[0]?.content_attributes?.tabs.flatMap(
            (tab) => tab.block_list
          )
        : position?.block_list;

    if (
      blockLists?.some(
        (block) => block?.block_type === BLOCK_TYPE.DIGITAL_PRODUCT
      )
    ) {
      return true;
    }
  }
  return false;
}
