export function handleOnKeyPress(e) {
  if (!/[+0-9]/.test(e?.key)) e?.preventDefault();
}

export const CloseModalIcon = (): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 6L18 18M6 18L18 6L6 18Z"
      stroke="#3F3F46"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const AlertIcon = (): JSX.Element => (
  <svg
    width="45"
    height="44"
    viewBox="0 0 45 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5001 16.5V22.5M22.5001 27.5H22.5184M9.79877 34.8334H35.2014C38.0248 34.8334 39.7884 31.7772 38.3768 29.3334L25.6754 7.33336C24.2638 4.88953 20.7364 4.88953 19.3248 7.33336L6.62344 29.3334C5.21177 31.7772 6.97544 34.8334 9.79877 34.8334Z"
      stroke="#DC2626"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export function IconSuccess(props) {
  return (
    <svg
      {...props}
      width="200"
      height="128"
      viewBox="0 0 200 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.5 19C77.6102 19 59 37.6102 59 60.5C59 83.3898 77.6102 102 100.5 102C123.39 102 142 83.3898 142 60.5C142 37.6102 123.39 19 100.5 19Z"
        fill="#389E0D"
      />
      <path
        d="M92.508 79.483C87.207 74.182 81.9385 68.8324 76.6213 63.5315C76.0377 62.9479 76.0377 61.9752 76.6213 61.3916L82.7328 55.2801C83.3164 54.6965 84.2891 54.6965 84.8727 55.2801L93.6103 64.0178L116.079 41.5332C116.679 40.9496 117.635 40.9496 118.235 41.5332L124.362 47.6609C124.962 48.2608 124.962 49.2172 124.362 49.8008L94.6478 79.483C94.0643 80.0828 93.1078 80.0828 92.508 79.483Z"
        fill="white"
      />
      <path
        d="M157.589 109.128C161.186 105.717 165.06 103.958 170.216 102.306L172.18 104.696C166.196 106.209 163.345 106.342 161.396 110.475L157.589 109.128Z"
        fill="#007BFF"
      />
      <path
        d="M30.1242 72.8123C25.9719 75.7148 22.0615 76.555 17.0369 76.8694L16 73.3992C21.7036 73.5282 24.2498 74.2861 27.1636 70L30.1242 72.8123Z"
        fill="#007BFF"
      />
      <path
        d="M164.834 53.0877C168.633 50.8512 170.741 47.9276 172.844 43.8592L170.518 41.5016C168.509 46.3393 168.158 48.7968 163.734 49.4031L164.834 53.0877Z"
        fill="#FF5C33"
      />
      <path
        d="M46.6125 29.2251C47.5031 29.2251 48.2251 28.5031 48.2251 27.6125C48.2251 26.722 47.5031 26 46.6125 26C45.722 26 45 26.722 45 27.6125C45 28.5031 45.722 29.2251 46.6125 29.2251Z"
        fill="#FF5C33"
      />
      <path
        d="M41.6125 99.2251C42.5031 99.2251 43.2251 98.5031 43.2251 97.6125C43.2251 96.722 42.5031 96 41.6125 96C40.722 96 40 96.722 40 97.6125C40 98.5031 40.722 99.2251 41.6125 99.2251Z"
        fill="#FF5C33"
      />
      <path
        d="M145.101 35.4708C145.991 35.4708 146.713 34.7488 146.713 33.8583C146.713 32.9677 145.991 32.2457 145.101 32.2457C144.21 32.2457 143.488 32.9677 143.488 33.8583C143.488 34.7488 144.21 35.4708 145.101 35.4708Z"
        fill="#FFB900"
      />
      <path
        d="M169 85C170.657 85 172 83.6569 172 82C172 80.3431 170.657 79 169 79C167.343 79 166 80.3431 166 82C166 83.6569 167.343 85 169 85Z"
        fill="#FF5C33"
      />
      <path
        d="M16.2246 44L13 47.2246L16.2246 50.4492L19.4492 47.2246L16.2246 44Z"
        fill="#FFB900"
      />
      <path
        d="M98.2246 117L95 120.225L98.2246 123.449L101.449 120.225L98.2246 117Z"
        fill="#FFB900"
      />
      <path
        d="M175.225 5L172 8.22459L175.225 11.4492L178.449 8.22459L175.225 5Z"
        fill="#FFB900"
      />
      <path
        d="M195.71 63.5691L192.485 66.7937L195.71 70.0183L198.935 66.7937L195.71 63.5691Z"
        fill="#14CCA1"
      />
      <path
        d="M42.605 7.94127C38.4414 10.3907 34.6262 10.8842 29.7869 10.781L29 7.36237C34.4713 7.94772 36.8724 8.883 39.9298 5L42.605 7.94127Z"
        fill="#FFB900"
      />
      <path
        d="M19.7712 112.63C16.7295 108.877 15.6738 105.178 15.0558 100.377L18.3192 99.0905C18.5545 104.588 17.9869 107.102 22.2817 109.547L19.7712 112.63Z"
        fill="#FFB900"
      />
      <path
        d="M101.177 15.0558C97.707 13.3433 95.4607 10.8632 93 7.34141L94.4142 5C96.9459 9.22808 97.6957 11.434 101.348 11.6114L101.177 15.0558Z"
        fill="#14CCA1"
      />
    </svg>
  );
}
export function IconTime(props) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.72056 1.76875C6.05807 1.49874 6.11279 1.00625 5.84278 0.668745C5.57277 0.331236 5.08029 0.276515 4.74278 0.546523L2.13408 2.63348C1.79657 2.90348 1.74185 3.39597 2.01186 3.73349C2.28187 4.071 2.77435 4.12572 3.11186 3.85571L5.72056 1.76875Z"
        fill="url(#paint0_linear_1570_49708)"
      />
      <path
        d="M5.72056 1.76875C6.05807 1.49874 6.11279 1.00625 5.84278 0.668745C5.57277 0.331236 5.08029 0.276515 4.74278 0.546523L2.13408 2.63348C1.79657 2.90348 1.74185 3.39597 2.01186 3.73349C2.28187 4.071 2.77435 4.12572 3.11186 3.85571L5.72056 1.76875Z"
        fill="url(#paint1_linear_1570_49708)"
      />
      <path
        d="M19.2857 0.546523C18.9483 0.276515 18.4558 0.331236 18.1857 0.668745C17.9158 1.00625 17.9704 1.49874 18.308 1.76875L20.9167 3.85571C21.2542 4.12572 21.7467 4.071 22.0167 3.73349C22.2867 3.39597 22.232 2.90348 21.8944 2.63348L19.2857 0.546523Z"
        fill="url(#paint2_linear_1570_49708)"
      />
      <path
        d="M19.2857 0.546523C18.9483 0.276515 18.4558 0.331236 18.1857 0.668745C17.9158 1.00625 17.9704 1.49874 18.308 1.76875L20.9167 3.85571C21.2542 4.12572 21.7467 4.071 22.0167 3.73349C22.2867 3.39597 22.232 2.90348 21.8944 2.63348L19.2857 0.546523Z"
        fill="url(#paint3_linear_1570_49708)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0143 1.15765C5.81907 1.15765 0.796875 6.17985 0.796875 12.375C0.796875 18.5702 5.81907 23.5924 12.0143 23.5924C18.2095 23.5924 23.2317 18.5702 23.2317 12.375C23.2317 6.17985 18.2095 1.15765 12.0143 1.15765Z"
        fill="url(#paint4_linear_1570_49708)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0143 1.15765C5.81907 1.15765 0.796875 6.17985 0.796875 12.375C0.796875 18.5702 5.81907 23.5924 12.0143 23.5924C18.2095 23.5924 23.2317 18.5702 23.2317 12.375C23.2317 6.17985 18.2095 1.15765 12.0143 1.15765Z"
        fill="url(#paint5_linear_1570_49708)"
      />
      <path
        d="M11 6.14562C11 5.51292 11.5129 5 12.1456 5C12.5275 4.99206 13.2912 5.21008 13.2912 6.14562V10.2555L13.3369 12.1685L13.5471 12.9739L14.1396 13.5587C14.507 13.8222 15.0088 14.1131 15.7734 14.5547L16.687 15.0822C17.2351 15.3985 17.4228 16.0992 17.1065 16.6471C16.7901 17.195 16.0895 17.3829 15.5415 17.0666L14.5817 16.5124L14.5814 16.5122C13.8754 16.1046 13.2748 15.7579 12.8047 15.421C12.304 15.0622 11.8774 14.6644 11.5627 14.1196C11.2482 13.5747 11.1172 13.0065 11.0567 12.3933C11 11.8176 11 11.1241 11 10.3088V6.14562Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1570_49708"
          x1="12.0143"
          y1="0.375"
          x2="12.0143"
          y2="23.5924"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F97794" />
          <stop
            offset="1"
            stopColor="#9708CC"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1570_49708"
          x1="1.84033"
          y1="0.375"
          x2="6.4424"
          y2="1.03297"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA1A71" />
          <stop
            offset="1"
            stopColor="#FD7718"
          />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1570_49708"
          x1="12.0143"
          y1="0.375"
          x2="12.0143"
          y2="23.5924"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F97794" />
          <stop
            offset="1"
            stopColor="#9708CC"
          />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1570_49708"
          x1="18.0142"
          y1="0.375"
          x2="22.6163"
          y2="1.03298"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA1A71" />
          <stop
            offset="1"
            stopColor="#FD7718"
          />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1570_49708"
          x1="12.0143"
          y1="0.375014"
          x2="12.0143"
          y2="23.5924"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F97794" />
          <stop
            offset="1"
            stopColor="#9708CC"
          />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1570_49708"
          x1="0.796875"
          y1="1.15765"
          x2="25.6493"
          y2="4.26675"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA1A71" />
          <stop
            offset="1"
            stopColor="#FD7718"
          />
        </linearGradient>
      </defs>
    </svg>
  );
}
export const IconTooltip = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ position: "relative" }}
  >
    <path
      fill="#DDDDDD"
      d="M7.99968 1.83337C4.31967 1.83337 1.33301 4.82004 1.33301 8.50004C1.33301 12.18 4.31967 15.1667 7.99968 15.1667C11.6797 15.1667 14.6663 12.18 14.6663 8.50004C14.6663 4.82004 11.6797 1.83337 7.99968 1.83337ZM8.66634 11.8334H7.33301V10.5H8.66634V11.8334ZM8.66634 9.16671H7.33301V5.16671H8.66634V9.16671Z"
    />
  </svg>
);
export function TrashIcon(props) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 10C0 4.47718 4.47715 3.05176e-05 10 3.05176e-05C15.5228 3.05176e-05 20 4.47718 20 10C20 15.5229 15.5228 20 10 20C4.47715 20 0 15.5229 0 10Z"
        fill="#DC2626"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.42857 5.00003C9.32248 5.00009 9.2185 5.02968 9.12827 5.08549C9.03804 5.1413 8.96514 5.22113 8.91771 5.31603L8.504 6.14289H6.57143C6.41988 6.14289 6.27453 6.20309 6.16737 6.31026C6.0602 6.41742 6 6.56276 6 6.71432C6 6.86587 6.0602 7.01121 6.16737 7.11838C6.27453 7.22554 6.41988 7.28574 6.57143 7.28574V13C6.57143 13.3031 6.69184 13.5938 6.90616 13.8082C7.12049 14.0225 7.41118 14.1429 7.71429 14.1429H12.2857C12.5888 14.1429 12.8795 14.0225 13.0938 13.8082C13.3082 13.5938 13.4286 13.3031 13.4286 13V7.28574C13.5801 7.28574 13.7255 7.22554 13.8326 7.11838C13.9398 7.01121 14 6.86587 14 6.71432C14 6.56276 13.9398 6.41742 13.8326 6.31026C13.7255 6.20309 13.5801 6.14289 13.4286 6.14289H11.496L11.0823 5.31603C11.0349 5.22113 10.962 5.1413 10.8717 5.08549C10.7815 5.02968 10.6775 5.00009 10.5714 5.00003H9.42857ZM8.28571 8.4286C8.28571 8.27705 8.34592 8.1317 8.45308 8.02454C8.56025 7.91738 8.70559 7.85717 8.85714 7.85717C9.0087 7.85717 9.15404 7.91738 9.2612 8.02454C9.36837 8.1317 9.42857 8.27705 9.42857 8.4286V11.8572C9.42857 12.0087 9.36837 12.1541 9.2612 12.2612C9.15404 12.3684 9.0087 12.4286 8.85714 12.4286C8.70559 12.4286 8.56025 12.3684 8.45308 12.2612C8.34592 12.1541 8.28571 12.0087 8.28571 11.8572V8.4286ZM11.1429 7.85717C10.9913 7.85717 10.846 7.91738 10.7388 8.02454C10.6316 8.1317 10.5714 8.27705 10.5714 8.4286V11.8572C10.5714 12.0087 10.6316 12.1541 10.7388 12.2612C10.846 12.3684 10.9913 12.4286 11.1429 12.4286C11.2944 12.4286 11.4398 12.3684 11.5469 12.2612C11.6541 12.1541 11.7143 12.0087 11.7143 11.8572V8.4286C11.7143 8.27705 11.6541 8.1317 11.5469 8.02454C11.4398 7.91738 11.2944 7.85717 11.1429 7.85717Z"
        fill="white"
      />
    </svg>
  );
}
export const ClearIcon = ({
  className = "",
  style = {},
  width = 16,
  height = 16,
  fillColor = "#fff",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 21.5999C14.546 21.5999 16.9878 20.5885 18.7881 18.7881C20.5885 16.9878 21.5999 14.546 21.5999 11.9999C21.5999 9.45382 20.5885 7.01203 18.7881 5.21168C16.9878 3.41133 14.546 2.3999 11.9999 2.3999C9.45382 2.3999 7.01203 3.41133 5.21168 5.21168C3.41133 7.01203 2.3999 9.45382 2.3999 11.9999C2.3999 14.546 3.41133 16.9878 5.21168 18.7881C7.01203 20.5885 9.45382 21.5999 11.9999 21.5999V21.5999ZM10.4483 8.7515C10.222 8.53291 9.91886 8.41196 9.60422 8.41469C9.28959 8.41743 8.98861 8.54363 8.76612 8.76612C8.54363 8.98861 8.41743 9.28959 8.41469 9.60422C8.41196 9.91886 8.53291 10.222 8.7515 10.4483L10.3031 11.9999L8.7515 13.5515C8.63689 13.6622 8.54547 13.7946 8.48258 13.941C8.41969 14.0874 8.38659 14.2449 8.3852 14.4042C8.38382 14.5636 8.41418 14.7216 8.47452 14.869C8.53485 15.0165 8.62396 15.1505 8.73663 15.2632C8.8493 15.3758 8.98328 15.465 9.13076 15.5253C9.27823 15.5856 9.43625 15.616 9.59558 15.6146C9.75492 15.6132 9.91238 15.5801 10.0588 15.5172C10.2052 15.4543 10.3376 15.3629 10.4483 15.2483L11.9999 13.6967L13.5515 15.2483C13.7778 15.4669 14.0809 15.5878 14.3956 15.5851C14.7102 15.5824 15.0112 15.4562 15.2337 15.2337C15.4562 15.0112 15.5824 14.7102 15.5851 14.3956C15.5878 14.0809 15.4669 13.7778 15.2483 13.5515L13.6967 11.9999L15.2483 10.4483C15.4669 10.222 15.5878 9.91886 15.5851 9.60422C15.5824 9.28959 15.4562 8.98861 15.2337 8.76612C15.0112 8.54363 14.7102 8.41743 14.3956 8.41469C14.0809 8.41196 13.7778 8.53291 13.5515 8.7515L11.9999 10.3031L10.4483 8.7515Z"
        fill="#BFBFBF"
      />
    </svg>
  );
};

export const INPUT_MAX_INVITATION = 60;
export const INPUT_MAX_TXTBTN = 40;
export const INPUT_MIN_PRICE_GB = 1;
export const INPUT_MAX_PRICE_GB = 10000;
export const INPUT_MIN_PRICE = 2000; // for vietnam
export const INPUT_MAX_PRICE = 9999999999;
export const COUNTRY_VN = "VN";
export const COUNTRY_EN = "OTHER";
export const COUNTRY_ID = "ID";
export const COUNTRY_SG = "SG";
export const COUNTRY_PH = "PH";
export const COUNTRY_MY = "MY";
export const COUNTRY_TH = "TH";
export const CURRENCY_ID = "IDR";
export const CURRENCY_VN = "VND";

import React from "react";
import style from "./index.module.scss";
export function HintPrice({ price1, isShow, handleChoicePrice, country }) {
  const price = price1.toString().replace(/\D/g, "").replace(",", "");
  const multi = country == "VN" ? [1000, 10000, 100000] : [10, 100];
  const maxPrice = country == "VN" ? INPUT_MAX_PRICE : INPUT_MAX_PRICE_GB;
  const minPrice = country == "VN" ? INPUT_MIN_PRICE : INPUT_MIN_PRICE_GB;
  return (
    <>
      <div className={style.listPrice}>
        {price && isShow
          ? multi.map((xs, index) =>
              xs * price <= maxPrice &&
              xs * price >= minPrice &&
              xs * price != 0 ? (
                <div
                  onClick={() => handleChoicePrice(xs * price)}
                  className={`${style.choicePrice} text-sm font-medium text-center`}
                  key={index}
                >
                  {(price * xs)
                    .toString()
                    .replace(/\D/g, "")
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1,`)}
                </div>
              ) : (
                ""
              )
            )
          : ""}
      </div>
    </>
  );
}
