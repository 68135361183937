import { useAppDispatch, useAppSelector } from "@app/hooks";
import { requestSaveDomain, requestValidateDomain } from "@share/api";
import { CheckCircleIcon, GlobalIcon, InformationIcon } from "@share/icons";
import { validDomain } from "@share/lib";
import { Button, Input, Modal } from "antd-mobile";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import userApi from "@features/user/userApi";
import { setProfile } from "@features/user/userSlice";
import gaEvents, {
  ACTION_CONFIG,
  CATEGORY_CONFIG,
  LABEL_CONFIG,
} from "@share/lib/ga-events";
import { useToast } from "@share/hooks/toast";
import debounce from "lodash/debounce";

const DOMAIN_MAX_LENGTH = 50;

type Props = {
  onCancelPersonalDomainSetting: any;
};
const EXTENSION_DOMAIN = ".passio.eco";

const PersonalDomainSetting = (props: Props) => {
  const { onCancelPersonalDomainSetting } = props;
  const { t } = useTranslation();
  const { SuccessToast } = useToast();
  const dispatch = useAppDispatch();

  const kol = useAppSelector((state) => state?.user?.kol);
  const { domain } = kol || {};

  const domainDefault = domain?.split(".")[0] ?? "";

  const [valueDomain, setValueDomain] = useState(domainDefault);
  const [errorCode, setErrorCode] = useState("");
  const [isValidDomain, setIsValidDomain] = useState(false);
  const [isAllowedDomain, setIsAllowedDomain] = useState(false);
  const [checkDisableBtn, setCheckDisableBtn] = useState(false);
  const [checkLoadingBtn, setCheckLoadingBtn] = useState(false);

  const extensionDomain = useMemo(() => {
    const splitString = domain?.split(".");
    if (!splitString) {
      return EXTENSION_DOMAIN;
    }
    const indexLast1 = splitString[splitString.length - 1];
    const indexLast2 = splitString[splitString.length - 2];

    return `.${indexLast2}.${indexLast1}`;
  }, [domain]);

  const isDisableBtnSave = useMemo(() => {
    if (
      isValidDomain ||
      !valueDomain ||
      checkDisableBtn ||
      errorCode ||
      valueDomain === domainDefault
    ) {
      return true;
    } else return false;
  }, [isValidDomain, valueDomain, checkDisableBtn, errorCode, domainDefault]);

  const isEditDataStatus = useMemo(() => {
    if (valueDomain !== domainDefault) {
      return true;
    } else return false;
  }, [valueDomain, domainDefault]);

  const validateDomain = (data) => {
    if (data && data !== domainDefault) {
      const valueRequest = data.toLowerCase();
      const validate = async () => {
        let response = null;
        try {
          response = await requestValidateDomain(valueRequest);
        } catch (error) {
          console.log(error);
        }
        if (response) {
          setCheckLoadingBtn(false);
          setCheckDisableBtn(false);
          const isSuccess = response?.data?.success;
          if (!isSuccess) {
            const errorCode = response?.data?.message?.code;
            setErrorCode(errorCode);
            setIsAllowedDomain(false);
          } else {
            setIsAllowedDomain(true);
            setErrorCode(null);
          }
        }
      };
      const checkValidDomain = validDomain(data);
      if (checkValidDomain) {
        setCheckLoadingBtn(true);
        validate();
        setIsValidDomain(false);
      } else {
        setIsValidDomain(true);
        setErrorCode(null);
        setIsAllowedDomain(false);
      }
    } else {
      setErrorCode(null);
      setIsValidDomain(false);
    }
  };

  const handleChangeDomain = useCallback(debounce(validateDomain, 1000), []);

  const fetchAccount = async () => {
    try {
      const response = await userApi.me();
      dispatch(setProfile(response));
    } catch (error) {
      console.log(error);
    }
  };

  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);

  const handleSaveDomainSetting = async () => {
    let response = null;
    const valueRequest = valueDomain.toLowerCase();
    try {
      response = await requestSaveDomain(valueRequest);
    } catch (error) {
      console.log(error);
    }
    if (response && /^20[0-9]$/.test(response.status.toString())) {
      fetchAccount();
      SuccessToast(t("ss_builder_save_succesfully_label"));
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.SITE_SETTINGS,
        `SiteSettings_Domain_${LABEL_CONFIG.EDIT}`,
        LABEL_CONFIG.EDIT,
        ACTION_CONFIG.DOMAIN
      );
      onCancelPersonalDomainSetting();
    } else handleShowFailAlert();
  };

  const handleShowFailAlert = () => {
    Modal.alert({
      header: (
        <InformationIcon
          className="w-11 h-11"
          fillColor="var(--adm-color-primary)"
          onClick={() => {}}
        />
      ),
      title: <div>{t("ss_builder_theme_setting_save_failed_title")}</div>,
      content: (
        <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
          {t("ss_builder_theme_setting_save_failed_content")}
        </div>
      ),
      confirmText: t("ss_builder_close_panel_edit_text_block"),
      onConfirm: () => {
        return;
      },
    });
  };

  const handleCancelDomainSetting = useCallback(() => {
    if (isEditDataStatus) {
      Modal.confirm({
        header: (
          <InformationIcon
            className="w-11 h-11"
            fillColor="#FF8F1F"
            onClick={() => {}}
          />
        ),
        title: <div>{t("ss_builder_cancel_edit_block_title")}</div>,
        content: (
          <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
            {t("ss_builder_cancel_edit_block_content")}
          </div>
        ),
        confirmText: t("ss_builder_continue_edit_block_label"),
        cancelText: t("ss_builder_cancel_block_btn_label"),
        onConfirm: () => {
          return;
        },
        onCancel: () => {
          gaEvents.submitEventV2(
            passioPageProfile,
            CATEGORY_CONFIG.SITE_SETTINGS,
            `SiteSettings_Domain_${LABEL_CONFIG.CANCEL}`,
            LABEL_CONFIG.CANCEL,
            ACTION_CONFIG.DOMAIN
          );
          onCancelPersonalDomainSetting();
        },
      });
    } else {
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.SITE_SETTINGS,
        `SiteSettings_Domain_${LABEL_CONFIG.CANCEL}`,
        LABEL_CONFIG.CANCEL,
        ACTION_CONFIG.DOMAIN
      );
      onCancelPersonalDomainSetting();
    }
  }, [isEditDataStatus]);

  return (
    <div className="relative h-full">
      <div
        className="text-lg text-[#333333] font-normal flex text-center items-center gap-1 bg-white p-3 justify-center"
        style={{
          boxShadow: "inset 0px -1px 0px #EEEEEE",
        }}
      >
        <GlobalIcon className="inline-block" />
        {t("ss_builder_site_domain_setting_label")}
      </div>
      <div className="flex flex-row gap-1 mb-3 p-3 bg-white">
        <InformationIcon
          className="ml-1 w-[14px] h-[14px]"
          onClick={() => {}}
          fillColor="#1890FF"
        />
        <span className="text-3 text-[#333333] leading-4">
          {t("ss_builder_domain_site_setting_alert_infomation")}
        </span>
      </div>
      <div className="px-4 py-[13px] bg-white">
        <div className="text-[15px] leading-[150%] text-[#666666]">
          <span className="text-red-600">*</span>
          {t("ss_builder_enter_domain_site_setting_label")}
        </div>
        <div className="flex flex-row">
          <Input
            value={valueDomain || ""}
            onChange={(val) => {
              setValueDomain(val);
              handleChangeDomain(val);
              setCheckDisableBtn(true);
            }}
            placeholder={t(
              "ss_builder_affiliate_product_btn_see_more_placeholder"
            )}
            maxLength={DOMAIN_MAX_LENGTH}
          />
          <div className="text-[13px] leading-[131%] text-[#333333]">
            {extensionDomain}
          </div>
        </div>
        <div className="text-[13px] leading-[17px] text-[#999999] mt-1">
          {t("ss_builder_domain_site_setting_rule")}
        </div>
        {isValidDomain && (
          <div className="text-[13px] leading-[17px] text-[#FF3141]">
            {t("ss_builder_malformed_domain_warning")}
          </div>
        )}
        {errorCode && (
          <div className="text-[13px] leading-[17px] text-[#FF3141]">
            {t(`ss_validate_domain_error_${errorCode}`)}
          </div>
        )}
        {isAllowedDomain && (
          <div className="text-[13px] leading-[17px] text-[#00B578] flex flex-row gap-1">
            <CheckCircleIcon
              className="text-center inline-block w-[16px] h-[16px]"
              fillColor="#00B578"
            />
            {t("ss_builder_allowed_domain_label")}
          </div>
        )}
      </div>

      <div className="max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[10] bg-[#FFFFFF]">
        <div className="w-1/2 text-center">
          <Button
            onClick={handleCancelDomainSetting}
            className="adm-button adm-button-default adm-button-shape-default w-full"
          >
            {t("ss_builder_close_panel")}
          </Button>
        </div>
        <div className="w-1/2 text-center">
          <Button
            color="primary"
            disabled={isDisableBtnSave}
            className="adm-button adm-button-primary adm-button-shape-default w-full"
            onClick={() => handleSaveDomainSetting()}
            loading={checkLoadingBtn}
          >
            {t("ss_builder_save")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PersonalDomainSetting;
