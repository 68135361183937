import { POSITION_TYPE } from "@features/block/config/interface";
import PositionInfo from "@features/design/components/PositionInfo";
import {
  BLOCK_GROUP_TYPE,
  BLOCK_TYPE_BY_GROUP,
} from "@features/design/design.config";
import { CREATOR_TYPE_ENUM, Position } from "@features/main/store/interface";
import { getBlocksBySlug } from "@features/main/store/positionsSlice";
import { COUNTRY_TH, POSITION_DROPPABLE_ID } from "@share/configs/const";
import { InfomationPositionIcon } from "@share/icons";
import { Divider } from "antd-mobile";
import cls from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PlaceholderProps } from "../PlaceholderCustom";
import BlockGroup from "./components/BlockGroup";
import BlockGroupWrapper from "./components/BlockGroupWrapper";
import EmptyBlock from "@features/design/components/EmptyBlock";
import CopyRight from "@features/design/components/CopyRight";
import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { COUNTRY_TH_BLOCK_HIDDEN } from "@features/block/config/block-selection";

enum POSITON_STATUS {
  ENABLE = 1,
  DISABLE = 0,
}

const PopupInfoIcon = ({ image, titleKey, desKey }) => {
  const { t } = useTranslation();
  return (
    <PositionInfo
      actionBtn={
        <div className="cursor-pointer">
          <InfomationPositionIcon
            width={13}
            height={13}
            className="ml-1"
          />
        </div>
      }
      info={{
        image: image,
        title: t(titleKey),
        desctiption: t(desKey),
      }}
    />
  );
};

interface ExpandDesignProps {
  position: Position;
  expandPopupDrag: boolean;
  expandFloatingIconsDrag: boolean;
  addNewBlockButtonDrag: boolean;
  placeholderProps: PlaceholderProps;
}

const ExpandDesign: React.FunctionComponent<ExpandDesignProps> = (props) => {
  const {
    position,
    expandPopupDrag,
    expandFloatingIconsDrag,
    addNewBlockButtonDrag,
    placeholderProps,
  } = props;
  const { t } = useTranslation();
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);
  const country = useSelector((state: RootState) => state?.user?.kol?.country);
  const creatorType = passioPageProfile?.creator_type;
  const isPositionEnabled = Object.is(position.enable, POSITON_STATUS.ENABLE);
  const listBlocksData =
    country === COUNTRY_TH
      ? position?.block_list?.filter(
          (block) => !COUNTRY_TH_BLOCK_HIDDEN.includes(block?.block_type)
        )
      : position?.block_list;
  const imgPopupBlocks = useSelector(
    getBlocksBySlug(POSITION_TYPE.EXPAND, BLOCK_TYPE_BY_GROUP.POPUP)
  );

  const floatingBlocks = useSelector(
    getBlocksBySlug(POSITION_TYPE.EXPAND, BLOCK_TYPE_BY_GROUP.FLOATING)
  );

  const BUNCH_OF_BLOCK_GROUP = {
    [BLOCK_GROUP_TYPE.POPUP]: {
      includeType: BLOCK_TYPE_BY_GROUP.POPUP,
      type: BLOCK_GROUP_TYPE.POPUP,
      label_language_key: "ss_builder_design_group_image_popup_title",
      blocks: imgPopupBlocks,
      droppable_id: POSITION_DROPPABLE_ID.EXPAND_POPUP,
      introModal: (
        <PopupInfoIcon
          titleKey={"ss_builder_image_popup_introduction_title"}
          desKey={"ss_builder_image_popup_introduction_description"}
          image={
            "https://image.passio.eco/page-builder/page-builder/position-modal/Popup.gif"
          }
        />
      ),
    },
    [BLOCK_GROUP_TYPE.FLOATING]: {
      includeType: BLOCK_TYPE_BY_GROUP.FLOATING,
      type: BLOCK_GROUP_TYPE.FLOATING,
      label_language_key: "ss_builder_design_group_floating_title",
      blocks: floatingBlocks,
      droppable_id: POSITION_DROPPABLE_ID.EXPAND_FLOATING_ICONS,
      introModal: (
        <PopupInfoIcon
          titleKey={"ss_builder_floating_introduction_title"}
          desKey={"ss_builder_floating_introduction_description"}
          image={
            "https://image.passio.eco/page-builder/page-builder/position-modal/Floating.gif"
          }
        />
      ),
    },
  };

  return (
    <>
      <Divider
        contentPosition="left"
        className={cls("divider-design adm-default", "expand-position-title")}
      >
        <div className="flex justify-center items-center font-normal">
          {t("ss_builder_add_block_expand_title")}
          <PositionInfo
            actionBtn={
              <div className="cursor-pointer">
                <InfomationPositionIcon
                  width={13}
                  height={13}
                  className="ml-1"
                />
              </div>
            }
            info={{
              image:
                "https://image.passio.eco/page-builder/page-builder/position-modal/Expand-position.gif",
              title: t("ss_builder_expand_introduction_title"),
              desctiption: t("ss_builder_expand_introduction_description"),
            }}
          />
        </div>
      </Divider>
      <div
        className={cls(
          "position-wrapper",
          isPositionEnabled ? "" : "hide-position"
        )}
      >
        {listBlocksData?.length > 0 ? (
          Object.values(BUNCH_OF_BLOCK_GROUP).map((block_group, index) => {
            return (
              block_group.blocks.length > 0 && (
                <div
                  className="mb-2"
                  key={index}
                >
                  {block_group.type === BLOCK_GROUP_TYPE.FLOATING && (
                    <div id="floating-icons-footer"></div>
                  )}
                  <BlockGroupWrapper
                    labelText={t(block_group.label_language_key)}
                    icon={block_group.introModal}
                  >
                    <BlockGroup
                      isDrag={
                        block_group?.type === BLOCK_GROUP_TYPE.POPUP
                          ? expandPopupDrag
                          : expandFloatingIconsDrag
                      }
                      blocks={block_group.blocks}
                      listOrderedBlock={listBlocksData}
                      positionSlug={position.slug}
                      addNewBlockButtonDrag={addNewBlockButtonDrag}
                      droppableId={block_group.droppable_id}
                      placeholderProps={placeholderProps}
                    />
                  </BlockGroupWrapper>
                </div>
              )
            );
          })
        ) : (
          <>
            <div id="floating-icons-footer"></div>
            <EmptyBlock data={{ position: "expand", uid: "" }} />
          </>
        )}
      </div>
      {creatorType === CREATOR_TYPE_ENUM.NORMAL && <CopyRight />}
    </>
  );
};

export default React.memo(ExpandDesign);
