import { FONT_GOOGLE, TITLE_SETTING_DEFAULT_VALUE } from "@share/configs/const";
import React, { useEffect } from "react";

const TitleStyle = ({ titleSetting }) => {
  useEffect(() => {
    const {
      font_family: fontFamily,
      font_color: titleColor,
      font_size: titleFontSize,
      scale: titleScale,
      font_weight: titleFontWeight,
      line_height: titleLineHeight,

      using_font_custom: usingFontCustom,
      font_family_custom: fontFamilyCustom,
    } = titleSetting || {};
    //Title font family
    let titleFontFamily;
    if (!!usingFontCustom && usingFontCustom === FONT_GOOGLE) {
      titleFontFamily =
        fontFamilyCustom?.toString()?.trim() ||
        TITLE_SETTING_DEFAULT_VALUE.FONT_FAMILY_CUSTOM;
    } else {
      titleFontFamily = fontFamily || TITLE_SETTING_DEFAULT_VALUE.FONT_FAMILY;
    }

    document.documentElement.style.setProperty(
      "--heading-font-family",
      titleFontFamily ?? TITLE_SETTING_DEFAULT_VALUE.FONT_FAMILY
    );

    //Title color
    document.documentElement.style.setProperty(
      "--heading-font-color",
      titleColor ?? TITLE_SETTING_DEFAULT_VALUE.FONT_COLOR
    );
    //Title font size
    document.documentElement.style.setProperty(
      "--heading-font-size",
      titleFontSize && typeof titleFontSize === "number"
        ? `${titleFontSize}px`
        : `${TITLE_SETTING_DEFAULT_VALUE.FONT_SIZE}px`
    );
    //Title font weight
    document.documentElement.style.setProperty(
      "--heading-font-weight",
      titleFontWeight ?? TITLE_SETTING_DEFAULT_VALUE.FONT_WEIGHT
    );
    //Title line height
    document.documentElement.style.setProperty(
      "--heading-line-height",
      titleLineHeight ?? TITLE_SETTING_DEFAULT_VALUE.LINE_HEIGHT
    );
    //Title font scale
    document.documentElement.style.setProperty(
      "--heading-font-ratio",
      titleScale ?? TITLE_SETTING_DEFAULT_VALUE.FONT_SCALE
    );
  }, [titleSetting]);
  return null;
};

export default TitleStyle;
