import { setDisplaySetting } from "@features/main/store/siteSettingSlice";
import { requestUploadImage } from "@share/api";
import { saveSite } from "..";
import { setDynamicParams } from "@features/main/store/dynamicSlice";

export const saveBackgroundSetting =
  (data, meta) => async (dispatch, getState) => {
    const currentState = getState();
    const { siteSettings } = currentState;
    let image = data.image;
    let responseImage = null;
    if (data.base64_image) {
      const dataResquestUploadImage = {
        id: siteSettings.id,
        user_id: siteSettings.user_id,
        name: data.image_name,
        content: data.base64_image,
      };
      try {
        responseImage = await requestUploadImage(dataResquestUploadImage);
      } catch (error) {
        console.log(error);
      }
      if (responseImage) image = responseImage.url;
    }
    const newData = {
      color: data.color,
      gradient: data.gradient,
      image: image,
      type: data.type,
      blur: data.blur,
      enable_overlay: data.enable_overlay,
      overlay_color: data.overlay_color,
      color_type: data.color_type,
    };
    const displaySettings = {
      ...siteSettings.display_setting,
      background: newData,
      meta_data: meta,
    };
    dispatch(
      setDynamicParams({
        data: {
          isSaving: true,
        },
      })
    );
    dispatch(setDisplaySetting({ data: displaySettings }));
    // dispatch(saveSite(true));
  };

export const updateMetaDataSetting = (meta) => (dispatch, getState) => {
  const currentState = getState();
  const { siteSettings } = currentState;
  const displaySettings = {
    ...siteSettings.display_setting,
    meta_data: meta,
  };
  dispatch(setDisplaySetting(displaySettings));
};

export const saveThemeTitleSetting =
  (data, headingColorGenerate = {}) =>
  (dispatch, getState) => {
    const currentState = getState();
    const { siteSettings } = currentState;
    const displaySettings = {
      ...siteSettings.display_setting,
      title_setting: data,
      heading_color_generate: headingColorGenerate,
    };
    dispatch(setDisplaySetting({ data: displaySettings }));
    // dispatch(saveSite());
  };

export const saveThemeParagraphSetting =
  (data, textColorGenerate = {}) =>
  (dispatch, getState) => {
    const currentState = getState();
    const { siteSettings } = currentState;
    const displaySettings = {
      ...siteSettings.display_setting,
      paragraph_setting: data,
      text_color_generate: textColorGenerate,
    };
    dispatch(setDisplaySetting({ data: displaySettings }));
    // dispatch(saveSite());
  };

export const saveButtonThemeSetting = (data) => (dispatch, getState) => {
  const currentState = getState();
  const { siteSettings } = currentState;
  const displaySettings = {
    ...siteSettings.display_setting,
    button_setting: data,
  };
  dispatch(setDisplaySetting({ data: displaySettings }));
  // dispatch(saveSite());
};
