import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FullScreenPopup from "@share/components/full-screen-popup";
import { Button } from "antd-mobile";
import DetailsCampaign from "./Details";
import UploadTestImage from "./UploadImage";
import { getListCampaign } from "@share/api";

interface CampainDetailInterFace {
  bonus: string,
  cpc_campaign_id: number,
  cpc_campaigns_upload_creators_id: number,
  created_at: string,
  creator_banner_image: string,
  creator_end_date: string,
  creator_start_date: string,
  description: string,
  display_type: string,
  end_date: string,
  id: number,
  image: string,
  join_status: number,
  popup_image: string,
  requirement: string,
  seller_id: number,
  shorten_code: string,
  start_date: string,
  title: string,
  updated_at: string,
}

export default function JoinCampaign(props) {
  const { t } = useTranslation();

  const [details, setDetails] = useState<CampainDetailInterFace>()

  const [showDetails, setShowDetails] = useState(false)
  const [showUploadImage, setShowUploadImage] = useState(false)

  const getWebsiteConfig = async () => {
    let response = null;
    try {
      response = await getListCampaign();
      setDetails(response[0])
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getWebsiteConfig();
  }, [])
  useEffect(() => {
    getWebsiteConfig();
  }, [showUploadImage])
  const handleButton = () => {
    if (details?.join_status == 0) setShowDetails(true)
    else setShowUploadImage(true)
  }

  if (!details || !Object.keys(details).length) return <></>
  return (
    <div className={`p-3 bg-[#fff] rounded flex flex-col gap-4 mb-3`}>
      <div className="flex flex-col gap-2">
        <div className="font-normal text-[15.6px] text-[#333333]">
          {t("ss_brand_cpc_text_title")} {details?.title}
        </div>
        <div className="font-normal text-[12px] text-[#8C8C8C]">
          {details?.title} {t('ss_brand_cpc_text_content')}
        </div>
        {details?.join_status == 3
          ? <div className="px-2 py-1 rounded bg-[#FFCCC7] w-fit text-[#F5222D] text-[10.8px] font-normal">{t('ss_brand_cpc_reject')}</div>
          : <></>
        }
        {details?.join_status == 2
          ? <div className="px-2 py-1 rounded bg-[#00B578] w-fit text-[#FFFFFF] text-[10.8px] font-normal">{t('ss_brand_cpc_success')}</div>
          : <></>
        }
      </div>
      {details?.join_status != 2
        ? <Button color="primary" fill="solid" size="middle" className="w-fit" onClick={handleButton}>
            {details?.join_status == 0 ? t("ss_brand_cpc_join_now") : (details?.join_status == 2 ? "" : t("ss_brand_cpc_upload_image"))}
          </Button>
        :<></>
      }
        
      <FullScreenPopup visible={showDetails} onMaskClick={() => setShowDetails(false)}>
        <DetailsCampaign
            visible={showDetails}
            setShowDetails={setShowDetails}
            setShowUploadImage={setShowUploadImage}
            details={details}
          />
      </FullScreenPopup>
      <FullScreenPopup visible={showUploadImage} onMaskClick={() => setShowUploadImage(false)}>
        <UploadTestImage
            visible={showUploadImage}
            setShowUploadImage={setShowUploadImage}
            details={details}
          />
      </FullScreenPopup>
    </div>
  );
};
