import React from "react";
import Heading from "@features/setting/components/heading";
import BodySetting from "@features/setting/components/body";
import useGetWebsiteConfig from "../hooks/useGetWebsiteConfig";


const Setting = () => {
  useGetWebsiteConfig();
  return (
    <>
      <div className="max-width-content-class theme-setting-class m-auto">
        <Heading />
        <div className="wrapper-setting">
          <BodySetting />
        </div>
      </div>
    </>
  );
};

export default Setting;
