import React from "react";
import cls from "classnames";
import { AddIcon } from "@share/icons";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { PRODUCT_TOUR_CLASS_NAME } from "../../ProductTour/product-tour-config";
import { useSelector } from "react-redux";
import { RootState } from "@app/store";
import { FirstGuideStateEnum } from "@features/design/store/externalStateSlice";
import { POSITION_DROPPABLE_ID } from "@share/configs/const";

export const ADD_NEW_BUTTON_DRAGGABLE_ID =
  "add-new-block-button-dragging-enable";

const AddNewButtonDragging = (props) => {
  const {
    handleOpenPositionSelectedList,
    showFirstGuide,
    addNewBlockStyle,
    isHideButtonAddBlock,
    addNewBlockButtonDragBeforeCapture,
  } = props;

  const guideState = useSelector<RootState, any>(
    (state) => state.externalState.guide_state
  );

  const renderAddBlockAnimation = () => (
    <div
      className={cls(
        `flex items-center justify-center w-[48px] h-[48px] rounded-full bg-[#EE3244] cursor-pointer `,
        PRODUCT_TOUR_CLASS_NAME.STEP_2
      )}
      style={{
        boxShadow: "0px 0px 30px rgba(51, 51, 51, 0.4)",
        margin: "auto",
      }}
      onClick={showFirstGuide}
    >
      {!addNewBlockButtonDragBeforeCapture && (
        <div className="pointer-events-none">
          <div className="add-block-circle"></div>
          <div className="add-block-circle-fill"></div>
        </div>
      )}
      <AddIcon
        className={!addNewBlockButtonDragBeforeCapture ? "icon-shake" : ""}
      />
    </div>
  );

  const getItemStyle = (isDragging, draggableStyle) => {
    return {
      width: isDragging ? "100vw" : "auto",
      maxWidth: "500px",
      ...draggableStyle,
    };
  };

  return (
    <Droppable
      droppableId={POSITION_DROPPABLE_ID.ADD_NEW_BLOCK_BUTTON_DRAGGING}
      isDropDisabled={true}
    >
      {(provided, snapshot): JSX.Element => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {!isHideButtonAddBlock ? (
            <section
              id={POSITION_DROPPABLE_ID.ADD_NEW_BLOCK_BUTTON_DRAGGING}
              key={POSITION_DROPPABLE_ID.ADD_NEW_BLOCK_BUTTON_DRAGGING}
              className="fixed left-[50%] translate-x-[-50%] z-10"
              style={{ ...addNewBlockStyle }}
            >
              <Draggable
                draggableId={ADD_NEW_BUTTON_DRAGGABLE_ID}
                index={-1}
                key={ADD_NEW_BUTTON_DRAGGABLE_ID}
              >
                {(provided, snapshot): JSX.Element => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={cls(
                      snapshot.isDragging &&
                        "adm-item-custom-design bg-drag-drop blur-none"
                    )}
                    style={getItemStyle(
                      snapshot.isDragging || addNewBlockButtonDragBeforeCapture,
                      provided.draggableProps.style
                    )}
                  >
                    <div>
                      {/* <Portal elementId="newBlockPortal"> */}
                      {guideState === FirstGuideStateEnum.ADD_BLOCK ? (
                        renderAddBlockAnimation()
                      ) : (
                        <div
                          className={cls(
                            `flex items-center justify-center w-[48px] h-[48px] rounded-full bg-[#EE3244] cursor-pointer `,
                            PRODUCT_TOUR_CLASS_NAME.STEP_2
                          )}
                          style={{
                            boxShadow: "0px 0px 30px rgba(51, 51, 51, 0.4)",
                            margin: "auto",
                          }}
                          onClick={handleOpenPositionSelectedList}
                        >
                          <AddIcon className="" />
                        </div>
                      )}
                      {/* </Portal> */}
                    </div>
                  </div>
                )}
              </Draggable>
              {provided.placeholder}
            </section>
          ) : (
            <></>
          )}
        </div>
      )}
    </Droppable>
  );
};

export default AddNewButtonDragging;
