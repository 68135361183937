import { RootState } from "@app/store";
import {
  FirstGuideStateEnum,
  getExternalState,
  setFirstGuideState,
} from "@features/design/store/externalStateSlice";
import ModalInfo, { ShowedContent } from "@share/components/ModalInfo";
import { usePopup } from "@share/hooks";
import { AddIcon, PreviewThemeIcon, PubLishIcon } from "@share/icons";
import React, { useEffect, useImperativeHandle, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

interface ModalGuideProps {
  onOpenPositionSelection: () => void;
  onOpenPreview: () => void;
  onPublish: () => void;
}

export interface ModalGuideRef {
  openModal: () => void;
  closeModal: () => void;
  changeFirstGuide: () => void;
}

const FormData: React.ForwardRefRenderFunction<
  ModalGuideRef,
  ModalGuideProps
> = (props, ref) => {
  const { visible, openPopup, closePopup } = usePopup();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userId = useSelector<RootState, any>(
    (state) => state.siteSettings.user_id
  );
  const guideState = useSelector<RootState, any>(
    (state) => state.externalState.guide_state
  );

  const nextFirstGuide = {
    [FirstGuideStateEnum.ADD_BLOCK]: FirstGuideStateEnum.PREVIEW,
    [FirstGuideStateEnum.PREVIEW]: FirstGuideStateEnum.PUBLISH,
    [FirstGuideStateEnum.PUBLISH]: FirstGuideStateEnum.DONE,
  };

  const changeFirstGuide = () => {
    if (guideState !== FirstGuideStateEnum.DONE) {
      dispatch(setFirstGuideState(nextFirstGuide[guideState]));
    }
  };

  const addBlockContent: ShowedContent = {
    image:
      "https://image.passio.eco/page-builder/page-builder/position-modal/add_block.gif",
    title: t("ss_builder_first_guide_add_block_title"),
    desctiption: t("ss_builder_first_guide_add_block_description"),
    button: {
      icon: <AddIcon className="" />,
      title: t("ss_builder_first_guide_add_block_btn_title"),
      onClick: () => {
        closePopup();
        props.onOpenPositionSelection();
      },
    },
  };

  const previewContent: ShowedContent = {
    image:
      "https://image.passio.eco/page-builder/page-builder/position-modal/preview.gif",
    title: t("ss_builder_first_guide_preview_title"),
    desctiption: t("ss_builder_first_guide_preview_description"),
    button: {
      icon: (
        <PreviewThemeIcon
          className=""
          fillColor="#fff"
        />
      ),
      title: t("ss_builder_first_guide_preview_btn_title"),
      onClick: () => {
        closePopup();
        props.onOpenPreview();
      },
    },
  };

  const publishContent: ShowedContent = {
    image:
      "https://image.passio.eco/page-builder/page-builder/position-modal/publish.gif",
    title: t("ss_builder_first_guide_publish_title"),
    desctiption: t("ss_builder_first_guide_publish_description"),
    button: {
      icon: (
        <PubLishIcon
          width={15}
          height={15}
          className=""
        />
      ),
      title: t("ss_builder_first_guide_publish_btn_title"),
      onClick: () => {
        closePopup();
        props.onPublish();
      },
    },
  };

  const guideSteps: { [key: string]: ShowedContent } = {
    [FirstGuideStateEnum.ADD_BLOCK]: addBlockContent,
    [FirstGuideStateEnum.PREVIEW]: previewContent,
    [FirstGuideStateEnum.PUBLISH]: publishContent,
    [FirstGuideStateEnum.DONE]: null,
  };

  const guide = useMemo(() => {
    return guideSteps[guideState];
  }, [guideState]);

  useImperativeHandle(ref, () => ({
    closeModal: closePopup,
    openModal: openPopup,
    changeFirstGuide,
  }));

  useEffect(() => {
    if (userId) dispatch(getExternalState());
  }, [userId]);

  return guide ? (
    <ModalInfo
      info={guide}
      visible={visible}
      onClose={closePopup}
    />
  ) : (
    <></>
  );
};

export default React.forwardRef(FormData);
