import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import SettingHeader from "../SettingHeader";
import { BackgroundIcon, StyleIcon } from "../../Icons";
import { Collapse, Divider, Selector, Slider, Switch } from "antd-mobile";
import { ColorPickerV2 } from "../ColorPicker";
import { ColorIcon, ImageIcon } from "@share/icons";
import ImageUploader from "./components/ImageUploader";
import GradientColor from "../GradientColor";
import { useAppSelector } from "@app/hooks";
import { useDispatch } from "react-redux";
import { saveBackgroundSetting } from "@share/thunk/setting";
import { useHasChange } from "@features/setting/hooks";
import { BACKGROUND_SETTING_DEFAULT_VALUE } from "@share/configs/const";
import { blurMarksBackgroundImage } from "@features/setting/helper";
import Preview from "./components/Preview";
import { BLOCK_SETTING_DEFAULT_VALUE } from "@share/configs/const";
import DisplayColor from "@share/components/DisplayColor";
import { useDebounce } from "usehooks-ts";
import PopupComponent from "@share/components/PopupComponent";
import BackgroundTourOpening from "@features/setting/components/ThemeSetting/BackgroundSetting/components/BackgroundTourOpening";
import styleModule from "./style.module.scss";

const BACKGROUND_TYPE_IMAGE = "image";
const BACKGROUND_TYPE_SOLID = "solid";
const BACKGROUND_TYPE_GRADENT = "gradient";

const BACKGROUND_OPTION_COLOR = "color";
const BACKGROUND_OPTION_IMAGE = "image";

const COLOR_TYPE_SOLID = "solid";
const COLOR_TYPE_GRADIENT = "gradient";
const getBackgroundSettingOptions = (t) => [
  {
    label: (
      <ImageIcon
        width="27"
        height="27"
        className="align-middle inline-block mb-2"
      />
    ),
    value: BACKGROUND_OPTION_IMAGE,
    description: t("ss_builder_theme_setting_background_image_selector_label"),
  },
  {
    label: (
      <ColorIcon
        width="27"
        height="27"
        className="align-middle inline-block mb-2"
      />
    ),
    value: BACKGROUND_OPTION_COLOR,
    description: t("ss_builder_theme_setting_background_color_selector_label"),
  },
];

const defaultBackgroundSetting = {
  type: "solid",
  color: "#FFFFFF",
  gradient: null,
  image: null,
};

interface BackgroundSettingInterface {
  type: string;
  color?: string | null;
  gradient?: string | null;
  image?: string | null;
  base64_image?: string;
  image_name?: string;
  blur?: number;
  enable_overlay?: boolean;
  overlay_color?: string;
  meta?: any;
  color_type?: string;
}

interface BlockSettingDataInterface {
  block_style: {
    radius_type: string;
    radius_value: number | undefined;
    inner_space: boolean;
  };
  block_background: {
    color: string;
    blur: number;
    meta: any;
  };
  block_border: {
    enable: boolean;
    color: string;
    color_meta: any;
    type: string;
    border_width: number | undefined;
  };
  block_shadow: {
    enable: boolean;
    color: string;
    color_meta: any;
  };
}

const defaultSettingState: BlockSettingDataInterface = {
  block_style: {
    radius_type: BLOCK_SETTING_DEFAULT_VALUE.BLOCK_STYLE,
    radius_value: BLOCK_SETTING_DEFAULT_VALUE.BORDER_RADIUS,
    inner_space: BLOCK_SETTING_DEFAULT_VALUE.INNER_SPACE,
  },
  block_background: {
    color: BLOCK_SETTING_DEFAULT_VALUE.BACKGROUND_COLOR,
    blur: BLOCK_SETTING_DEFAULT_VALUE.BACKGROUND_BLUR,
    meta: {},
  },
  block_border: {
    enable: BLOCK_SETTING_DEFAULT_VALUE.BORDER_ENABLE,
    color: BLOCK_SETTING_DEFAULT_VALUE.BORDER_COLOR,
    color_meta: {},
    type: BLOCK_SETTING_DEFAULT_VALUE.BORDER_STYLE,
    border_width: BLOCK_SETTING_DEFAULT_VALUE.BORDER_WIDTH,
  },
  block_shadow: {
    enable: BLOCK_SETTING_DEFAULT_VALUE.SHADOW_ENABLE,
    color: BLOCK_SETTING_DEFAULT_VALUE.SHADOW_COLOR,
    color_meta: {},
  },
};

/** @todo Optimize việc gradient picker bị dính vào redux */
export default function BackgroundSetting(props) {
  const { backToAllSetting, activeIndex } = props;
  const { t } = useTranslation();
  const [isOpenColorPicker, setIsOpenColorPicker] = useState(false);
  const [isOpenHoverColorPicker, setIsOpenHoverColorPicker] = useState(false);

  const defaultBackgroundSetting = useAppSelector(
    (state) => state.siteSettings.display_setting.background
  );
  const backgroundMeta = useAppSelector(
    (state) => state.siteSettings.display_setting.meta_data
  );
  const blockSetting = useAppSelector(
    (state) => state?.siteSettings?.display_setting?.block_setting
  );
  const dynamicConfig = useAppSelector((state) => state.dynamicConfig);
  const colorSettingOptions = [
    {
      label: t("ss_builder_solid"),
      value: COLOR_TYPE_SOLID,
    },
    {
      label: t("ss_builder_gradient"),
      value: COLOR_TYPE_GRADIENT,
    },
  ];

  const [backgroundSettingData, setBackgroundSettingData] =
    useState<BackgroundSettingInterface>({
      ...defaultBackgroundSetting,
      meta: backgroundMeta,
    });

  const {
    type = BACKGROUND_SETTING_DEFAULT_VALUE.BACKGROUND_TYPE,
    image = BACKGROUND_SETTING_DEFAULT_VALUE.IMAGE,
    color = BACKGROUND_SETTING_DEFAULT_VALUE.COLOR_SOLID,
    overlay_color = BACKGROUND_SETTING_DEFAULT_VALUE.OVERLAY_COLOR,
    enable_overlay = BACKGROUND_SETTING_DEFAULT_VALUE.OVERLAY_ENABLE,
    blur = BACKGROUND_SETTING_DEFAULT_VALUE.BLUR,
    color_type = BACKGROUND_SETTING_DEFAULT_VALUE.BACKGROUND_TYPE,
  } = backgroundSettingData ?? {};

  const [backgroundOption, setBackgroundOption] = useState(
    type === BACKGROUND_TYPE_IMAGE
      ? BACKGROUND_OPTION_IMAGE
      : BACKGROUND_OPTION_COLOR
  );

  const [colorType, setColorType] = useState(color_type);
  const isMarginBackground = dynamicConfig?.isMarginBackground || false;

  useEffect(() => {
    setBackgroundOption(
      type === BACKGROUND_TYPE_IMAGE
        ? BACKGROUND_OPTION_IMAGE
        : BACKGROUND_OPTION_COLOR
    );
    // setColorType(
    //   type == COLOR_TYPE_GRADIENT ? COLOR_TYPE_GRADIENT : COLOR_TYPE_SOLID
    // );
  }, [type]);
  const backgroundSettingOptions = getBackgroundSettingOptions(t);
  const dispatch = useDispatch();

  const onChangeBackgroundType = (val: string[]) => {
    if (val.length > 0) {
      setBackgroundOption(val[0]);
      const colorTypeSetting =
        val[0] === BACKGROUND_OPTION_COLOR ? colorType : val[0];
      setBackgroundSettingData((state) => ({
        ...state,
        type: colorTypeSetting,
      }));
    }
  };

  const onChangeColorType = (val: string[]) => {
    if (val.length > 0) {
      setColorType(val[0]);
      setBackgroundSettingData((state) => ({ ...state, type: val[0] }));
    } else {
      setColorType(COLOR_TYPE_SOLID);
      setBackgroundSettingData((state) => ({
        ...state,
        type: val[COLOR_TYPE_SOLID],
      }));
    }
  };

  const onChangeColor = (data) => {
    const newColorState = {
      color: data,
    };
    setBackgroundSettingData((state) => ({ ...state, ...newColorState }));
  };

  const onUploadImage = (image) => {
    setBackgroundSettingData((state) => ({
      ...state,
      base64_image: image?.dataBase64 ?? null,
      image: image?.url,
      image_name: image.fileName,
    }));
  };

  const onChangeBackgroundSettingState = (key, value) => {
    setBackgroundSettingData((state) => ({
      ...state,
      [key]: value,
    }));
  };
  const lastestData = useDebounce(backgroundSettingData, 100);

  const onSave = useCallback(() => {
    const savedData = {
      type:
        backgroundOption == BACKGROUND_OPTION_IMAGE
          ? BACKGROUND_TYPE_IMAGE
          : colorType,
      color: color,
      gradient: dynamicConfig.themeSettings.gradientColor,
      image: lastestData.image,
      base64_image: lastestData.base64_image,
      image_name: lastestData.image_name,
      blur: lastestData.blur,
      enable_overlay: lastestData.enable_overlay,
      overlay_color: lastestData.overlay_color,
      color_type: colorType,
    };
    dispatch(saveBackgroundSetting(savedData, backgroundMeta));
    backToAllSetting();
  }, [backgroundOption, colorType, color, dynamicConfig, lastestData]);
  const [blockSettingData, setBlockSettingData] =
    useState<BlockSettingDataInterface>(blockSetting ?? defaultSettingState);

  useEffect(() => {
    if (
      JSON.stringify(backgroundSettingData) !==
        JSON.stringify({
          ...defaultBackgroundSetting,
          meta: backgroundMeta,
        }) &&
      activeIndex === 1
    ) {
      onSave();
    }
  }, [
    backgroundOption,
    dynamicConfig.themeSettings.gradientColor,
    color,
    colorType,
    lastestData.image,
    lastestData.image_name,
    lastestData.enable_overlay,
    lastestData.overlay_color,
    lastestData.blur,
    activeIndex,
  ]);

  return (
    <div
      className="max-width-content-class m-auto h-full relative"
      style={{
        marginBottom: isMarginBackground ? "300px" : "",
      }}
    >
      <div className="w-full pb-10 h-full">
        <div className="max-width-content-class sticky top-0 z-[999] w-full">
          <SettingHeader
            title={t("ss_builder_background_setting_title")}
            Icon={BackgroundIcon}
            children={<BackgroundTourOpening />}
          />
          <Preview
            defaultValue={blockSettingData ?? {}}
            backgroundSettingData={backgroundSettingData ?? {}}
            dynamicConfig={dynamicConfig ?? {}}
          />
        </div>
        <Collapse
          defaultActiveKey="style"
          accordion
          className={styleModule.backgroundStyleCollapse}
          arrow={null}
        >
          <Collapse.Panel
            key="style"
            title={
              <>
                <div className="flex">
                  <span className="mr-1">
                    <StyleIcon />
                  </span>
                  <span>{t("ss_builder_theme_setting_block_style")}</span>
                </div>
              </>
            }
          >
            <div className="background-tour-step-2">
              <Selector
                columns={2}
                options={backgroundSettingOptions}
                onChange={onChangeBackgroundType}
                value={[backgroundOption]}
                style={{
                  "--border": "solid transparent 1px",
                  "--checked-border": "solid var(--adm-color-primary) 1px",
                }}
              />
            </div>
            <Divider />
            {backgroundOption == BACKGROUND_OPTION_IMAGE && (
              <>
                <ImageUploader
                  defaultImage={image}
                  onChangeImage={onUploadImage}
                />
                <Divider />
                <div>
                  <div className="text-base mb-4 flex justify-between">
                    <span>{t("ss_builder_background_opacity")}</span>
                    <span className="primary-color text-sm">
                      {blur ?? 0} px
                    </span>
                  </div>
                  <Slider
                    onChange={(val) => {
                      onChangeBackgroundSettingState(
                        "blur",
                        parseInt(val.toString())
                      );
                      // _.debounce(onChangeBackgroundSettingState("blur",parseInt(val.toString())),5000)
                    }}
                    // ticks={true}
                    marks={blurMarksBackgroundImage}
                    min={0}
                    step={1}
                    max={30}
                    defaultValue={blur}
                    popover={true}
                  />
                </div>
                <Divider />
                <div>
                  <div className="text-base mb-4">
                    {t("ss_builder_background_enable_overlay")}
                  </div>
                  <Switch
                    defaultChecked={enable_overlay}
                    uncheckedText={t("ss_builder_off")}
                    checkedText={t("ss_builder_on")}
                    onChange={(val) =>
                      onChangeBackgroundSettingState("enable_overlay", val)
                    }
                  />
                </div>
                {enable_overlay && (
                  <>
                    <Divider />
                    <div>
                      <div className="text-base mb-4">
                        {t("ss_builder_background_overlay_color")}
                      </div>
                      <DisplayColor
                        backgroundColor={overlay_color || "black"}
                        onClickOpenColorPicker={setIsOpenHoverColorPicker}
                      />
                    </div>
                  </>
                )}
              </>
            )}
            {backgroundOption == BACKGROUND_OPTION_COLOR && (
              <div>
                <div className="text-base mb-1 mb-[10px]">
                  {t("ss_builder_theme_setting_select_background_color_label")}
                </div>
                <div>
                  <Selector
                    style={{
                      // "--border-radius": "100px",
                      "--border": "solid transparent 1px",
                      "--checked-border": "solid var(--adm-color-primary) 1px",
                      "--padding": "8px 24px",
                      // "--color": "#000",
                      "--text-color": "#333",
                    }}
                    options={colorSettingOptions}
                    value={[colorType]}
                    columns={2}
                    onChange={onChangeColorType}
                  />
                </div>
                {colorType === "solid" && (
                  <div>
                    <div className="text-base mb-1 mt-7 mb-3">
                      {t("ss_builder_theme_setting_select_solid_color_label")}
                    </div>
                    <DisplayColor
                      backgroundColor={color || "#f5f5f5"}
                      onClickOpenColorPicker={setIsOpenColorPicker}
                    />
                  </div>
                )}
                {colorType === "gradient" && <GradientColor />}
              </div>
            )}
          </Collapse.Panel>
        </Collapse>
      </div>

      <PopupComponent
        visible={isOpenColorPicker}
        onMaskClick={() => setIsOpenColorPicker(false)}
        title={t("ss_builder_color_picker_panel_title")}
        onClose={() => setIsOpenColorPicker(false)}
        classNameBody="h-[60vh] popup-hard"
      >
        <ColorPickerV2
          defaultColor={color}
          onColorSelected={onChangeColor}
          onCancel={() => setIsOpenColorPicker(false)}
        />
      </PopupComponent>
      <PopupComponent
        visible={isOpenHoverColorPicker}
        onMaskClick={() => setIsOpenHoverColorPicker(false)}
        title={t("ss_builder_color_picker_panel_title")}
        onClose={() => setIsOpenHoverColorPicker(false)}
        classNameBody="h-[60vh] popup-hard"
      >
        <ColorPickerV2
          defaultColor={overlay_color}
          onColorSelected={(color) =>
            onChangeBackgroundSettingState("overlay_color", color)
          }
          onCancel={() => setIsOpenHoverColorPicker(false)}
        />
      </PopupComponent>
    </div>
  );
}
