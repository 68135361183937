import React, { useEffect, useRef, useState } from "react";
import {
  colorNameToHex,
  getHexColor,
  getRandomColor,
} from "../../../../share/lib";
import {
  ArrowDown,
  ArrowDownLeft,
  ArrowDownRight,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowUpLeft,
  ArrowUpRight,
  RandomizeIcon,
} from "@share/icons";
import { Button, Selector, Slider } from "antd-mobile";
import { dataService } from "@share/configs/rxjs";
import {
  setParamsThemeSetting,
} from "@features/main/store/dynamicSlice";
import { setDisplaySetting } from "@features/main/store/siteSettingSlice";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { useTranslation } from "react-i18next";
import ColorPicker from "../ColorPicker";
import { BACKGROUND_SETTING_DEFAULT_VALUE } from "@share/configs/const";
import { getOpacityFromHex, getGlobalColor } from "@share/lib";
import PopupComponent from "@share/components/PopupComponent";

interface data {
  selectedBackgroundGradientColor: string;
  indexColor: number;
}

const getTwoDifferentColors = () => {
  const firstColor = getRandomColor();
  const secondColor = getRandomColor(firstColor);
  return [firstColor, secondColor];
};

const GradientColor = () => {
  const directionButtonOptions = [
    {
      label: <ArrowUpLeft className="align-middle inline-block" />,
      value: "to top left",
    },
    {
      label: <ArrowUp className="align-middle inline-block" />,
      value: "to top",
    },
    {
      label: <ArrowUpRight className="align-middle inline-block" />,
      value: "to top right",
    },
    {
      label: <ArrowLeft className="align-middle inline-block" />,
      value: "to left",
    },
    {
      label: <div className="" />,
      value: "circle",
      disabled: true,
    },
    {
      label: <ArrowRight className="align-middle inline-block" />,
      value: "to right",
    },
    {
      label: <ArrowDownLeft className="align-middle inline-block" />,
      value: "to bottom left",
    },
    {
      label: <ArrowDown className="align-middle inline-block" />,
      value: "to bottom",
    },
    {
      label: <ArrowDownRight className="align-middle inline-block" />,
      value: "to bottom right",
    },
  ];

  const { t } = useTranslation();
  // const regex = new RegExp(/#[a-zA-Z0-9\#!]+/g);
  const siteSettings = useAppSelector<any>((state) => state.siteSettings);
  const displaySettings =
    siteSettings &&
    Object.values(siteSettings).length > 0 &&
    siteSettings.display_setting;
  const { meta_data } = displaySettings;
  const background = displaySettings?.background ?? {};
  const gradient = background?.gradient;

  const arrColorDefault =
    meta_data && meta_data.colors && meta_data.colors.length > 0
      ? meta_data.colors
      : [
          BACKGROUND_SETTING_DEFAULT_VALUE.COLOR_GRADIENT_GENERATOR_FIRST_COLOR,
          BACKGROUND_SETTING_DEFAULT_VALUE.COLOR_GRADIENT_GENERATOR_SECOND_COLOR,
        ];
  const intensityDefault =
    (meta_data && meta_data.intensity) ||
    BACKGROUND_SETTING_DEFAULT_VALUE.COLOR_GRADIENT_INTENSITY;
  const directionDefault =
    (meta_data && meta_data.direction) ||
    BACKGROUND_SETTING_DEFAULT_VALUE.COLOR_GRADIENT_DIRECTION;

  const [colors, setColors] = useState(
    arrColorDefault || getTwoDifferentColors
  );
  const [intensity, setIntensity] = useState(intensityDefault);
  const [direction, setDirection] = useState(directionDefault);
  const [isShowIntensityChange, setIsShowIntensityChange] = useState(false);
  const [actionKey, setActionKey] = useState(null);
  const [indexColor, setIndexColor] = useState(null);
  const [isOpenFirstColorPicker, setIsOpenFirstColorPicker] = useState(false);
  const [isOpenSecondColorPicker, setIsOpenSecondColorPicker] = useState(false);
  const [gradientBackground, setGradientBackGround] = useState(
    gradient || "white"
  );

  const firstColorRandom = useRef(colors[0]);
  const secondColorRandom = useRef(colors[1]);

  const dispatch = useAppDispatch();

  const handleRandomise = () => {
    const [firstColor, secondColor] = getTwoDifferentColors();
    firstColorRandom.current = firstColor;
    secondColorRandom.current = secondColor;
    setColors([
      getHexColor(firstColor, intensity),
      getHexColor(secondColor, intensity),
    ]);
    setIsShowIntensityChange(true);
  };

  const handleIntensityChange = (value: number) => {
    setColors([
      getHexColor(firstColorRandom.current, value),
      getHexColor(secondColorRandom.current, value),
    ]);
    setIntensity(value);
  };

  useEffect(() => {
    // const [firstColor, secondColor] = colors;
    let linearBackground = `linear-gradient(${
      direction ?? "circle"
    }, ${getHexColor(firstColorRandom.current, intensity)}, ${getHexColor(
      secondColorRandom.current,
      intensity
    )})`;
    if (direction == undefined) {
      linearBackground = `radial-gradient(${getHexColor(
        firstColorRandom.current,
        intensity
      )}, ${getHexColor(secondColorRandom.current, intensity)})`;
    }
    setGradientBackGround(linearBackground);
  }, [
    firstColorRandom.current,
    secondColorRandom.current,
    intensity,
    direction,
  ]);

  useEffect(() => {
    dispatch(
      setDisplaySetting({
        data: {
          meta_data: {
            colors,
            direction,
            intensity,
          },
        },
      })
    );
  }, [colors, direction, intensity]);

  useEffect(() => {
    const subscription = dataService.getObservable().subscribe((data: data) => {
      if (data) {
        const selectedBackgroundGradientColor =
          data.selectedBackgroundGradientColor;
        const indexColor = data.indexColor;
        if (selectedBackgroundGradientColor && indexColor !== undefined) {
          setIsShowIntensityChange(false);
          setColors((colors) =>
            colors.map((item, index) => {
              if (index === indexColor) {
                return selectedBackgroundGradientColor;
              }
              return item;
            })
          );
          if (indexColor === 0) {
            firstColorRandom.current = selectedBackgroundGradientColor;
          }
          if (indexColor === 1) {
            secondColorRandom.current = selectedBackgroundGradientColor;
          }
        }
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    dispatch(
      setParamsThemeSetting({
        data: {
          gradientColor: gradientBackground,
        },
      })
    );
  }, [gradientBackground]);

  const handleOpenColorPicker = (data) => {
    const { actionKey, indexColor } = data;
    setIndexColor(indexColor);
    setActionKey(actionKey);
    if (indexColor === 0) {
      setIsOpenFirstColorPicker(true);
    }
    if (indexColor === 1) {
      setIsOpenSecondColorPicker(true);
    }
  };

  const handleSetDirectionKey = (value) => {
    if (value.length > 0) {
      setDirection(value[0]);
    }
  };

  const handleCloseFirstColorPicker = () => {
    setIsOpenFirstColorPicker(false);
  };

  const handleCloseSecondColorPicker = () => {
    setIsOpenSecondColorPicker(false);
  };

  return (
    <>
      <div
        className="flex flex-col mx-auto w-full mt-4"
        style={{ maxWidth: "80ch" }}
      >
        <div className="">
          <div className="">
            <Button
              className="py-2 px-6 mb-4 d-flex gap-2 items-center w-full justify-center"
              onClick={handleRandomise}
              color="primary"
              fill="solid"
              size="middle"
            >
              <RandomizeIcon className="h-4 inline-block ml-2" />{" "}
              {t("ss_builder_randomize")}
            </Button>
            <div>
              <div className="text-base mb-1 mb-[10px] mt-5">
                {t("ss_builder_theme_setting_select_gradient_color_label")}
              </div>
              <div className="flex flex-row gap-5">
                {colors &&
                  colors.map((ele, index) => (
                    <div
                      className="flex flex-col text-center items-center"
                      key={index}
                    >
                      <div
                        className="w-12 h-12 rounded-[50%]  cursor-pointer relative overflow-hidden"
                        style={{
                          backgroundColor: getGlobalColor(
                            ele.includes("#") ? ele : colorNameToHex(ele)
                          ),
                        }}
                        onClick={() =>
                          handleOpenColorPicker({
                            actionKey: "SELECT_BACKGROUND_GRADIENT_COLOR",
                            indexColor: index,
                          })
                        }
                      >
                        <>
                          {getOpacityFromHex(
                            ele.includes("#") ? ele : colorNameToHex(ele)
                          ) !== 1 && (
                            <div
                              className="w-[50%] h-[100%] absolute right-0 top-0"
                              style={{
                                background:
                                  "repeat #fff url(https://image.passio.eco/page-builder/page-builder/background-default/checkerboard-v2.png) ",
                              }}
                            ></div>
                          )}
                          <div
                            className="w-[50%] h-[100%] absolute right-0 top-0"
                            style={{
                              backgroundColor: ele.includes("#")
                                ? ele
                                : colorNameToHex(ele),
                            }}
                          ></div>
                          <div
                            className="w-[100%] h-[100%] absolute left-0 top-0 rounded-[50%]"
                            style={{
                              outline: "rgba(0, 0, 0, 0.2) solid 1px",
                              outlineOffset: "-1px",
                            }}
                          ></div>
                        </>
                      </div>
                      <div className="text-center">
                        {t("ss_builder_theme_setting_gadient_color")}{" "}
                        {`${index + 1}`}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            {isShowIntensityChange && (
              <div>
                <div className="text-base mb-1 mt-5 mb-5">
                  {t("ss_builder_theme_setting_intensity_color_label")}
                </div>
                <Slider
                  step={100}
                  min={100}
                  max={900}
                  ticks={true}
                  onChange={handleIntensityChange}
                  value={intensity}
                />
              </div>
            )}
            <div className="text-base mb-1 mt-5 mb-4">
              {t("ss_builder_theme_setting_gradient_color_direction_label")}
            </div>
            <div>
              <Selector
                columns={3}
                options={directionButtonOptions}
                onChange={handleSetDirectionKey}
                value={[direction]}
                style={{
                  "--border": "solid transparent 1px",
                  "--checked-border": "solid var(--adm-color-primary) 1px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <PopupComponent
        visible={isOpenFirstColorPicker}
        onMaskClick={() => handleCloseFirstColorPicker()}
        title={t("ss_builder_color_picker_panel_title")}
        onClose={() => handleCloseFirstColorPicker()}
        classNameBody="h-[60vh] popup-hard"
      >
        <ColorPicker
          callbackCancelColorPicker={handleCloseFirstColorPicker}
          indexColor={indexColor}
          actionKey={actionKey}
          defaultColor={colors[0]}
        />
      </PopupComponent>
      <PopupComponent
        visible={isOpenSecondColorPicker}
        onMaskClick={() => handleCloseSecondColorPicker()}
        title={t("ss_builder_color_picker_panel_title")}
        onClose={() => handleCloseSecondColorPicker()}
        classNameBody="h-[60vh] popup-hard"
      >
        <ColorPicker
          callbackCancelColorPicker={handleCloseSecondColorPicker}
          indexColor={indexColor}
          actionKey={actionKey}
          defaultColor={colors[1]}
        />
      </PopupComponent>
    </>
  );
};

export default GradientColor;
