import { RootState } from "@app/store";
import { BLOCK_TYPE } from "@features/block/block.conf";
import { runMiddleware } from "@features/block/components/BlockEditV2";
import { POSITION_TYPE } from "@features/block/config/interface";
import {
  BLOCK_TYPE_BY_GROUP,
  COUNT_OF_FLOATING_BLOCK_MAXIMUM,
} from "@features/design/design.config";
import {
  FloatingIntroStatus,
  setFloatingIntroductionState,
} from "@features/design/store/externalStateSlice";
import { FLOATING } from "@features/design/store/externalStateSlice";
import { addBlock, getBlocksBySlug } from "@features/main/store/positionsSlice";
import { COUNTRY_VN } from "@features/mini-store/block/TruthOrDare/sideFnc";
import { usePopup } from "@share/hooks";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import ModalBrandGiftGolive from "./ModalBrandGiftGolive";
import ModalWaringFloatingFull from "./ModalWaringFloatingFull";
import PopupIntroductionBrandGift from "./PopupIntroductionBrandGift";
import { FINISH_PRODUCT_TOUR_ENUM } from "@features/main/store/interface";

enum BRAND_GIFT_FOOTER {
  NOT_SHOW = "NOT_SHOW",
  SHOW_INTRO = "SHOW_INTRO",
  SHOW_WARNING = "SHOW_WARNING",
}

const BrandGiftIntroduction: React.FunctionComponent = (props) => {
  const [state, setState] = useState<BRAND_GIFT_FOOTER>(
    BRAND_GIFT_FOOTER.NOT_SHOW
  );
  const { visible, openPopup, closePopup } = usePopup();
  const dispatch = useDispatch();

  const allPositions = useSelector<RootState, any>((state) => state.positions);
  const productTourState = useSelector<RootState, any>(
    (state) => state.creatorProfile.finish_product_tour
  );
  const modalIntroBrandGiftState = useSelector<RootState, any>(
    (state) =>
      state.externalState?.floating_icon &&
      state.externalState?.floating_icon[FLOATING.BRAND_GIFT_FOOTER]
  );

  const userId = useSelector<RootState, any>(
    (state) => state.siteSettings.user_id
  );

  const country = useSelector<RootState, any>(
    (state) => state?.user?.kol?.country
  );

  const floatingBlocks = useSelector(
    getBlocksBySlug(POSITION_TYPE.EXPAND, BLOCK_TYPE_BY_GROUP.FLOATING)
  );

  const hideAllModal = () => setState(BRAND_GIFT_FOOTER.NOT_SHOW);

  const addBrandGiftFooterBlock = async () => {
    const isBrandGiftFooterExist = floatingBlocks.find(
      (block) => block.block_type === BLOCK_TYPE.BRAND_GIFT_FOOTER
    );
    if (!isBrandGiftFooterExist) {
      const UID = `brand-gift-footer-${uuidv4()}`;
      const expand = allPositions.find(
        (position) => position.slug === POSITION_TYPE.EXPAND
      );
      const defaultBrandGift = {
        uid: UID,
        sort_order: 1,
        enable: 1,
        block_type: BLOCK_TYPE.BRAND_GIFT_FOOTER,
        title: "Brand Gift Footer",
        content_attributes: {},
        style_attributes: {},
      };

      try {
        var data = await runMiddleware(
          BLOCK_TYPE.BRAND_GIFT_FOOTER,
          defaultBrandGift,
          {
            user_id: userId,
          },
          () => {}
        );
      } catch (e) {
        console.log("e", e);
      }

      const blockData = {
        ...data,
        tab_uid: "0",
        position_id: expand?.id,
        parent_id: "0",
        sort_order: floatingBlocks.length > 0 ? floatingBlocks.length + 1 : 1,
      };

      dispatch(
        addBlock({
          tabUid: blockData.tab,
          positionSlug: POSITION_TYPE.EXPAND,
          blockData: blockData,
          positionId: expand?.id,
        })
      );
    }
    hideAllModalAndScrollToFloating();
  };

  const checkConditionToAddBrandGift = () => {
    const isFloatingNotFull =
      floatingBlocks.length < COUNT_OF_FLOATING_BLOCK_MAXIMUM;

    if (isFloatingNotFull) {
      addBrandGiftFooterBlock();
      hideAllModal();
    } else {
      setState(BRAND_GIFT_FOOTER.SHOW_WARNING);
    }
  };

  const hideAllModalAndScrollToFloating = () => {
    hideAllModal();
    closePopup();
    const floatingAreaElement = document.getElementById(
      "floating-icons-footer"
    );
    setTimeout(() => {
      floatingAreaElement.scrollIntoView({
        behavior: "smooth",
      });
    }, 300);
  };

  useEffect(() => {
    if (country !== COUNTRY_VN) {
      return;
    }

    const isProductTourFinished =
      productTourState === FINISH_PRODUCT_TOUR_ENUM.TRUE;
    const isBrandGiftFooterExist = floatingBlocks.find(
      (block) => block.block_type === BLOCK_TYPE.BRAND_GIFT_FOOTER
    );

    if (
      modalIntroBrandGiftState !== FloatingIntroStatus.DISABLE &&
      isBrandGiftFooterExist
    ) {
      dispatch(
        setFloatingIntroductionState({
          [FLOATING.BRAND_GIFT_FOOTER]: FloatingIntroStatus.DISABLE,
        })
      );
      return;
    }
    if (
      modalIntroBrandGiftState !== FloatingIntroStatus.DISABLE &&
      isProductTourFinished &&
      userId
    ) {
      setState(BRAND_GIFT_FOOTER.SHOW_INTRO);
      dispatch(
        setFloatingIntroductionState({
          [FLOATING.BRAND_GIFT_FOOTER]: FloatingIntroStatus.DISABLE,
        })
      );
    }
  }, [modalIntroBrandGiftState, userId]);

  return (
    <>
      {/* <ModalBrandGiftGolive
        visible={state === BRAND_GIFT_FOOTER.SHOW_INTRO}
        onAddBlock={checkConditionToAddBrandGift}
        onReject={hideAllModal}
      /> */}
      <ModalWaringFloatingFull
        visible={state === BRAND_GIFT_FOOTER.SHOW_WARNING}
        onContinue={hideAllModalAndScrollToFloating}
        onReject={hideAllModal}
        onOpenIntroductionBrandGift={openPopup}
      />
      <PopupIntroductionBrandGift
        visible={visible}
        onBack={closePopup}
        onContinue={hideAllModalAndScrollToFloating}
      />
    </>
  );
};

export default BrandGiftIntroduction;
