import Portal from "@share/HOC/portal";
import { Modal, Button } from "antd-mobile";
import React from "react";
import { ClosePositionInfoIcon } from "@share/icons";

export interface ShowedContent {
  image: string;
  title: string;
  desctiption: string;
  button?: {
    icon?: React.ReactNode;
    title: string;
    onClick: () => void;
  };
}

interface ModalInfoProp {
  info: ShowedContent;
  visible: boolean;
  onClose: () => void;
}

const ModalInfo: React.FunctionComponent<ModalInfoProp> = (props) => {
  const { visible, info, onClose } = props;

  const ModalContent = () => (
    <div className="position-info-content">
      <span
        className="cursor-pointer p-1 position-info-icon"
        onClick={onClose}
      >
        <ClosePositionInfoIcon
          width={24}
          height={24}
          className=""
          onClick={onClose}
        />
      </span>
      <div className="mb-2 text-[18px] leading-[25px] font-bold text-[#333333]">
        {info?.title}
      </div>
      <p className="text-[15px] font-normal leading-[140%] text-textColorDefault text-[#333333]">
        {info?.desctiption}
      </p>
      {info?.button && (
        <div className="mt-3">
          <Button
            color="primary"
            size="middle"
            fill="solid"
            block
            onClick={info?.button?.onClick}
          >
            <div className="flex flex-row justify-center items-center">
              <span className="mr-1">{info?.button?.icon}</span>
              <span>{info?.button?.title}</span>
            </div>
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <Portal elementId="modalPortal">
      <Modal
        // header={
        //   <CloseCircleIconV2 width={48} height={48} fillColor="#FF3141" />
        // }
        bodyClassName="modal-position-info"
        forceRender={true}
        onClose={onClose}
        visible={visible}
        content={<ModalContent />}
        closeOnMaskClick={true}
        showCloseButton={false}
        image={info?.image}
      />
    </Portal>
  );
};

export default ModalInfo;
