import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GeneralIcon,
  BlockIcon,
  HeaderIconV2,
  TextIcon,
  ButtonIconV2,
} from "@features/setting/components/Icons";
import Item from "@features/setting/components/body/component/ThemeSetting/Item";
import FullScreenPopup from "@share/components/full-screen-popup";
import GeneralSetting from "@features/setting/components/ThemeSetting/GeneralSetting";
import BlockSetting from "@features/setting/components/ThemeSetting/BlockSetting";
import TitleSetting from "@features/setting/components/ThemeSetting/TitleSetting";
import ButtonSetting from "@features/setting/components/ThemeSetting/ButtonSetting";
import ParagraphSetting from "@features/setting/components/ThemeSetting/ParagraphSetting";
import gaEvents, { CATEGORY_CONFIG, LABEL_CONFIG } from "@share/lib/ga-events";
import { handleCapitalizeFirstLetter } from "@share/lib";
import { SETTING_TOUR_CLASS_NAME } from "../SettingTourOpening/SettingTour/setting-tour-config";
import cls from "classnames";
import {useSelector} from "react-redux";
import {selectProfile} from "@features/user/userSlice";
import {useAppSelector} from "@app/hooks";

const LIST_SETTING = {
  GENERAL: "general",
  BLOCK: "block",
  HEADING: "heading",
  BUTTON: "button",
  PARAGRAPH: "paragraph",
};

const SETTING_MAPPING = {
  [LIST_SETTING.GENERAL]: GeneralSetting,
  [LIST_SETTING.BLOCK]: BlockSetting,
  [LIST_SETTING.HEADING]: TitleSetting,
  [LIST_SETTING.BUTTON]: ButtonSetting,
  [LIST_SETTING.PARAGRAPH]: ParagraphSetting,
};

const Setting = (props: {
  type: string;
  backToAllSetting: () => void;
  visible: boolean;
}) => {
  const { type, backToAllSetting, visible } = props;
  const Renderer = SETTING_MAPPING[type];
  return Renderer ? (
    <FullScreenPopup
      visible={visible}
      onMaskClick={backToAllSetting}
    >
      <Renderer
        backToAllSetting={backToAllSetting}
        type={type}
      />
    </FullScreenPopup>
  ) : (
    <></>
  );
};

export const ThemeSetting = (props) => {
  const { t } = useTranslation();
  const [settingType, setSettingType] = useState(null);
  const [visible, setVisible] = useState(false);
  const listSettingArr = [
    {
      type: LIST_SETTING.GENERAL,
      icon: () => <GeneralIcon className="m-auto" />,
      title: "ss_builder_theme_setting_general",
    },
    {
      type: LIST_SETTING.BLOCK,
      icon: () => <BlockIcon className="m-auto" />,
      title: "ss_builde_theme_setting_block",
    },
    {
      type: LIST_SETTING.HEADING,
      icon: () => <HeaderIconV2 className="m-auto" />,
      title: "ss_builder_theme_setting_title",
    },
    {
      type: LIST_SETTING.PARAGRAPH,
      icon: () => <TextIcon className="m-auto" />,
      title: "ss_builder_theme_setting_paragraph",
    },
    {
      type: LIST_SETTING.BUTTON,
      icon: () => <ButtonIconV2 className="m-auto" />,
      title: "ss_builder_theme_setting_button",
    },
  ];

  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);

  const handleSelectItem = (type) => {
    setSettingType(type);
    if (
      type === LIST_SETTING.HEADING ||
      type === LIST_SETTING.BLOCK ||
      type === LIST_SETTING.BUTTON ||
      type === LIST_SETTING.GENERAL ||
      type === LIST_SETTING.PARAGRAPH
    ) {
      setVisible(true);
    } else setVisible(false);
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.THEME_SETTINGS,
      `ThemeSettings_${handleCapitalizeFirstLetter(type)}_${
        LABEL_CONFIG.CLICK_ON
      }`,
      LABEL_CONFIG.CLICK_ON,
      handleCapitalizeFirstLetter(type)
    );
  };

  return (
    <div
      className={cls(
        "pb-3 bg-[#fff] rounded-[4px] mb-3",
        SETTING_TOUR_CLASS_NAME.STEP_2
      )}
    >
      <div
        style={{
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "130%",
          padding: "12px 12px 16px 12px",
          color: "#666666",
        }}
      >
        {t("ss_builder_setup_theme_setting")}
      </div>

      <div
        className="gap-4 flex"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
          gap: "10px",
          padding: "0px 12px",
          alignItems: "center",
        }}
      >
        {listSettingArr?.map((ele, index) => (
          <div key={index}>
            <Item
              Icon={ele.icon}
              title={ele.title}
              onClick={() => handleSelectItem(ele.type)}
            />
          </div>
        ))}
      </div>
      <Setting
        type={settingType}
        backToAllSetting={() => setVisible(false)}
        visible={visible}
      />
    </div>
  );
};
export default ThemeSetting;
