import {useAppDispatch, useAppSelector} from "@app/hooks";
import {requestGetSite, sendRating, sendRequestNoNeedToRating} from "@share/api";
import { CheckCircleIcon } from "@share/icons";
import { StarFillIcon } from "@share/icons/line-icon";
import { Rate, Selector, TextArea, Toast } from "antd-mobile";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PopupComponent from "../PopupComponent";
import RatingStarComponent from "../RatingStarComponent";
import {setSiteInfo} from "@features/main/store/siteSettingSlice";

const RATING_STAR = {
  STAR_1: 1,
  STAR_2: 2,
  STAR_3: 3,
  STAR_4: 4,
  STAR_5: 5,
};
const RATING_CATEGORY = {
  CATEGORY_1: "unsatisfactory",
  CATEGORY_2: "need_improvement",
  CATEGORY_3: "not_bad",
  CATEGORY_4: "satisfactory",
  CATEGORY_5: "good",
};
const DESCRIPTION_MAXIMUM = 500;

export const RatingStarAutoComponent = ({
  onClose,
}: {
  onClose?: () => void;
}) => {
  const { t } = useTranslation();
  const [rating, setRating] = useState(null);
  const [isOpenPopupRating, setIsOpenPopupRating] = useState(false);
  const handleRatingStar = (value: number) => {
    if (value > 0) {
      setRating(value);
      handleOpenPopupRating(true);
    }
  };
  const handleClose = () => {
    onClose();
  };

  const handleOpenPopupRating = (value) => {
    if(!value){
      handleClose();
    }else{
      setIsOpenPopupRating(value);
    }
  };

  return (
    <div className="popup-auto">
      <div className="rating-popup-content h-full">
        <div className="max-h-full overflow-auto">
          <div className="rating-star form-item">
            <Rate
              className="w-full"
              onChange={(val) => handleRatingStar(val)}
              character={
                <StarFillIcon
                  width={30}
                  height={30}
                  fillColor="var(--adm-color-light)"
                />
              }
              style={{
                "--inactive-color": "var(--adm-color-light)",
                "--active-color": "#ffd21e",
              }}
            />
            <div className="title-star">
              {t("ss_builder_block_rating_description_auto_show")}
            </div>
          </div>
        </div>
      </div>
      <PopupComponent
        visible={isOpenPopupRating}
        onMaskClick={() => {}}
        title={t("ss_builder_block_rating_title")}
        onClose={() => handleOpenPopupRating(false)}
        classNameBody="max-h-[60vh] h-[60vh] popup-hard"
      >
        <RatingStarComponent isAuto={true} ratingDefault={rating} onClose={() => handleOpenPopupRating(false)} />
      </PopupComponent>
    </div>
  );
};

export default RatingStarAutoComponent;
