import React, { useEffect } from "react";

const HeadingColor = ({ headingColorGenerate }) => {
  useEffect(() => {
    if (headingColorGenerate) {
      for (var i = 1; i <= 10; i++) {
        if (headingColorGenerate[`heading-color-${i}`]) {
          document.documentElement.style.setProperty(
            `--heading-color-${i}`,
            headingColorGenerate[`heading-color-${i}`]
          );
        }
      }
    }
  }, [headingColorGenerate]);
  return null;
};

export default HeadingColor;
