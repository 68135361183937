import { Divider, Slider, Button } from "antd-mobile";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SettingIcon } from "@share/icons";
import {
  BUTTON_SETTING_DEFAULT_VALUE,
  BUTTON_SETTING_RADIUS_STYLE,
  BUTTON_SETTING_SHADOW_STYLE,
} from "@share/configs/const";
import cls from "classnames";

export interface ButtonDetailSettingInterface {
  font_size: number;
  line_height: number;
  font_weight: number;
  type: string;
}

export default function DetailButtonSetting(props: {
  value: ButtonDetailSettingInterface;
  onUpdate: (key, value) => void;
  buttonSettingData;
}) {
  const { value, onUpdate, buttonSettingData } = props;
  const { font_size, line_height, font_weight, type } = value;
  const { t } = useTranslation();
  const {
    enable_border = BUTTON_SETTING_DEFAULT_VALUE.ENABLE_BORDER,
    border_color = BUTTON_SETTING_DEFAULT_VALUE.BORDER_COLOR,
    background_color = BUTTON_SETTING_DEFAULT_VALUE.BACKGROUND_COLOR,
    text_color = BUTTON_SETTING_DEFAULT_VALUE.TEXT_COLOR,
    border_width = BUTTON_SETTING_DEFAULT_VALUE.BORDER_WIDTH,
    radius_type = BUTTON_SETTING_DEFAULT_VALUE.RADIUS_TYPE,
    radius_value = BUTTON_SETTING_DEFAULT_VALUE.RADIUS_VALUE,
    shadow_type = BUTTON_SETTING_DEFAULT_VALUE.SHADOW_TYPE,
    shadow_color = shadow_type === BUTTON_SETTING_SHADOW_STYLE.SOFT_SHADOW
      ? BUTTON_SETTING_DEFAULT_VALUE.SOFT_SHADOW_COLOR
      : BUTTON_SETTING_DEFAULT_VALUE.HARD_SHADOW_COLOR,
  } = buttonSettingData;

  const borderRadiusValue = useMemo(() => {
    let value = "0px";
    if (radius_type === "rounded") {
      value = "4px";
    } else if (radius_type === "custom") {
      value = `${radius_value}px`;
    }
    return value;
  }, [radius_type, radius_value]);
  const styleBorder =
    enable_border === true
      ? {
          borderWidth: border_width + "px",
          borderStyle: radius_type,
          borderColor: border_color,
        }
      : { border: "none" };

  const getClassByTypeButton = (type) => {
    switch (type) {
      case "big":
        return "large";
      case "small":
        return "small";
      case "mini":
        return "mini";
      default:
        return "middle";
    }
  };

  const styleShadow = (shadow_type) => {
    let styles = {};
    switch (shadow_type) {
      case BUTTON_SETTING_SHADOW_STYLE.NO_SHADOW:
        styles = { boxShadow: "none" };
        break;
      case BUTTON_SETTING_SHADOW_STYLE.SOFT_SHADOW:
        styles = { boxShadow: `0px 5px 12px ${shadow_color}` };
        break;
      case BUTTON_SETTING_SHADOW_STYLE.HARD_SHADOW:
        styles = { boxShadow: `5px 5px 0px ${shadow_color}` };
        break;
      default:
        styles = { boxShadow: "none" };
    }
    return styles;
  };

  return (
    <>
      {/*<div className="preview-button-block mb-3 pb-3">*/}
      {/*  <div className="text-[#666666] text-[15px]">*/}
      {/*    {t("ss_builder_preview")}*/}
      {/*  </div>*/}
      {/*  <div className="mt-1 p-3 w-full h-full preview-general-block text-center flex justify-center">*/}
      {/*    <Button*/}
      {/*      color="primary"*/}
      {/*      className={cls(*/}
      {/*        "flex items-center adm-button adm-button-primary adm-button-shape-default gap-2"*/}
      {/*      )}*/}
      {/*      size={getClassByTypeButton(type)}*/}
      {/*      style={{*/}
      {/*        backgroundColor: background_color,*/}
      {/*        color: text_color,*/}
      {/*        fontSize: font_size,*/}
      {/*        lineHeight: line_height,*/}
      {/*        fontWeight: font_weight,*/}
      {/*        borderRadius: borderRadiusValue,*/}
      {/*        ...styleBorder,*/}
      {/*        ...styleShadow(shadow_type)*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <SettingIcon*/}
      {/*        width={font_size}*/}
      {/*        height={font_size}*/}
      {/*        fillColor={text_color}*/}
      {/*      />*/}
      {/*      {t("ss_builder_button_title")}*/}
      {/*    </Button>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="mb-3">
        <div className="text-base mb-4">{t("ss_builder_button_font_size")}</div>
        <Slider
          onChange={(val) => onUpdate("font_size", parseInt(val.toString()))}
          value={font_size}
          marks={{
            13: 13,
            14: 14,
            15: 15,
            16: 16,
            17: 17,
            18: 18,
            19: 19,
            20: 20,
            21: 21,
            22: 22,
            23: 23,
            24: 24,
          }}
          ticks={true}
          min={13}
          max={24}
          step={1}
        />
      </div>
      <Divider />
      <div className="mb-3">
        <div className="text-base mb-4">
          {t("ss_builder_button_line_height")}
        </div>
        <Slider
          onChange={(val) =>
            onUpdate("line_height", parseFloat(val.toString()))
          }
          value={line_height}
          marks={{
            1.0: 1.0,
            1.1: 1.1,
            1.2: 1.2,
            1.3: 1.3,
            1.4: 1.4,
            1.5: 1.5,
            1.6: 1.6,
            1.7: 1.7,
            1.8: 1.8,
            1.9: 1.9,
            2.0: 2.0,
          }}
          ticks={true}
          min={1.0}
          max={2.0}
          step={0.1}
        />
      </div>
      <Divider />
      <div className="mb-3">
        <div className="text-base mb-4">
          {t("ss_builder_button_font_weight")}
        </div>
        <Slider
          onChange={(val) => onUpdate("font_weight", parseInt(val.toString()))}
          value={font_weight}
          marks={{
            400: t("ss_builder_medium"),
            500: "",
            600: "",
            700: "",
            800: "",
            900: t("ss_builder_bold"),
          }}
          // ticks={true}
          min={400}
          max={900}
          step={100}
        />
      </div>
    </>
  );
}
