import { ClosePopupIcon } from "@share/icons";
import { Picker } from "antd-mobile";
import React from "react";
import { useTranslation } from "react-i18next";

export const SCALE_OPTIONS = [
  [
    {
      value: "1.067",
      label: "1.067 - Minor Second",
    },
    {
      value: "1.125",
      label: "1.125 - Major Second",
    },
    {
      value: "1.200",
      label: "1.200 - Minor Third",
    },
    {
      value: "1.250",
      label: "1.250 - Major Third",
    },
    {
      value: "1.333",
      label: "1.333 - Perfect Fourth",
    },
    {
      value: "1.414",
      label: "1.414 - Augmented Fourth",
    },
    {
      value: "1.500",
      label: "1.500 - Perfect Fifth",
    },
    {
      value: "1.618",
      label: "1.618 - Golden Ratio",
    },
  ],
];

export default function ScaleSeletor(props: {
  value: string;
  visible: boolean;
  onClose: () => void;
  onSelect: (val: string) => void;
}) {
  const { onClose, onSelect, value, visible } = props;
  const { t } = useTranslation();

  return (
    <Picker
      style={{
        width: "100%",
      }}
      title={t("ss_builder_select_ratio_title")}
      popupClassName={"eco-popup"}
      className={"eco-picker rounded-tl-lg rounded-tr-lg"}
      columns={SCALE_OPTIONS}
      visible={visible}
      onClose={() => {
        onClose();
      }}
      cancelText={<ClosePopupIcon />}
      confirmText={t("ss_builder_confirm_panel")}
      value={[value]}
      onConfirm={(v) => {
        onSelect(v[0]);
        onClose();
      }}
    />
  );
}
