import FullScreenPopup from "@share/components/full-screen-popup";
import { BackAngelIcon } from "@share/icons";
import { Button, NavBar, Image, SpinLoading } from "antd-mobile";
import cls from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import LazyImage from "@share/components/lazy-image";

interface PopupIntroductionBrandGiftProps {
  visible: boolean;
  onBack: () => void;
  onContinue: () => void;
}

const PopupIntroductionBrandGift: React.FunctionComponent<
  PopupIntroductionBrandGiftProps
> = (props) => {
  const { visible, onBack, onContinue } = props;
  const { t } = useTranslation();

  const listImgs = [
    "https://image.passio.eco/page-builder/page-builder/intro-new-feature/IntroductBrandGift-1.jpg",
    "https://image.passio.eco/page-builder/page-builder/intro-new-feature/IntroductBrandGift-2.jpg",
    "https://image.passio.eco/page-builder/page-builder/intro-new-feature/IntroductBrandGift-3.jpg",
    "https://image.passio.eco/page-builder/page-builder/intro-new-feature/IntroductBrandGift-4.jpg",
  ];

  return (
    <>
      <FullScreenPopup
        visible={visible}
        onMaskClick={onBack}
      >
        <div className="block-editor-wrapper relative h-full">
          <div className="pb-[60px]">
            <div className="sticky top-0 z-[11]">
              <div
                className="relative bg-white"
                style={{
                  boxShadow: "inset 0px -1px 0px #EEEEEE",
                }}
              >
                <NavBar
                  backArrow={<BackAngelIcon />}
                  onBack={onBack}
                  className="w-full"
                >
                  <div className="flex flex-row text-center items-center justify-center gap-[10px] text-[13px]">
                    <div className="text-ellipsis whitespace-nowrap overflow-hidden max-w-[70vw] md:max-w-[20vw] text-titleFontSize">
                      {t("ss_brand_gift_introduction_popup_title")}
                    </div>
                  </div>
                </NavBar>
              </div>
            </div>
            <div className="list-img-introduction-brand-gift">
              {listImgs.map((src, index) => (
                <LazyImage
                  key={index}
                  src={src}
                  style={{
                    marginBottom: "-1px",
                  }}
                />
              ))}
            </div>
          </div>
          <div
            className={cls(
              "max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[10] bg-[#FFFFFF]"
            )}
          >
            <Button
              className="w-full"
              color="primary"
              onClick={onContinue}
            >
              {t("ss_builder_continue_add_brand_gift")}
            </Button>
          </div>
        </div>
      </FullScreenPopup>
    </>
  );
};

export default PopupIntroductionBrandGift;
