import { useAppSelector } from "@app/hooks";
import { BLOCK_SETTING_DEFAULT_VALUE } from "@share/configs/const";
import { AddCircleIcon, CloseIconAlert } from "@share/icons";
import React from "react";
import { useTranslation } from "react-i18next";

export interface PlaceholderProps {
  clientHeight?: number;
  clientWidth?: number;
  clientY?: number;
  clientX?: number;
}

interface Props {
  placeholderProps: PlaceholderProps;
  isFullBlock: boolean;
}

const PlaceholderCustom = ({ placeholderProps, isFullBlock }: Props) => {
  const { t } = useTranslation();

  const block_style = useAppSelector(
    (state) => state.siteSettings?.display_setting?.block_setting?.block_style
  );
  const { outside_space } = block_style || {};

  const placeholderWidth = !outside_space
    ? placeholderProps.clientWidth
    : placeholderProps.clientWidth -
      BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER * 2;

  const iconRender = isFullBlock ? (
    <CloseIconAlert
      width={16}
      height={16}
      fillColor="#8C8C8C"
    />
  ) : (
    <AddCircleIcon
      width={16}
      height={16}
      fillColor="#1890FF"
    />
  );

  const labelRender = isFullBlock ? (
    <span className="text-[#8C8C8C]">{t("ss_builder_exceeding_label")}</span>
  ) : (
    <span className="text-[#1890FF]">{t("ss_builder_drop_here_label")}</span>
  );

  const styleRender = isFullBlock
    ? {
        backgroundColor: "rgba(245, 245, 245, 0.75)",
        border: "1px dashed #8C8C8C",
      }
    : {
        backgroundColor: "rgba(230, 247, 255, 0.75)",
        border: "1px dashed #1890FF",
      };

  return (
    <div
      className="absolute flex justify-center items-center flex-row gap-1"
      style={{
        top: placeholderProps.clientY,
        left: outside_space
          ? placeholderProps.clientX + 12
          : placeholderProps.clientX,
        height: placeholderProps.clientHeight,
        width: placeholderWidth,
        borderRadius: "var(--block-border-radius)",
        ...styleRender,
      }}
    >
      {iconRender}
      {labelRender}
    </div>
  );
};

export default PlaceholderCustom;
