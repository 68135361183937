import { useAppDispatch, useAppSelector } from "@app/hooks";
import { CREATOR_TYPE_ENUM } from "@features/main/store/interface";
import { setWebsiteConfig } from "@features/main/store/websiteConfigSlice";
import { requestGetSiteSetting } from "@share/api";
import {
  CREATOR_FOURTEEN_DEFAULT_ICON,
  OLD_PASSIO_DEFAULT_ICON,
  PASSIO_DEFAULT_ICON,
} from "@share/configs/const";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const useGetWebsiteConfig = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user_id = useAppSelector((state) => state?.user?.kol?._id);
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);
  const creatorType = passioPageProfile?.creator_type;
  useEffect(() => {
    const getWebsiteConfig = async () => {
      let response = null;
      try {
        response = await requestGetSiteSetting(user_id);
      } catch (error) {
        console.log(error);
      }
      if (response) {
        const data = response?.data;
        if (data) {
          const favicon =
            creatorType === CREATOR_TYPE_ENUM.FOURTEEN &&
            (data?.favicon === OLD_PASSIO_DEFAULT_ICON ||
              data?.favicon === PASSIO_DEFAULT_ICON)
              ? CREATOR_FOURTEEN_DEFAULT_ICON
              : data?.favicon ?? PASSIO_DEFAULT_ICON;
          const meta_title = data?.meta_title || "";
          const meta_description =
            data?.meta_description ?? t("ss_builder_default_meta_description");
          dispatch(
            setWebsiteConfig({
              data: {
                id: data?.id,
                favicon,
                meta_title,
                meta_description,
                site_title: data?.site_title,
              },
            })
          );
        }
      }
    };
    getWebsiteConfig();
  }, [user_id]);
  return null;
};

export default useGetWebsiteConfig;
