import { getPackageSass, requestSearchThemeTemplate } from "@share/api";
import FullScreenPopup from "@share/components/full-screen-popup";
import {
  THEME_RENDER_LIST_KEY,
  THEME_TEMPLATE_KEY,
  COUNTRY_PH,
} from "@share/configs/const";
import { InformationTriangleIcon, SaveIcon } from "@share/icons";
import { Button, Modal, SearchBar } from "antd-mobile";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ThemeTemplateLists from "../ThemeTemplateLists";
import ThemeTemplateSaving from "../ThemeTemplateSaving";
import { CREATOR_ROLE_ENUM } from "@features/main/store/interface";
import { useAppSelector } from "@app/hooks";
import debounce from "lodash/debounce";

type Props = {
  onCancelThemeTemplateOverwrite: any;
  onSaveThemeTemplateOverwrite: any;
  user: any;
};

const ThemeTemplateOverwrite = (props: Props) => {
  const { onCancelThemeTemplateOverwrite, onSaveThemeTemplateOverwrite, user } =
    props;
  const [selectedThemeData, setSelectedThemeData] = useState(null);
  const [themeTemplateArr, setThemeTemplateArr] = useState([]);
  const [isOpenOverwriteThemeSaving, setIsOpenOverwriteThemeSaving] =
    useState(false);

  const { t } = useTranslation();
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);

  const creator_role = passioPageProfile?.creator_role;
  const isAdminRole = useMemo(
    () => creator_role === CREATOR_ROLE_ENUM.ADMIN,
    [creator_role]
  );

  useEffect(() => {
    const requestThemeTemplateList = async () => {
      let listThemeResponse = null;
      let packageSASS = null;
      const dataRequest = {
        per_page: 100,
        keyword: "",
        is_admin: isAdminRole,
      };
      try {
        listThemeResponse = await requestSearchThemeTemplate(dataRequest);
        packageSASS = await getPackageSass();
      } catch (error) {
        console.log(error);
      }
      const listThemePremium = packageSASS?.data?.data?.packages?.find(
        (item) => item.id === 1
      )?.params?.passio_page_service?.premium_theme;
      const listThemeArr = listThemeResponse?.data?.map((theme) => {
        if (listThemePremium?.includes(theme?.id))
          return {
            ...theme,
            is_premium: true,
          };
        return { ...theme, is_premium: false };
      });
      // check thi truong PH
      if (listThemeArr && user?.kol?.country === COUNTRY_PH) {
        setThemeTemplateArr(listThemeArr);
      } else {
        const listThemeArrClone = listThemeArr?.filter(
          (item) => !item?.is_premium
        );
        setThemeTemplateArr(listThemeArrClone);
      }
    };
    requestThemeTemplateList();
  }, []);

  const handleSelectTheme = (val) => {
    setSelectedThemeData(val);
  };
  const handleSaveThemeTemplateOverwrite = () => {
    Modal.confirm({
      header: (
        <InformationTriangleIcon
          className="w-11 h-11"
          fillColor="var(--adm-color-primary)"
          onClick={() => {}}
        />
      ),
      title: (
        <div>
          {t("ss_builder_confirm_overwrite_theme_template_modal_title")}
        </div>
      ),
      content: (
        <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
          {t("ss_builder_confirm_overwrite_theme_template_modal_content")}
        </div>
      ),
      confirmText: t("ss_builder_confirm_overwrite_theme_template_btn_label"),
      cancelText: t("ss_builder_cancel_overwrite_theme_template_btn_label"),
      onConfirm: () => {
        setIsOpenOverwriteThemeSaving(true);
      },
      onCancel: () => {
        return;
      },
    });
  };

  const themeSelectData = useMemo(
    () => themeTemplateArr?.find((x) => x.id === selectedThemeData?.id),
    [themeTemplateArr, selectedThemeData]
  );

  const handleCancelOverwriteThemeSaving = () => {
    setIsOpenOverwriteThemeSaving(false);
  };

  const handleConfirmOverwriteTheme = (data) => {
    onSaveThemeTemplateOverwrite(data, handleCancelOverwriteThemeSaving);
  };

  const handleRequestSearchTheme = async (value) => {
    let searchResponseData = null;
    const dataRequest = {
      per_page: 100,
      keyword: value.trim(),
      is_admin: isAdminRole,
    };
    try {
      searchResponseData = await requestSearchThemeTemplate(dataRequest);
    } catch (error) {
      console.log(error);
    }
    const listThemeArr = searchResponseData?.data;
    if (listThemeArr) setThemeTemplateArr(listThemeArr);
  };

  const handleSearchThemeTemplate = useCallback(
    debounce(handleRequestSearchTheme, 1000),
    []
  );

  return (
    <div className="h-full">
      <div
        className="sticky top-0 bg-white pb-3 z-[6]"
        style={{
          boxShadow: "inset 0px -1px 0px #EEEEEE",
        }}
      >
        <div className="flex flex-row text-center items-center justify-center py-3 gap-[10px]">
          <SaveIcon className="w-6 h-6" />
          <div className="text-[18px] leading-6">
            {t("ss_builder_overwrite_theme_template_title")}
          </div>
        </div>
        <SearchBar
          placeholder={t("ss_builder_theme_template_search_bar_placeholder")}
          className="mx-4"
          onChange={(val) => handleSearchThemeTemplate(val)}
          onSearch={(val) => handleRequestSearchTheme(val)}
        />
      </div>
      <ThemeTemplateLists
        onSelectTheme={handleSelectTheme}
        KEY={THEME_RENDER_LIST_KEY.THEME_OVERWRITE_SELECTION}
        themeTemplateData={themeTemplateArr}
      />
      <div className="max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[1] bg-[#FFFFFF]">
        <div className="w-1/2 text-center">
          <Button
            className="w-full"
            onClick={onCancelThemeTemplateOverwrite}
          >
            {t("ss_builder_editor_btn-cancel")}
          </Button>
        </div>
        <div className="w-1/2 text-center">
          <Button
            className="w-full"
            color={"primary"}
            onClick={handleSaveThemeTemplateOverwrite}
            disabled={!selectedThemeData}
          >
            {t("ss_builder_overwrite_theme_btn_label")}
          </Button>
        </div>
      </div>
      {isOpenOverwriteThemeSaving && (
        <FullScreenPopup
          visible={isOpenOverwriteThemeSaving}
          onMaskClick={() => {
            setIsOpenOverwriteThemeSaving(false);
          }}
        >
          <ThemeTemplateSaving
            handleCancelThemeSaving={() => setIsOpenOverwriteThemeSaving(false)}
            KEY={THEME_TEMPLATE_KEY.OVERWRITE}
            handleConfirmThemeSaving={handleConfirmOverwriteTheme}
            themeTemplateData={themeSelectData}
          />
        </FullScreenPopup>
      )}
    </div>
  );
};

export default ThemeTemplateOverwrite;
