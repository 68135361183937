import { useAppSelector } from "@app/hooks";
import { requestGetSiteSetting } from "@share/api";
import { copyToClipboard } from "@share/helper/browser";
import {
  CheckCircleIcon,
  CloseIcon,
  ClosePopupIcon,
  CoppyAltIcon,
  CoppyIcon,
  GlobalIcon,
  ShareIconV3,
} from "@share/icons";
import { Button, Divider, Input, NavBar, Result, Toast } from "antd-mobile";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import gaEvents, { ACTION_CONFIG, CATEGORY_CONFIG } from "@share/lib/ga-events";
import {useSelector} from "react-redux";
import {selectProfile} from "@features/user/userSlice";

type Props = {
  callbackClosePublishResult: any;
};

const ResultPublishComponent = (props: Props) => {
  const { callbackClosePublishResult } = props;

  const userState = useAppSelector((state) => state.user);
  const siteSettings = useAppSelector((state) => state?.siteSettings);
  const kol = userState && userState.kol;
  const valueLink = kol && kol.domain;
  const user_id = kol && kol._id;

  const { t } = useTranslation();
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  useEffect(() => {
    const getWebsiteConfig = async () => {
      let response = null;
      try {
        response = await requestGetSiteSetting(user_id);
      } catch (error) {
        console.log(error);
      }
      if (response) {
        const data = response?.data;
        if (data) {
          const meta_title = data?.meta_title || "";
          const meta_description =
            data?.meta_description ?? t("ss_builder_default_meta_description");
          setMetaTitle(meta_title);
          setMetaDescription(meta_description);
        }
      }
    };
    getWebsiteConfig();
  }, [user_id]);

  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);

  const handleClickCoppyLink = () => {
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.PUBLISH_RESULT,
      "PublishResult_CopyDomain",
      valueLink,
      ACTION_CONFIG.COPY_DOMAIN
    );
    copyToClipboard(`https://${valueLink}`);
    Toast.show({
      icon: (
        <CheckCircleIcon
          className="text-center inline-block w-[30px] h-[30px]"
          fillColor="#00B578"
        />
      ),
      content: (
        <div className="text-center">
          {t("ss_builder_personal_link_coppy_successfully_label")}
        </div>
      ),
    });
  };

  const handleBackPublishResult = () => {
    callbackClosePublishResult();
  };

  const handleClickToLink = (link) => {
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.PUBLISH_RESULT,
      "PublishResult_ViewSiteInBrowser",
      valueLink,
      ACTION_CONFIG.VIEW_SITE
    );
    const castWindow = window as any;
    if (castWindow.flutter_inappwebview !== undefined) {
      var args = [{ url: `https://${link}` }];
      castWindow.flutter_inappwebview.callHandler("openInBrowser", ...args);
    } else {
      window.open(`https://${link}`, "_blank");
    }
  };

  const handleShare = (url) => {
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.PUBLISH_RESULT,
      "PublishResult_ShareSite",
      valueLink,
      ACTION_CONFIG.SHARE
    );

    const castWindow = window as any;
    const args2 = [{ url }];
    if (castWindow.flutter_inappwebview !== undefined) {
      castWindow.flutter_inappwebview.callHandler("share", ...args2);
    } else if (!!navigator?.share) {
      navigator?.share({
        title: metaTitle,
        text: metaDescription,
        url,
      });
    }
  };

  return (
    <div className="h-full w-full bg-[#fff]">
      <div
        className="w-[26px] h-[26px] absolute right-2 top-2 flex justify-center items-center"
        onClick={() => handleBackPublishResult()}
      >
        <ClosePopupIcon className="cursor-pointer" />
      </div>

      <div className="mb-3 flex justify-center">
        <CheckCircleIcon
          width={48}
          height={48}
          fillColor="#00B578"
        />
      </div>

      <div className="mb-2 text-[18px] leading-[25px] text-center font-bold">
        {t("ss_builder_publish_result_successfully")}
      </div>
      <div className="text-center mb-4">
        {t("ss_builder_publish_result_description")}
      </div>
      <div>
        <div className="text-[#666] mb-1">
          {t("ss_builder_personal_link_title")}
        </div>

        <div
          className="flex items-center justify-between bg-[#F5F5F5]"
          style={{
            borderRadius: "4px",
            height: "40px",
          }}
        >
          <div
            className="flex items-center overflow-hidden h-full pl-3"
            style={{
              width: "calc(100% - 40px)",
            }}
          >
            <span className="text-[17px]">{`${valueLink}`}</span>
          </div>
          <Button
            size="middle"
            onClick={handleClickCoppyLink}
            style={{
              border: "none",
              width: "40px",
              backgroundColor: "unset",
              display: "flex",
              height: "100%",
            }}
            block
            className="flex justify-center items-center "
          >
            <CoppyAltIcon
              width={18}
              height={18}
            />
          </Button>
        </div>

        <Button
          className="flex items-center justify-center gap-2 w-full"
          fill="solid"
          size="middle"
          onClick={() => handleClickToLink(valueLink)}
          style={{
            display: "flex",
            overflow: "hidden",
            marginTop: "12px",
          }}
        >
          <GlobalIcon
            width={17}
            height={17}
          />
          {t("ss_builder_publish_view_page")}
        </Button>
        <Button
          className="flex items-center justify-center gap-2 w-full mt-3"
          fill="solid"
          size="middle"
          onClick={() => {
            handleShare(`https://${valueLink}`);
          }}
          style={{
            display: "flex",
            overflow: "hidden",
            marginTop: "12px",
          }}
        >
          <ShareIconV3
            width={17}
            height={17}
          />
          {t("ss_builder_publish_share")}
        </Button>
        {/* <Divider /> */}
        {/* <div className="text-titleFontSize text-textColorDefault">
          {t("ss_builder_personal_link_sharing_title")}
        </div>
        <div className="text-descFontSize text-descColorDefault">
          {t("ss_builder_personal_link_sharing_description")}
        </div> */}
        {/* <div className="flex flex-row items-center gap-4">
          {buttonShareArr.map((ele, idx) => (
            <Button color="primary" fill="solid" size="middle" key={idx}>
              {ele.content}
            </Button>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default ResultPublishComponent;
