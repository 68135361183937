import { BACKGROUND_SETTING_DEFAULT_VALUE } from "@share/configs/const";
import React, { useEffect } from "react";

const enum BackgroundTypeEnum {
  SOLID = "solid",
  GRADIENT = "gradient",
  IMAGE = "image",
}

const BackgroundStyle = ({ backgroundSetting }) => {
  useEffect(() => {
    const { color, gradient, image, type, blur, overlay_color } =
      backgroundSetting || {};
    //Background solid color
    if (type === BackgroundTypeEnum.SOLID) {
      document.documentElement.style.setProperty(
        "--background-color",
        color ?? BACKGROUND_SETTING_DEFAULT_VALUE.COLOR_SOLID
      );
      document.documentElement.style.setProperty("--background-image", "none");
    }
    //Background gradient color
    if (type === BackgroundTypeEnum.GRADIENT) {
      document.documentElement.style.setProperty(
        "--background-image",
        gradient ??
          `linear-gradient(${BACKGROUND_SETTING_DEFAULT_VALUE.COLOR_GRADIENT_DIRECTION}, ${BACKGROUND_SETTING_DEFAULT_VALUE.COLOR_GRADIENT_GENERATOR_FIRST_COLOR}, ${BACKGROUND_SETTING_DEFAULT_VALUE.COLOR_GRADIENT_GENERATOR_SECOND_COLOR})`
      );
      document.documentElement.style.setProperty("--background-color", "none");
    }
    //Background image
    if (image && type === BackgroundTypeEnum.IMAGE) {
      document.documentElement.style.setProperty(
        "--background-image",
        `url('${image}')`
      );
      document.documentElement.style.setProperty("--background-color", "none");
    }
    //Background blur
    document.documentElement.style.setProperty(
      "--background-blur",
      blur && typeof blur === "number"
        ? `${blur}px`
        : `${BACKGROUND_SETTING_DEFAULT_VALUE.BLUR}px`
    );
    //Background overlay color
    document.documentElement.style.setProperty(
      "--background-overlay-color",
      overlay_color || BACKGROUND_SETTING_DEFAULT_VALUE.OVERLAY_COLOR
    );
  }, [backgroundSetting]);
  return null;
};

export default BackgroundStyle;
