import { ArrowRightIcon, ClosePopupIconV2, QuestionIcon } from "@share/icons";
import { Button, Popover } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SettingTour from "./SettingTour";

const SettingTourOpening = ({}) => {
  const { t } = useTranslation();
  const [isOpenSettingTour, setIsOpenSettingTour] = useState(false);
  const popoverRef = useRef(null);
  const popoverManualRef = useRef(null);

  const handleHidePopoverManual = () => {
    popoverManualRef.current.hide();
  };
  const handleHideSettingTour = () => {
    setIsOpenSettingTour(false);
  };

  const contentPopoverManual = () => {
    return (
      <div className="w-[320px] relative max-w-full">
        <div
          className="w-[32px] h-[32px] absolute p-1 right-2 top-2 cursor-pointer z-[3]"
          onClick={() => handleHidePopoverManual()}
        >
          <ClosePopupIconV2 fillColor="#fff" />
        </div>
        <div className="">
          <img
            src="https://image.passio.eco/page-builder/page-builder/setting-tour/setting-tour-0.gif"
            alt=""
          />
        </div>
        <div className="p-3">
          <div className="mb-2 text-lg leading-[25px] font-bold">
            {t("ss_builder_setting_popover_manual_title")}
          </div>
          <div className="text-[15px] font-normal leading-[140%] text-textColorDefault  mb-5">
            {t("ss_builder_setting_popover_manual_content")}
          </div>
          <Button
            color="primary"
            size="middle"
            fill="solid"
            onClick={() => {
              handleHidePopoverManual();
              setIsOpenSettingTour(true);
            }}
            block
          >
            <span className="flex items-center w-full justify-center gap-2 text-[17px]">
              {t("ss_builder_popover_manual_button")}{" "}
              <ArrowRightIcon fillColor="#fff" />
            </span>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className="user-manual"
        ref={popoverRef}
      >
        <Popover
          className="popover-user-manual"
          content={contentPopoverManual()}
          placement="bottom-end"
          trigger="click"
          stopPropagation={["click"]}
          getContainer={() => popoverRef.current}
          ref={popoverManualRef}
        >
          <span>
            <QuestionIcon
              width={20}
              height={20}
              className="cursor-pointer"
            />
          </span>
        </Popover>
      </div>
      {isOpenSettingTour && (
        <SettingTour handleHideSettingTour={handleHideSettingTour} />
      )}
    </>
  );
};

export default SettingTourOpening;
