import React from "react";

import { useTranslation } from "react-i18next";

export const Item = (props) => {
  const { Icon, title, onClick } = props;

  const { t } = useTranslation();
  return (
    <>
      <div
        onClick={onClick}
        className="text-center item-center justify-center"
        style={{
          width: "76px",
          height: "68px",
          alignItems: "center",
          margin: "auto",
          cursor: "pointer",
        }}
      >
        <span>
          <Icon />
        </span>

        <div
          style={{
            marginTop: "4px",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "13px",
            lineHeight: "131%",
          }}
        >
          {t(title)}
        </div>
      </div>
    </>
  );
};
export default Item;
