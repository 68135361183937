import React from "react";
import { Logo } from "@share/icons";
import { useTranslation } from "react-i18next";

const CopyRight = () => {
  const { t } = useTranslation();
  const handleClickLogo = () => {};
  const handleClickText = () => {};
  return (
    <div
      className="mt-3 flex"
      style={{ color: "#FFFFFF" }}
    >
      <div
        className="m-auto"
        style={{
          margin: " 20px auto 8px",
          filter: "drop-shadow(rgba(0, 0, 0, 1) 0px 0px 60px)",
        }}
      >
        <div
          onClick={handleClickLogo}
          // style={{ filter: "drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.25))" }}
        >
          <Logo className="m-auto" />
        </div>
        <div
          className="text-center default-font-family font-normal text-[12px] leading-[15px] mt-1"
          onClick={handleClickText}
          // style={{ filter: "drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.25))" }}
        >
          {t("ss_builder_powered_by_passio")}
        </div>
      </div>
    </div>
  );
};
export default CopyRight;
