import { useAppSelector } from "@app/hooks";
import { Button } from "antd-mobile";
import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import SettingHeader from "../SettingHeader";
import { saveColor } from "@share/thunk";
import { useHasChange, useOnCancel } from "@features/setting/hooks";
import {
  GENERAL_SETTING_DEFAULT_VALUE,
  MAX_WIDTH_CONTENT_LAYOUT,
} from "@share/configs/const";
import PrimaryColor from "./components/PrimaryColor";
import * as generateColor from "@share/lib/generateColor";
import gaEvents, { CATEGORY_CONFIG, LABEL_CONFIG } from "@share/lib/ga-events";
import { handleCapitalizeFirstLetter } from "@share/lib";
import { useToast } from "@share/hooks/toast";
import {selectProfile} from "@features/user/userSlice";

const HeaderIcon = ({ className = "" }) => (
  <svg
    className={className}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="22"
      height="22"
      fill="white"
      fillOpacity="0.01"
    />
    <path
      d="M17.4193 3.66772H4.58594C3.85659 3.66772 3.15712 3.95746 2.64139 4.47318C2.12567 4.98891 1.83594 5.68838 1.83594 6.41772V15.5844C1.83594 16.3137 2.12567 17.0132 2.64139 17.5289C3.15712 18.0447 3.85659 18.3344 4.58594 18.3344H17.4193C18.1486 18.3344 18.8481 18.0447 19.3638 17.5289C19.8795 17.0132 20.1693 16.3137 20.1693 15.5844V6.41772C20.1693 5.68838 19.8795 4.98891 19.3638 4.47318C18.8481 3.95746 18.1486 3.66772 17.4193 3.66772ZM4.58594 16.5011C4.34282 16.5011 4.10966 16.4045 3.93776 16.2326C3.76585 16.0607 3.66927 15.8275 3.66927 15.5844V13.3661L6.69427 10.3502C6.86562 10.1823 7.096 10.0882 7.33594 10.0882C7.57588 10.0882 7.80625 10.1823 7.9776 10.3502L14.1284 16.5011H4.58594ZM18.3359 15.5844C18.3359 15.8275 18.2394 16.0607 18.0675 16.2326C17.8955 16.4045 17.6624 16.5011 17.4193 16.5011H16.7134L13.2209 12.9902L14.0276 12.1836C14.199 12.0156 14.4293 11.9215 14.6693 11.9215C14.9092 11.9215 15.1396 12.0156 15.3109 12.1836L18.3359 15.1994V15.5844ZM18.3359 12.6144L16.6126 10.9002C16.0902 10.3983 15.3938 10.118 14.6693 10.118C13.9448 10.118 13.2484 10.3983 12.7259 10.9002L11.9193 11.7069L9.27927 9.06689C8.75682 8.56496 8.06043 8.28463 7.33594 8.28463C6.61144 8.28463 5.91505 8.56496 5.3926 9.06689L3.66927 10.7811V6.41772C3.66927 6.17461 3.76585 5.94145 3.93776 5.76954C4.10966 5.59764 4.34282 5.50106 4.58594 5.50106H17.4193C17.6624 5.50106 17.8955 5.59764 18.0675 5.76954C18.2394 5.94145 18.3359 6.17461 18.3359 6.41772V12.6144Z"
      fill="#333333"
    />
  </svg>
);

const GeneralSetting = (props) => {
  const { t } = useTranslation();
  const { SuccessToast } = useToast();
  const { backToAllSetting, type } = props;
  const defaultMainColor = useAppSelector(
    (state) => state?.siteSettings?.display_setting?.primary_color
  );
  const defaultSecondColor = useAppSelector(
    (state) => state?.siteSettings?.display_setting?.second_color
  );
  const [primaryColor, setMainColor] = useState(
    defaultMainColor || GENERAL_SETTING_DEFAULT_VALUE.PRIMARY_COLOR
  );
  const [secondColor, setSecondColor] = useState(
    defaultSecondColor || GENERAL_SETTING_DEFAULT_VALUE.SECONDARY_COLOR
  );
  const defaultSiteSettings = useAppSelector(
    (state) => state?.siteSettings?.display_setting
  );
  //generate Primary color
  const COLOR_LENGTH_DEFAULT = 9;

  const opacityPrimaryColor =
    primaryColor.length == COLOR_LENGTH_DEFAULT ? primaryColor.slice(-2) : "";
  const parseColorValuesPrimary = generateColor.parseColorValues(primaryColor);

  const calculateShadesPrimary = generateColor.calculateShades(
    parseColorValuesPrimary[0] ?? primaryColor.replace("#", "")
  );
  const calculateTintsPrimary = generateColor.calculateTints(
    parseColorValuesPrimary[0] ?? primaryColor.replace("#", "")
  );
  const calculateShadesAndTintsPrimary = [
    ...calculateTintsPrimary.reverse(),
    ...calculateShadesPrimary,
  ];
  const primaryColorGenerate = useMemo(() => {
    const primaryColorObj = {};
    calculateShadesAndTintsPrimary.forEach((ele, index) => {
      primaryColorObj[`primary-${index + 1}`] = ele + opacityPrimaryColor;
    });
    return primaryColorObj;
  }, [calculateShadesAndTintsPrimary]);

  //generate secondary color
  const opacitySecondColor =
    secondColor.length == COLOR_LENGTH_DEFAULT ? secondColor.slice(-2) : "";
  const parseColorValuesSecond = generateColor.parseColorValues(secondColor);
  const calculateShadesSecond = generateColor.calculateShades(
    parseColorValuesSecond[0] ?? secondColor.replace("#", "")
  );
  const calculateTintsSecond = generateColor.calculateTints(
    parseColorValuesSecond[0] ?? secondColor.replace("#", "")
  );
  const calculateShadesAndTintsSecond = [
    ...calculateTintsSecond.reverse(),
    ...calculateShadesSecond,
  ];
  const secondaryColorGenerate = useMemo(() => {
    const secondaryColorObj = {};
    calculateShadesAndTintsSecond.forEach((ele, index) => {
      secondaryColorObj[`secondary-${index + 1}`] = ele + opacitySecondColor;
    });
    return secondaryColorObj;
  }, [calculateShadesAndTintsSecond]);

  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);

  const dispatch = useDispatch();
  const handleSave = () => {
    dispatch(
      saveColor(
        primaryColor,
        secondColor,
        primaryColorGenerate,
        secondaryColorGenerate
      )
    );
    backToAllSetting();
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.THEME_SETTINGS,
      `ThemeSettings_${handleCapitalizeFirstLetter(type)}_${LABEL_CONFIG.EDIT}`,
      LABEL_CONFIG.EDIT,
      handleCapitalizeFirstLetter(type)
    );
    SuccessToast(t("ss_builder_save_succesfully_label"));
  };

  const hasChange = useHasChange([primaryColor, secondColor]);
  const onCancel = useOnCancel(hasChange, backToAllSetting, type);

  return (
    <>
      <div className="w-full">
        <SettingHeader
          title={t("ss_builder_general_setting_title")}
          Icon={HeaderIcon}
          // subTitle={t('ss_builder_general_setting_sub_title')}
        />
        <div className="mb-5">
          <PrimaryColor
            onUpdateSecondColor={setSecondColor}
            onUpdateColor={(data) => setMainColor(data)}
          />
        </div>
      </div>
      <div className="max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[10] bg-[#FFFFFF]">
        <div className="w-1/2 text-center">
          <Button
            onClick={onCancel}
            className="adm-button adm-button-default adm-button-shape-default w-full"
          >
            {t("ss_builder_close_panel")}
          </Button>
        </div>
        <div className="w-1/2 text-center">
          <Button
            disabled={!hasChange}
            color="primary"
            className="adm-button adm-button-primary adm-button-shape-default w-full"
            onClick={handleSave}
          >
            {t("ss_builder_save")}
          </Button>
        </div>
      </div>
    </>
  );
};
export default GeneralSetting;
