import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AwardIconV2,
  SiteIconV2,
} from "@features/setting/components/Icons";
import FullScreenPopup from "@share/components/full-screen-popup";
import { Divider } from "antd-mobile";
import gaEvents, {
  ACTION_CONFIG,
  CATEGORY_CONFIG,
  LABEL_CONFIG,
} from "@share/lib/ga-events";
import PersonalDomainSetting from "./PersonalDomainSetting";
import PersonalBrandSetting from "./PersonalBrandSetting";
import { SETTING_TOUR_CLASS_NAME } from "../SettingTourOpening/SettingTour/setting-tour-config";
import { useAppSelector } from "@app/hooks";
import cls from "classnames";

export const SiteSetting = () => {
  const { t } = useTranslation();
  const [isOpenDomainSetting, setIsOpenDomainSetting] = useState(false);
  const [isOpenBrandSetting, setIsOpenBrandSetting] = useState(false);
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);

  const handleOpenBrandSetting = () => {
    setIsOpenBrandSetting(true);
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.SITE_SETTINGS,
      `SiteSettings_PersonalBrand_${LABEL_CONFIG.CLICK_ON}`,
      LABEL_CONFIG.CLICK_ON,
      ACTION_CONFIG.PERSONAL_BRAND
    );
  };

  const handleOpenDomainSetting = () => {
    setIsOpenDomainSetting(true);
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.SITE_SETTINGS,
      `SiteSettings_Domain_${LABEL_CONFIG.CLICK_ON}`,
      LABEL_CONFIG.CLICK_ON,
      ACTION_CONFIG.DOMAIN
    );
  };

  const handleCancelPersonalBrandSetting = (data) => {
    setIsOpenBrandSetting(false);
  }

  return (
    <div
      className={cls(
        "p-3 bg-[#fff] rounded-[4px] mb-3",
        SETTING_TOUR_CLASS_NAME.STEP_3
      )}
    >
      <div
        style={{
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "130%",
          color: "#666666",
        }}
      >
        {t("ss_builder_setup_site_setting")}
      </div>
      <div style={{ margin: "16px 0px 12px" }}>
        <div
          className="flex"
          style={{ cursor: "pointer" }}
          onClick={handleOpenBrandSetting}
        >
          <AwardIconV2 />
          <span
            className=" my-auto lead-2"
            style={{
              marginLeft: "12px",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "131%",
              display: "flex",
              alignItems: "center",
              color: "#333333",
            }}
          >
            {t("ss_builder_site_brand_setting_label")}
          </span>
        </div>
        <Divider style={{ margin: "8px 0px 8px 56px" }} />
        <div
          className="flex"
          style={{ cursor: "pointer" }}
          onClick={handleOpenDomainSetting}
        >
          <SiteIconV2 />
          <span
            className=" my-auto lead-2"
            style={{
              marginLeft: "12px",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "131%",
              display: "flex",
              alignItems: "center",
              color: "#333333",
            }}
          >
            {t("ss_builder_site_domain_setting_label")}
          </span>
        </div>
      </div>
      <FullScreenPopup
        visible={isOpenDomainSetting}
        onMaskClick={() => {
          setIsOpenDomainSetting(false);
        }}
      >
        <PersonalDomainSetting
          onCancelPersonalDomainSetting={() => setIsOpenDomainSetting(false)}
        />
      </FullScreenPopup>
      <FullScreenPopup
        visible={isOpenBrandSetting}
        onMaskClick={() => {
          setIsOpenBrandSetting(false);
        }}
      >
        <PersonalBrandSetting
          onCancelPersonalBrandSetting={handleCancelPersonalBrandSetting}
        />
      </FullScreenPopup>
    </div>
  );
};
export default SiteSetting;
