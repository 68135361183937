export const FontGoogle = ({ className = "", width = 13, height = 13 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 13 13"
      className={className}
    >
      <g clipPath="url(#clip0_910_743673)">
        <path
          fill="#fff"
          fillOpacity="0.01"
          d="M0 0H13V13H0z"
        ></path>
        <path
          fill="#FBBC04"
          d="M.443 11.14l5.85-9.28h3.632v1.29l-5.044 7.99"
        ></path>
        <path
          fill="#1A73E8"
          d="M9.925 11.139H6.294v-9.28h3.631v9.28z"
        ></path>
        <path
          fill="#34A853"
          d="M12.548 8.513a2.618 2.618 0 01-2.622 2.623V5.89a2.617 2.617 0 012.622 2.622z"
        ></path>
        <path
          fill="#0D652D"
          d="M9.925 11.136a2.617 2.617 0 01-2.622-2.623 2.618 2.618 0 012.622-2.622v5.245z"
        ></path>
        <path
          fill="#1A73E8"
          d="M11.943 3.877c0 1.13-.888 2.017-2.017 2.017V1.859c1.13 0 2.017.888 2.017 2.018z"
        ></path>
        <path
          fill="#174EA6"
          d="M9.926 5.894a1.998 1.998 0 01-2.018-2.017c0-1.13.888-2.018 2.018-2.018v4.035z"
        ></path>
        <path
          fill="#EA4335"
          d="M.646 3.675c0-1.009.806-1.816 1.815-1.816 1.008 0 1.815.807 1.815 1.816a1.808 1.808 0 01-1.815 1.816A1.808 1.808 0 01.646 3.675z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_910_743673">
          <path
            fill="#fff"
            d="M0 0H13V13H0z"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};
