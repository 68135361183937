import cls from "classnames";
import React from "react";
import { Divider } from "antd-mobile";

interface BlockGroupWrapperProps {
  labelText: string;
  icon: React.ReactNode;
}
const BlockGroupWrapper: React.FunctionComponent<BlockGroupWrapperProps> = (
  props
) => {
  const { labelText, icon } = props;
  return (
    <>
      <div
        className="cursor-pointer relative"
        style={{
          paddingTop: "12px",
          paddingBottom: "12px",
          border: "2px solid #00B578",
          borderRadius: "4px",
          marginLeft: "12px",
          marginRight: "12px",
        }}
      >
        <Divider
          contentPosition="left"
          className={cls("divider-design adm-default", "expand-block-title")}
        >
          <span className="flex justify-center items-center">
            {labelText}
            {icon}
          </span>
        </Divider>
        {props.children}
      </div>
    </>
  );
};

export default BlockGroupWrapper;
