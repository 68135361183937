import React, { useMemo, useEffect } from "react";
import ButtonBgColor from "./button";
import GeneralColor from "./generator";
import TextColor from "./text";
import HeadingColor from "./heading";

// const enum BackgroundTypeEnum {
//   COLOR = "color",
//   GRADIENT = "gradient",
//   IMAGE = "image",
// }

export default function GenerateColor({ displaySetting }) {
  const {
    primary_color_generate = {},
    secondary_color_generate = {},
    heading_color_generate = {},
    button_setting = {},
    text_color_generate = {},
  } = displaySetting;

  return (
    <>
      <GeneralColor
        generatorSetting={{ primary_color_generate, secondary_color_generate }}
      />
      <HeadingColor headingColorGenerate={heading_color_generate} />
      <TextColor textColorGenerate={text_color_generate} />
      <ButtonBgColor buttonSetting={button_setting} />
    </>
  );
}
