import { BLOCK_SETTING_DEFAULT_VALUE } from "@share/configs/const";
import React, { useEffect } from "react";

const enum BlockRadiusTypeEnum {
  SQUARE = "square",
  ROUNDED = "rounded",
  CUSTOM = "custom",
}

const BlockStyle = ({ blockSetting }) => {
  useEffect(() => {
    const { block_style, block_background, block_border, block_shadow } =
      blockSetting || {};
    const {
      radius_type,
      radius_value,
      inner_space,
      inner_space_number,
      outside_space,
    } = block_style || {};
    
    const {
      color: backgroundColor = BLOCK_SETTING_DEFAULT_VALUE.BACKGROUND_COLOR,
      blur,
    } = block_background || {};

    const block_tab_background = backgroundColor
      ? `${backgroundColor.toString().substring(0, 7)}ff`
      : BLOCK_SETTING_DEFAULT_VALUE.TAB_BACKGROUND_COLOR;

    const block_background_drag_drop = backgroundColor
      ? `${backgroundColor.toString().substring(0, 7)}BF`
      : BLOCK_SETTING_DEFAULT_VALUE.BACKGROUND_COLOR;

    const {
      enable: enableBorder,
      color: borderColor,
      type: typeBorder,
      border_width,
    } = block_border || {};
    const { enable: enableShadow, color: shadowColor } = block_shadow || {};
    //Block border radius
    if (radius_type && radius_type === BlockRadiusTypeEnum.SQUARE) {
      document.documentElement.style.setProperty(
        "--block-border-radius",
        "0px"
      );
    }
    if (radius_type && radius_type === BlockRadiusTypeEnum.ROUNDED) {
      document.documentElement.style.setProperty(
        "--block-border-radius",
        "8px"
      );
    }
    if (radius_type && radius_type === BlockRadiusTypeEnum.CUSTOM) {
      document.documentElement.style.setProperty(
        "--block-border-radius",
        radius_value && typeof radius_value === "number"
          ? `${radius_value}px`
          : `${BLOCK_SETTING_DEFAULT_VALUE}px`
      );
    }
    //Block inner space
    if (typeof inner_space_number === "number") {
      document.documentElement.style.setProperty(
        "--block-inner-space",
        `${inner_space_number}px`
      );
    } else {
      if (typeof inner_space === "boolean") {
        document.documentElement.style.setProperty(
          "--block-inner-space",
          inner_space ? `${BLOCK_SETTING_DEFAULT_VALUE.INNER_SPACE_NUMBER}px` : "0px"
        );
      } else {
        document.documentElement.style.setProperty(
          "--block-inner-space",
          `${BLOCK_SETTING_DEFAULT_VALUE.INNER_SPACE_NUMBER}px`
        );
      }
    }
    if (outside_space || typeof outside_space === "undefined") {
      document.documentElement.style.setProperty(
        "--block-outside-space",
        `12px`
      );
    } else {
      document.documentElement.style.setProperty(
        "--block-outside-space",
        "0px"
      );
    }
    //Block background color
    document.documentElement.style.setProperty(
      "--block-background-color",
      backgroundColor
    );
    //Block Tab background color
    document.documentElement.style.setProperty(
      "--block-tab-background-color",
      block_tab_background
    );
    //background color when drag drop
    document.documentElement.style.setProperty(
      "--block-background-color-drag-drop",
      block_background_drag_drop
    );
    //Block background blur
    document.documentElement.style.setProperty(
      "--block-background-blur",
      blur && typeof blur === "number"
        ? `${blur}px`
        : `${BLOCK_SETTING_DEFAULT_VALUE.BACKGROUND_BLUR}px`
    );

    // Block border
    if (enableBorder) {
      //Block border color
      document.documentElement.style.setProperty(
        "--block-border-color",
        borderColor ?? BLOCK_SETTING_DEFAULT_VALUE.BORDER_COLOR
      );
      //Block border width
      document.documentElement.style.setProperty(
        "--block-border-width",
        border_width && typeof border_width === "number"
          ? `${border_width}px`
          : `${BLOCK_SETTING_DEFAULT_VALUE.BORDER_WIDTH}px`
      );
      //Block border style
      document.documentElement.style.setProperty(
        "--block-border-style",
        typeBorder ?? BLOCK_SETTING_DEFAULT_VALUE.BORDER_STYLE
      );
    } else {
      document.documentElement.style.setProperty("--block-border-width", "0px");
    }
    if (enableShadow) {
      //Block shadow
      document.documentElement.style.setProperty(
        "--block-box-shadow",
        shadowColor
          ? `0px 4px 4px ${shadowColor}`
          : `0px 4px 4px ${BLOCK_SETTING_DEFAULT_VALUE.SHADOW_COLOR}`
      );
    } else {
      document.documentElement.style.setProperty("--block-box-shadow", "none");
    }
    // First we get the viewport height and multiply it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    // We listen to the resize event
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }, [blockSetting]);
  return null;
};

export default BlockStyle;
