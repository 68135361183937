import {
  InformationIcon,
  InformationTriangleIcon,
  TrashIcon,
} from "@share/icons";
import { mockUpload } from "@share/lib";
import {
  Button,
  Divider,
  Image,
  ImageUploader as Uploader,
  Modal,
} from "antd-mobile";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ImageUploaderProps {
  defaultImage?: string;
  onChangeImage?: (image) => void;
}
export default function ImageUploader(props: ImageUploaderProps) {
  const { defaultImage, onChangeImage } = props;
  const { t } = useTranslation();
  const [image, setImage] = useState(defaultImage);
  useEffect(() => {
    setImage(defaultImage);
  }, [defaultImage]);
  const [isValidFileFormat, setIsValidFileFormat] = useState(false);
  const [isValidFileSize, setIsValidFileSize] = useState(false);
  const onUploadImage = (data: any[]) => {
    const uploadedImage = data[data.length - 1];
    if (uploadedImage?.isValidFormat) {
      setIsValidFileFormat(true);
      setIsValidFileSize(false);
    } else if (uploadedImage?.isValidSize) {
      setIsValidFileFormat(false);
      setIsValidFileSize(true);
    } else {
      setImage(uploadedImage.url);
      onChangeImage(uploadedImage);
    }
  };
  return (
    <div>
      <div className="text-base mb-1">
        {t("ss_builder_theme_setting_background_image_preview_label")}
      </div>
      <div className="grid grid-cols-[120px_auto] gap-4">
        <Image
          src={
            image || "https://i.ecomobi.com/ssp/passio-page/image-default.png"
          }
          // width={120}
          // height={120}
          fit="cover"
          style={{
            borderRadius: "8px",
            width: "120px",
            height: "120px",
            objectFit: "cover",
          }}
        />
        <div className="flex flex-col justify-between">
          <div className="leading-4">
            {t("ss_builder_theme_setting_background_content")}
          </div>
          <div className="flex gap-2">
            {/* <Button
                        size="middle"
                        color={image ? "primary" : "default"}
                        fill="solid"
                        className=""
                        onClick={() => setImage(null)}
                    >
                        <TrashIcon
                            className="w-[17px] h-[17px]"
                            fillColor={image ? "#FFFFFF" : "#CCCCCC"}
                        />
                    </Button> */}
            <Uploader
              onChange={onUploadImage}
              upload={(file) => mockUpload(file, 2000, 2000)}
              className="flex-grow"
              // value={uploadedImageArr}
              // disableUpload={isDisableUploadBtn}
              children={
                <Button
                  color="primary"
                  fill="solid"
                  size="middle"
                  className="w-full"
                  //   onClick={handleSaveBlockText}
                  // disabled={isDisableUploadBtn}
                >
                  {t("ss_builder_upload_button_label")}
                </Button>
              }
            />
          </div>
        </div>
      </div>
      {isValidFileFormat && (
        <div className="flex flex-row gap-1 items-center mt-1">
          <InformationTriangleIcon
            fillColor="#FF3141"
            className="w-4 h-4"
          />
          <div className="text-[#FF3141]">
            {t("ss_builder_upload_file_format_invalid_content")}
          </div>
        </div>
      )}
      {isValidFileSize && (
        <div className="flex flex-row gap-1 items-center mt-1">
          <InformationTriangleIcon
            fillColor="#FF3141"
            className="w-4 h-4"
          />
          <div className="text-[#FF3141]">
            {t("ss_builder_upload_file_size_invalid_content")}
          </div>
        </div>
      )}
    </div>
  );
}
