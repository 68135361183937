import {
  GG_FONT_URI,
  PARAGRAPH_SETTING_DEFAULT_VALUE,
  TITLE_SETTING_DEFAULT_VALUE,
} from "@share/configs/const";
import startCase from "lodash/startCase";
import { useEffect, useMemo } from "react";

export const useGetFontValue = (paragraphSetting, titleSetting) => {
  //Thực hiện xóa link font nếu có bất kì link nào tồn tại trong Head
  useEffect(() => {
    const existingLinks = document.querySelectorAll(
      `link[href^="${GG_FONT_URI}?family="]`
    );
    existingLinks.forEach((link) => link.parentNode.removeChild(link));
  }, [paragraphSetting, titleSetting]);

  //Tạo link font mới
  const fontValue = useMemo(() => {
    // paragraph
    const paragraph_using_font_custom =
      paragraphSetting?.using_font_custom ||
      PARAGRAPH_SETTING_DEFAULT_VALUE.USING_FONT_CUSTOM;
    const paragraph_font_family_custom =
      paragraphSetting?.font_family_custom ||
      PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_FAMILY_CUSTOM;
    const paragraph_font_family =
      paragraphSetting?.font_family ||
      PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_FAMILY;
    const paragraphFontFamily = (
      paragraph_using_font_custom ===
      PARAGRAPH_SETTING_DEFAULT_VALUE.USING_FONT_CUSTOM
        ? startCase(paragraph_font_family)
        : startCase(paragraph_font_family_custom.toString().trim())
    ).replace(/\s+/g, "+");
    const paragraphWeight =
      paragraphSetting?.font_weight ||
      PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_WEIGHT;
    const isNewParagraph = paragraphSetting?.is_new || false;

    //title
    const title_using_font_custom =
      titleSetting?.using_font_custom ||
      TITLE_SETTING_DEFAULT_VALUE.USING_FONT_CUSTOM;
    const title_font_family_custom =
      titleSetting?.font_family_custom ||
      TITLE_SETTING_DEFAULT_VALUE.FONT_FAMILY_CUSTOM;
    const title_font_family =
      titleSetting?.font_family || TITLE_SETTING_DEFAULT_VALUE.FONT_FAMILY;
    const titleFontFamily = (
      title_using_font_custom === TITLE_SETTING_DEFAULT_VALUE.USING_FONT_CUSTOM
        ? startCase(title_font_family)
        : startCase(title_font_family_custom.toString().trim())
    ).replace(/\s+/g, "+");
    const titleWeight =
      titleSetting?.font_weight || TITLE_SETTING_DEFAULT_VALUE.FONT_WEIGHT;
    const isNewTitle = titleSetting?.is_new || false;

    let titleFont = isNewTitle
      ? `${titleFontFamily}:${titleWeight}`
      : titleFontFamily;
    let paragraphFont = isNewParagraph
      ? `${paragraphFontFamily}:${paragraphWeight}`
      : paragraphFontFamily;

    let value = `${titleFont}|${paragraphFont}`;
    if (titleFontFamily === paragraphFontFamily) {
      if (Number(paragraphWeight) === Number(titleWeight)) {
        value = titleFont;
      } else {
        const firstWeight = Number(titleWeight);
        const secondWeight = Number(paragraphWeight);
        const [fontFirst, fontSecond] =
          firstWeight > secondWeight
            ? [titleWeight, paragraphWeight]
            : [paragraphWeight, titleWeight];
        value = `${titleFontFamily}:${fontFirst},${fontSecond}`;
      }
    }
    return value;
  }, [paragraphSetting, titleSetting]);

  return {
    fontValue,
  };
};
