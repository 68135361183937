import { CSSProperties } from "react";
import classnames from "classnames";

type ButtonProps = {
  isActive?: boolean;
  icon?: React.ReactNode;
  label: string;
  onClick?: () => void;
  extra?: React.ReactNode;
  style?: CSSProperties;
  width?: number;
};

const ButtonViewTheme = ({
  isActive = false,
  icon,
  onClick = () => null,
  extra,
  label,
  style,
}: ButtonProps) => {

  const handleClick = () => {
    onClick();
  }
  
  return (
    <>
      <div 
        className={classnames("flex items-center h-[32px] bg-[#F5F5F5] cursor-pointer rounded-full", {
          "bg-[#EE3244]" : isActive,
          "bg-[#F5F5F5]" : !isActive,
          "text-white": isActive,
          "text-[#333333]": !isActive,
        })}
        onClick={handleClick}
        style={{...style}}
      >
        <div className="flex items-center pl-2">
          <div className="pr-1">{icon}</div>
          <div className="text-[15px] leading-5">{label}</div>
        </div>
        {extra}
      </div>
    </>
  ) 
}

export default ButtonViewTheme;