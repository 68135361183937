import { BackArrow } from "@features/setting/components/Icons";
import { GlobalIcon, PreviewThemeIcon } from "@share/icons";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import gaEvents, { CATEGORY_CONFIG, ACTION_CONFIG } from "@share/lib/ga-events";
import {useSelector} from "react-redux";
import {selectProfile} from "@features/user/userSlice";
import {useAppSelector} from "@app/hooks";

const Preview = (props) => {
  const { user, onCancelPreview, siteSettings } = props;
  const { t } = useTranslation();

  const previewUrl = useMemo(() => {
    const domain = user?.kol?.domain;
    const siteSettingId = siteSettings.id;
    return `https://${domain}/preview?id=${siteSettingId}&isDisableLink=true`;
  }, [user, siteSettings]);

  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);

  const handleClickToLink = () => {
    const castWindow = window as any;
    const domain = user?.kol?.domain;
    const siteSettingId = siteSettings.id;
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.PREVIEW_SITE,
      "PreviewSiteInBrowser",
      domain,
      ACTION_CONFIG.PREVIEW_SITE_IN_BROWSER
    );
    if (castWindow.flutter_inappwebview !== undefined) {
      var args = [{ url: `https://${domain}/preview?id=${siteSettingId}` }];
      castWindow.flutter_inappwebview.callHandler("openInBrowser", ...args);
    } else {
      window.open(`https://${domain}/preview?id=${siteSettingId}`, "_blank");
    }
  };

  return (
    <>
      <div
        className="bg-white"
        style={{
          borderBottom: "1px solid rgb(238, 238, 238)",
        }}
      >
        <div className="max-width-content-class m-auto text-lg font-normal flex text-center items-center gap-1 p-3 relative">
          <span
            onClick={() => onCancelPreview()}
            className="cursor-pointer"
          >
            <BackArrow />
          </span>
          <span className="flex-1 text-center">
            <span className="flex items-center justify-center gap-1">
              <PreviewThemeIcon className="w-5 h-5" />
              <div className="text-[18px] leading-6">
                {t("ss_builder_theme_template_preview_mode_title")}
              </div>
            </span>
          </span>
          <span
            className="absolute right-2 top-2 cursor-pointer p-1"
            onClick={() => handleClickToLink()}
            title={t("ss_builder_browser_opening_label")}
          >
            <GlobalIcon />
          </span>
        </div>
      </div>
      <div
        className=""
        style={{
          height: "calc(100% - 50px)",
        }}
      >
        <iframe
          id="theme-preview-iframe"
          src={previewUrl}
          className="h-full w-full"
        />
      </div>
    </>
  );
};

export default Preview;
