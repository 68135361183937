import { useAppDispatch, useAppSelector } from "@app/hooks";
import { FONT_OPTIONS } from "@share/configs/const";
import { dataService } from "@share/configs/rxjs";
import { CheckList, SearchBar } from "antd-mobile";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cls from "classnames";
import { setDynamicParams } from "@features/main/store/dynamicSlice";
import ButtonConfirmPopup from "@share/components/ButtonConfirmPopup";

type Props = {
  callbackCancelFontOption: any;
};

const FontOption = (props: Props) => {
  const { callbackCancelFontOption } = props;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const siteSettings = useAppSelector<any>((state) => state.siteSettings);
  const displaySettings =
    siteSettings &&
    Object.values(siteSettings).length > 0 &&
    siteSettings.display_setting;
  const { font_family } = displaySettings;

  const [selectedFont, setSelectedFont] = useState("");

  const handleCancleFontOption = () => {
    // dataService.publish({
    //   isOpenFontOption: false,
    // });
    callbackCancelFontOption();
  };

  const handleSelectFontFamily = () => {
    handleCancleFontOption();
    dataService.publish({
      fontFamily: selectedFont,
    });
  };

  return (
    <div>
      <div className="panel-header h-[40px] px-[8px] py-[6px] flex flex-row items-center text-center">
        <span
          className="grow-0 text-specialColorDefault p-1 cursor-pointer"
          onClick={handleCancleFontOption}
        >
          {t("ss_builder_close_panel")}
        </span>
        <span className="grow p-1 text-[15px]">
          {t("ss_builder_font_option_panel_title")}
        </span>
        <span
          className={cls(
            "grow-0 p-1 cursor-pointer",
            selectedFont ? "text-specialColorDefault" : "text-[#8C8C8C]"
          )}
          onClick={selectedFont ? handleSelectFontFamily : () => {}}
        >
          {t("ss_builder_confirm_panel")}
        </span>
      </div>
      <SearchBar
        placeholder={t("ss_builder_panel_search_label")}
        className="pt-0 pb-3 px-3"
        // onFocus={onFocus}
        style={{
          borderColor: "red",
        }}
      />
      <CheckList
        value={[selectedFont]}
        style={{
          overflowY: "scroll",
          height: "500px",
        }}
        onChange={(val) => setSelectedFont(val[0])}
      >
        {FONT_OPTIONS.map((ele, index) => (
          <CheckList.Item
            value={ele.value}
            key={index}
          >
            {ele.label}
          </CheckList.Item>
        ))}
      </CheckList>
    </div>
  );
};

export function FontOptionV2(props: {
  selectedValue: string;
  callbackCancelFontOption: () => void;
  onSelectFont: (font) => void;
}) {
  const { callbackCancelFontOption, onSelectFont, selectedValue } = props;
  const { t } = useTranslation();

  const [selectedFont, setSelectedFont] = useState(selectedValue);

  const handleCancelFontOption = () => {
    callbackCancelFontOption();
  };

  const handleSelectFontFamily = () => {
    onSelectFont(selectedFont);
    handleCancelFontOption();
  };

  useEffect(() => {
    const selector = selectedFont.replaceAll(" ", "-");
    const timer = setTimeout(() => {
      const element = document.getElementsByClassName(selector) as any;
      if (element[0]) {
        element[0].scrollIntoView();
      }
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="h-full">
      {/* <SearchBar
        placeholder={t("ss_builder_panel_search_label")}
        className="pt-0 pb-3 px-3"
        // onFocus={onFocus}
        style={{
          borderColor: "red",
        }}
      /> */}
      <CheckList
        value={[selectedFont]}
        style={{
          overflowY: "scroll",
          height: "500px",
        }}
        onChange={(val) => (val[0] ? setSelectedFont(val[0]) : {})}
      >
        {FONT_OPTIONS.map((ele, index) => (
          <CheckList.Item
            style={{ fontFamily: ele.value }}
            value={ele.value}
            key={index}
            className={ele.value.replaceAll(" ", "-")}
          >
            {ele.label}
          </CheckList.Item>
        ))}
      </CheckList>
      <ButtonConfirmPopup onConfirm={handleSelectFontFamily} />
    </div>
  );
}

export default FontOption;
