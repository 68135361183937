import { useAppSelector } from "@app/hooks";
import { BLOCK_SETTING_DEFAULT_VALUE } from "@share/configs/const";
import { Divider } from "antd-mobile";
import React from "react";

const BlockDraggingSpace = ({ addNewBlockButtonDrag, children }) => {
  const block_style = useAppSelector(
    (state) => state.siteSettings?.display_setting?.block_setting?.block_style
  );
  const {
    block_space = BLOCK_SETTING_DEFAULT_VALUE.BLOCK_SPACE,
    outside_space,
  } = block_style ?? {};

  const Space = () => {
    return addNewBlockButtonDrag ? (
      <Divider
        style={{
          height: `${block_space / 2}px`,
          margin: "0px",
          visibility: addNewBlockButtonDrag ? "hidden" : "unset",
          opacity: addNewBlockButtonDrag ? 0 : 1,
          paddingLeft: outside_space
            ? `${BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER}px`
            : "",
          paddingRight: outside_space
            ? `${BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER}px`
            : "",
        }}
      />
    ) : (
      <></>
    );
  };

  return (
    <>
      <Space />
      {children}
      <Space />
    </>
  );
};

export default BlockDraggingSpace;
