import {
  BIG_BUTTON_SETTING_DEFAULT_VALUE,
  BUTTON_SETTING_DEFAULT_VALUE,
  BUTTON_SETTING_SHADOW_STYLE,
  MEDIUM_BUTTON_SETTING_DEFAULT_VALUE,
  SMALL_BUTTON_SETTING_DEFAULT_VALUE,
} from "@share/configs/const";
import React, { useEffect } from "react";

const enum ButtonRadiusTypeEnum {
  SQUARE = "square",
  ROUNDED = "rounded",
  CUSTOM = "custom",
}

const ButtonStyle = ({ buttonSetting }) => {
  useEffect(() => {
    const {
      border_color,
      background_color,
      border_width,
      text_color,
      radius_type,
      radius_value,
      enable_border,
      shadow_type = BUTTON_SETTING_SHADOW_STYLE.NO_SHADOW,
      shadow_color,
    } = buttonSetting || {};
    const bigButtonSetting = buttonSetting?.big_button_setting;
    const mediumButtonSetting = buttonSetting?.medium_button_setting;
    const smallButtonSetting = buttonSetting?.small_button_setting;
    const newShadowColor =
      shadow_color ??
      (shadow_type === BUTTON_SETTING_SHADOW_STYLE.SOFT_SHADOW
        ? BUTTON_SETTING_DEFAULT_VALUE.SOFT_SHADOW_COLOR
        : BUTTON_SETTING_DEFAULT_VALUE.HARD_SHADOW_COLOR);

    const {
      font_size: medium_button_font_size,
      line_height: medium_button_line_height,
      font_weight: medium_button_font_weight,
    } = mediumButtonSetting || {};

    if (enable_border) {
      //Button border color
      document.documentElement.style.setProperty(
        "--button-border-color",
        border_color ?? BUTTON_SETTING_DEFAULT_VALUE.BORDER_COLOR
      );
      //Button border width
      if (typeof border_width === "number") {
        document.documentElement.style.setProperty(
          "--button-border-width",
          border_width && typeof border_width === "number"
            ? `${border_width}px`
            : `${BUTTON_SETTING_DEFAULT_VALUE.BORDER_WIDTH}px`
        );
      }
    } else {
      document.documentElement.style.setProperty(
        "--button-border-width",
        "0px"
      );
    }
    //Button background color
    document.documentElement.style.setProperty(
      "--button-background-color",
      background_color ?? BUTTON_SETTING_DEFAULT_VALUE.BACKGROUND_COLOR
    );
    //Button text color
    document.documentElement.style.setProperty(
      "--button-text-color",
      text_color ?? BUTTON_SETTING_DEFAULT_VALUE.TEXT_COLOR
    );
    //Button radius
    if (radius_type) {
      if (radius_type === ButtonRadiusTypeEnum.SQUARE) {
        document.documentElement.style.setProperty(
          "--button-border-radius",
          "0px"
        );
      }
      if (radius_type === ButtonRadiusTypeEnum.ROUNDED) {
        document.documentElement.style.setProperty(
          "--button-border-radius",
          "8px"
        );
      }
      if (radius_type === ButtonRadiusTypeEnum.CUSTOM) {
        document.documentElement.style.setProperty(
          "--button-border-radius",
          typeof radius_value === "number"
            ? `${radius_value}px`
            : `${BUTTON_SETTING_DEFAULT_VALUE.RADIUS_VALUE}px`
        );
      }
    }
    // Big button

    //Big button font size
    document.documentElement.style.setProperty(
      "--large-button-font-size",
      medium_button_font_size && typeof medium_button_font_size === "number"
        ? `${medium_button_font_size + 1}px`
        : `${MEDIUM_BUTTON_SETTING_DEFAULT_VALUE.FONT_SIZE}px`
    );
    //Big button font weight
    document.documentElement.style.setProperty(
      "--large-button-font-weight",
      medium_button_font_weight ??
        MEDIUM_BUTTON_SETTING_DEFAULT_VALUE.FONT_WEIGHT
    );
    //Big button line height
    document.documentElement.style.setProperty(
      "--large-button-line-height",
      medium_button_line_height ??
        MEDIUM_BUTTON_SETTING_DEFAULT_VALUE.LINE_HEIGHT
    );

    //Medium button

    //Medium button font size
    document.documentElement.style.setProperty(
      "--middle-button-font-size",
      medium_button_font_size && typeof medium_button_font_size === "number"
        ? `${medium_button_font_size}px`
        : `${MEDIUM_BUTTON_SETTING_DEFAULT_VALUE.FONT_SIZE}px`
    );

    //Medium button font weight
    document.documentElement.style.setProperty(
      "--middle-button-font-weight",
      medium_button_font_weight ??
        MEDIUM_BUTTON_SETTING_DEFAULT_VALUE.FONT_WEIGHT
    );

    //Medium button line height
    document.documentElement.style.setProperty(
      "--middle-button-line-height",
      medium_button_line_height ??
        MEDIUM_BUTTON_SETTING_DEFAULT_VALUE.LINE_HEIGHT
    );

    //Small button

    //Small button font size
    document.documentElement.style.setProperty(
      "--small-button-font-size",
      medium_button_font_size && typeof medium_button_font_size === "number"
        ? `${medium_button_font_size - 2}px`
        : `${MEDIUM_BUTTON_SETTING_DEFAULT_VALUE.FONT_SIZE}`
    );

    //Small button font weight
    document.documentElement.style.setProperty(
      "--small-button-font-weight",
      medium_button_font_weight ??
        MEDIUM_BUTTON_SETTING_DEFAULT_VALUE.FONT_WEIGHT
    );

    //Small button line height
    document.documentElement.style.setProperty(
      "--small-button-line-height",
      medium_button_line_height ??
        MEDIUM_BUTTON_SETTING_DEFAULT_VALUE.LINE_HEIGHT
    );

    //Button shadow
    if (shadow_type) {
      if (shadow_type === BUTTON_SETTING_SHADOW_STYLE.NO_SHADOW) {
        document.documentElement.style.setProperty(
          "--button-box-shadow",
          "none"
        );
      }
      if (shadow_type === BUTTON_SETTING_SHADOW_STYLE.SOFT_SHADOW) {
        document.documentElement.style.setProperty(
          "--button-box-shadow",
          `0px 5px 12px ${newShadowColor}`
        );
      }
      if (shadow_type === BUTTON_SETTING_SHADOW_STYLE.HARD_SHADOW) {
        document.documentElement.style.setProperty(
          "--button-box-shadow",
          `5px 5px 0px ${newShadowColor}`
        );
      }
    }
    document.documentElement.style.setProperty(
      "--button-box-soft-shadow",
      `0px 5px 12px  ${
        shadow_color ?? BUTTON_SETTING_DEFAULT_VALUE.SOFT_SHADOW_COLOR
      }`
    );

    document.documentElement.style.setProperty(
      "--button-box-hard-shadow",
      `4px 5px 0px ${
        shadow_color ?? BUTTON_SETTING_DEFAULT_VALUE.HARD_SHADOW_COLOR
      }`
    );
  }, [buttonSetting]);
  return null;
};

export default ButtonStyle;
