import React, { useMemo, useEffect } from "react";
import BackgroundStyle from "./background";
import BlockStyle from "./block";
import ButtonStyle from "./button";
import GeneratorStyle from "./generator";
import ParagraphStyle from "./paragraph";
import TitleStyle from "./title";

// const enum BackgroundTypeEnum {
//   COLOR = "color",
//   GRADIENT = "gradient",
//   IMAGE = "image",
// }

export default function ThemeStyle({ displaySetting }) {
  const {
    primary_color,
    secondary_color,
    background: backgroundSetting,
    block_setting,
    title_setting,
    button_setting,
    paragraph_setting,
  } = displaySetting;
  return (
    <>
      <GeneratorStyle generatorSetting={{ primary_color, secondary_color }} />
      <BackgroundStyle backgroundSetting={backgroundSetting} />
      <BlockStyle blockSetting={block_setting} />
      <TitleStyle titleSetting={title_setting} />
      <ParagraphStyle paragraphSetting={paragraph_setting} />
      <ButtonStyle buttonSetting={button_setting} />
    </>
  );
}
