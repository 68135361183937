import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";
import {selectProfile} from "@features/user/userSlice";
import {useAppSelector} from "@app/hooks";
import { Button, Toast } from "antd-mobile";
import { IconDocument } from "@features/setting/components/Icons";
import { formatNumberToCurrency, htmlDecode } from "@share/lib";
import { CheckCircleIcon } from "@share/icons";
import { countryCurrencyMap } from "@share/configs/const";
import moment from "moment";
import { updateCampaignStatus } from "@share/api";
import FullScreenPopup from "@share/components/full-screen-popup";
import RejectAccess from "../Reject";
import Cookies from "js-cookie";
import { KEY_TOKEN } from "@share/configs/system";

export default function DetailsCampaign(props) {
  const {details, setShowDetails, setShowUploadImage} = props
  const { t } = useTranslation();
  const profile = useSelector(selectProfile);
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);
  const customerToken = Cookies.get(KEY_TOKEN)

  const [showReject, setShowReject] = useState(false)

  const titleCss = 'font-normal text-[15.6px] text-[#333333]'
  const contentCss = 'font-normal text-[13px] text-[#8C8C8C]'
  const buttonCss ='adm-button adm-button-shape-default w-full  w-1/2 text-center'

  const handleJoin = async () => {
    await updateCampaignStatus({cpc_campaign_id: details.id})
    .then(() => {
      Toast.show({
        icon: (
          <CheckCircleIcon
            className="text-center inline-block w-[36px] h-[36px]"
            fillColor="#00B578"
          />
        ),
        content: <div className="text-center">{t("ss_brand_cpc_join_successfully_label")}</div>,
      });
      setTimeout(() => {
        setShowDetails(false)
        setShowUploadImage(true)
      }, 1500)
    })
  }
  if (!details || !customerToken) setShowReject(true)
  return (
    <div>
      <div className="w-full h-full pb-[69px]">
        <div className="py-3 px-3 bg-white pb-2 flex" style={{boxShadow: "inset 0px -1px 0px #EEEEEE",}}>
          <span className="m-auto text-center items-center text-lg font-normal flex">
            <IconDocument className="inline-block mr-2" />
            <span className="max-h-[28px] max-w-[300px] block whitespace-nowrap overflow-hidden text-ellipsis line-clamp-1">
              {t("ss_brand_cpc_campaign_title")} {details.title}
            </span>
          </span>
        </div>

        <div className="p-3 flex flex-col gap-3 bg-white">
          <img src={details.creator_banner_image} className="rounded-lg" style={{aspectRatio: '3/2'}}/>
          <div className="flex flex-col gap-1">
            <div className={titleCss}>{t('ss_brand_cpc_bonus')}</div>
            <div className={`bg-[#FFCCC7] text-[#EE3244] text-[13px] font-medium rounded px-2 py-1 w-fit`}>
              {formatNumberToCurrency(details.bonus)} {countryCurrencyMap[passioPageProfile.country]}
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div className={titleCss}>{t('ss_brand_cpc_notice')}</div>
            <div className={contentCss}>{t('ss_brand_cpc_notice_content')}</div>
          </div>
          <div className="flex flex-col gap-1">
            <div className={titleCss}>{t('ss_brand_cpc_description')}</div>
            <div className={contentCss}>{htmlDecode(details.description)}</div>
          </div>
          {details.creator_start_date || details.creator_end_date 
            ? <div className="flex flex-col gap-1">
                <div className={titleCss}>
                  {(details.creator_start_date && details.creator_end_date)
                    ? t('ss_brand_cpc_time')
                    : (details.creator_start_date && !details.creator_end_date) ? t('ss_brand_cpc_time_start') : t('ss_brand_cpc_time_end')
                  }
                </div>
                <div className={contentCss}>
                  {(details.creator_start_date && details.creator_end_date)
                    ? `${moment(details.creator_start_date).format('DD/MM/YYYY')} - ${moment(details.creator_end_date).format('DD/MM/YYYY')}`
                    : (details.creator_start_date && !details.creator_end_date) ? moment(details.creator_start_date).format('DD/MM/YYYY') : moment(details.creator_end_date).format('DD/MM/YYYY')
                  }
                </div>
              </div>
            : <></>
          }
          <div className="flex flex-col gap-1">
            <div className={titleCss}>{t('ss_brand_cpc_requirement')}</div>
            <div className={contentCss}>{htmlDecode(details.requirement)}</div>
          </div>
        </div>
      </div>
      
      <div className="max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[10] bg-[#FFFFFF]">
          <Button className={`${buttonCss} adm-button-default`} onClick={() => setShowDetails(false)}>
            {t("ss_builder_close_panel")}
          </Button>
          <Button color="primary" className={`${buttonCss} adm-button-primary`} onClick={handleJoin}>
            {t("ss_brand_cpc_join")}
          </Button>
      </div>
      <FullScreenPopup visible={showReject} onMaskClick={() => setShowReject(false)}>
        <RejectAccess
            visible={showReject}
            setShowUploadImage={setShowReject}
            details={details}
          />
      </FullScreenPopup>
    </div>
  )
}