import { useAppSelector } from "@app/hooks";
import { StarIcon } from "@share/icons/line-icon";
import { Button, Rate } from "antd-mobile";
import cls from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PopupComponent from "@share/components/PopupComponent";
import RatingStarComponent from "@share/components/RatingStarComponent";
import gaEvents, { CATEGORY_CONFIG, EVENT_CONFIG, ACTION_CONFIG } from "@share/lib/ga-events";

export const RatingStar = () => {
  const dynamicConfig = useAppSelector((state) => state.dynamicConfig);
  const isMarginSettingTour = dynamicConfig?.isMarginSettingTour || false;
  const { t } = useTranslation();
  const [isOpenPopupRating, setIsOpenPopupRating] = useState(false);
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);

  const handleOpenPopupRating = (value) => {
    setIsOpenPopupRating(value);
    if (value) {
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.SETTINGS,
        EVENT_CONFIG.CLICK_REVIEW_BUTTON,
        '',
        ACTION_CONFIG.CLICK_REVIEW_BUTTON
      )    
    } else {
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.CREATOR_SEND_REVIEW,
        EVENT_CONFIG.CREATOR_SEND_REVIEW_CLOSE_REVIEW_FORM,
        '',
        ACTION_CONFIG.CLOSE_REVIEW_FORM
      )    
    }
  };

  return (
    <div
      className={cls(
        "p-3 bg-[#fff] rounded-[4px]",
        isMarginSettingTour ? "mb-[300px]" : ""
      )}
    >
      <div className="mb-2 text-[15.6px] leading-[130%]">
        {t("ss_builder_block_rating_title")}
      </div>
      <div className="text-[#8C8C8C] text-[12px] leading-[16px] mb-4">
        {t("ss_builder_block_rating_description")}
      </div>
      <div>
        <Button
          className="btn-flex"
          onClick={() => handleOpenPopupRating(true)}
        >
          <StarIcon
            width={17}
            height={17}
          ></StarIcon>
          <span className="text-[17px] leading-[1.4]">
            {t("ss_builder_block_rating_button_title_open_popup")}
          </span>
        </Button>
      </div>
      <PopupComponent
        visible={isOpenPopupRating}
        onMaskClick={() => handleOpenPopupRating(false)}
        title={t("ss_builder_block_rating_title")}
        onClose={() => handleOpenPopupRating(false)}
        classNameBody="max-h-[60vh] h-[60vh] popup-hard"
      >
        <RatingStarComponent onClose={() => handleOpenPopupRating(false)} />
      </PopupComponent>
    </div>
  );
};

export default RatingStar;
