import {
  FONT_GOOGLE,
  PARAGRAPH_SETTING_DEFAULT_VALUE,
} from "@share/configs/const";
import React, { useEffect } from "react";

const ParagraphStyle = ({ paragraphSetting }) => {
  useEffect(() => {
    const {
      font_family: fontFamily,
      font_color: paragraphColor,
      font_size: paragraphFontSize,
      scale: paragraphScale,
      font_weight: paragraphFontWeight,
      line_height: paragraphLineHeight,
      using_font_custom: usingFontCustom,
      font_family_custom: fontFamilyCustom,
    } = paragraphSetting || {};
    //Paragraph font family
    let paragraphFontFamily;
    if (!!usingFontCustom && usingFontCustom === FONT_GOOGLE) {
      paragraphFontFamily =
        fontFamilyCustom?.toString().trim() ||
        PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_FAMILY_CUSTOM;
    } else {
      paragraphFontFamily =
        fontFamily || PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_FAMILY;
    }
    document.documentElement.style.setProperty(
      "--paragraph-font-family",
      paragraphFontFamily ?? PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_FAMILY
    );
    //Paragraph font color
    document.documentElement.style.setProperty(
      "--paragraph-font-color",
      paragraphColor ?? PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_COLOR
    );
    //Paragraph font size
    document.documentElement.style.setProperty(
      "--paragraph-font-size",
      paragraphFontSize && typeof paragraphFontSize === "number"
        ? `${paragraphFontSize}px`
        : `${PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_SIZE}px`
    );
    //Paragraph font weight
    document.documentElement.style.setProperty(
      "--paragraph-font-weight",
      paragraphFontWeight ?? PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_WEIGHT
    );
    //Paragraph line height
    document.documentElement.style.setProperty(
      "--paragraph-line-height",
      paragraphLineHeight ?? PARAGRAPH_SETTING_DEFAULT_VALUE.LINE_HEIGHT
    );
    //Paragraph font scale
    document.documentElement.style.setProperty(
      "--paragraph-font-ratio",
      paragraphScale ?? PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_SCALE
    );
  }, [paragraphSetting]);
  return null;
};

export default ParagraphStyle;
