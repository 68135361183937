import { useAppDispatch, useAppSelector } from "@app/hooks";
import { BLOCK_TYPE } from "@features/block/block.conf";
import {
  BLOCK_TYPE_CONFIG,
  FINISH_PRODUCT_TOUR,
  MAX_WIDTH_CONTENT_LAYOUT,
  MAX_WIDTH_OUTSIDE_ON,
  POSITION_MAIN_LAYOUT,
  TAB_BAR_INDEX,
  TAB_GHIM_STYLE,
  TAB_POSITION,
  THEME_PREVIEW_KEY,
} from "@share/configs/const";
import Portal from "@share/HOC/portal";
import {
  BackIcon,
  CheckCircleIcon,
  InformationIcon,
  MoreIcon,
  PassioIcon,
  PreviewThemeIcon,
  PubLishIcon,
  QuestionIcon,
  PlaceHolderImageIcon,
  ClosePopupIconV2,
  ArrowRightIcon,
} from "@share/icons";
import { publishSite, restoreTheme } from "@share/thunk";
import {
  Button,
  Dialog,
  Modal,
  Popover,
  SpinLoading,
  Toast,
} from "antd-mobile";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { Action } from "antd-mobile/es/components/popover";
import cls from "classnames";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import BlockSelectionV2 from "@features/block/components/BlockSelectionV2";
import { POSITION_TYPE } from "@features/block/config/interface";
import {
  setDynamicParams,
  setParamsTab,
} from "@features/main/store/dynamicSlice";
import { CREATOR_ROLE_ENUM } from "@features/main/store/interface";
import {
  disableEnablePosition,
  setPosition,
} from "@features/main/store/positionsSlice";
import { setSiteInfo } from "@features/main/store/siteSettingSlice";
import {
  requestGetSelectedThemeTemplate,
  requestReplaceThemeTemplate,
} from "@share/api";
import FullScreenPopup from "@share/components/full-screen-popup";
import PopupComponent from "@share/components/PopupComponent";
import gaEvents, { CATEGORY_CONFIG, ACTION_CONFIG } from "@share/lib/ga-events";
import ProductTour from "./ProductTour";
import { useDebounce } from "usehooks-ts";
import { sleep } from "antd-mobile/es/utils/sleep";
import { PRODUCT_TOUR_CLASS_NAME } from "./ProductTour/product-tour-config";
import { PASSIO_HOME_PAGE } from "@share/configs/system";
import cloneDeep from "lodash/cloneDeep";
import "simplebar/src/simplebar.css";
import AddNewBlock from "./AddNewBlock/AddNewBlock";
import Preview from "./Preview";
import ThemeTemplateHandler from "./ThemeTemplateHandler";
import ThemeTemplatePreview from "./ThemeTemplateHandler/ThemeTemplatePreview";
import ModalGuide, { ModalGuideRef } from "./ModalGuide";
import { useSelector } from "react-redux";
import { RootState } from "@app/store";
import { FirstGuideStateEnum } from "../store/externalStateSlice";
import { DesignContext } from "../context/context";
import IntroductionNewFeature from "./IntroductionNewFeature/BrandGiftFloat";
import IntroductionNewFeatureBlock from "./IntroductionNewFeature/BrandRatingFloat";
import DesignMainLayout from "./DesignMainLayout";
import useGetHeaderMoreActions from "../hooks/useGetHeaderMoreActions";

type Props = {
  callbackShowResultPublish: any;
  activeIndex: number;
  handleRedirectTabs: any;
  isOpenFloatingAddBlock?: boolean;
  callBackCloseFloating?: any;
  setIsOpenPositionSelectedList?: any;
  setIsOpenAddBlockFloatingPanel?: any;
  isOpenPositionSelectedList?: any;
  isOpenAddBlockFloatingPanel?: any;
};

const Design = (props: Props) => {
  const {
    callbackShowResultPublish,
    activeIndex,
    handleRedirectTabs,
    isOpenFloatingAddBlock,
    setIsOpenPositionSelectedList,
    setIsOpenAddBlockFloatingPanel,
    isOpenPositionSelectedList,
    isOpenAddBlockFloatingPanel,
  } = props;

  const designMoreRef = useRef(null);
  const productTourRef = useRef(null);
  const popoverManualRef = useRef(null);
  const tabBarHeaderRef = useRef(null);
  const guideState = useSelector<RootState, any>(
    (state) => state.externalState.guide_state
  );

  const [tabBarHeaderHeight, setTabBarHeaderHeight] = useState(null);
  const [tabBarFooterHeight, setTabBarFooterHeight] = useState(null);
  const [tabMenuHeight, setTabMenuHeight] = useState(null);
  const [isOpenAddNewThemeSelectionPanel, setIsOpenAddNewThemeSelectionPanel] =
    useState(false);
  const [checkLoadingBtnPublish, setCheckLoadingBtnPublish] = useState(false);
  const [isOpenPreviewAndReplaceTheme, setIsOpenPreviewAndReplaceTheme] =
    useState(false);
  const [selectedThemeTemplate, setSelectedThemeTemplate] = useState(null);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [isOpenProductTour, setIsOpenProductTour] = useState(false);
  const [isProductTourManual, setIsProductTourManual] = useState(false);
  const [isShowTextCustomUserManual, setIsShowTextCustomUserManual] =
    useState(false);

  const [selectedBlock, setSelectedBlock] = useState<{
    position: POSITION_TYPE;
    uid: string;
    sort_order?: number;
  }>();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const positionState = useAppSelector((state) => state.positions);
  const siteSettings = useAppSelector((state) => state.siteSettings);
  const siteSettingUserRootId = siteSettings?.id;
  const dynamicConfig = useAppSelector((state) => state.dynamicConfig);
  const user = useAppSelector((state) => state.user);
  const domain = user?.kol?.domain;
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);
  const creatorProfile = useAppSelector((state) => state.creatorProfile);
  const isRenderContent = useMemo(
    () =>
      siteSettings?.user_id ? !!(siteSettings && siteSettings.user_id) : false,
    [siteSettings]
  );
  const outside_space =
    siteSettings?.display_setting?.block_setting?.block_style?.outside_space;
  const headerState = positionState.find(
    (x) => x.slug === POSITION_TYPE.HEADER
  );
  const bodyState = positionState.find((x) => x.slug === POSITION_TYPE.BODY);
  const footerState = positionState.find(
    (x) => x.slug === POSITION_TYPE.FOOTER
  );
  const expandPosition = positionState.find(
    (x) => x.slug === POSITION_TYPE.EXPAND
  );

  const hasChange = useMemo(
    () =>
      siteSettings?.has_change
        ? !!(siteSettings && siteSettings.has_change)
        : false,
    [siteSettings]
  );

  const isSaving = dynamicConfig?.isSaving;
  const isOpenProductTourDynamic = dynamicConfig?.isOpenProductTourDynamic;

  const creator_role = passioPageProfile?.creator_role;
  const finishProductTour = creatorProfile?.finish_product_tour;

  const isSavingProductTour = useDebounce(
    dynamicConfig?.isSavingProductTour,
    300
  );
  const isAdminRole = useMemo(
    () => creator_role === CREATOR_ROLE_ENUM.ADMIN,
    [creator_role]
  );

  const actions = useGetHeaderMoreActions(isAdminRole, positionState);

  const modalGuideRef = useRef<ModalGuideRef>(null);

  useEffect(() => {
    if (isOpenFloatingAddBlock === true) {
      setIsOpenPositionSelectedList(false);
    }
  }, [isOpenFloatingAddBlock]);

  const requestSelectedThemeTemplate = async () => {
    let themeResponse = null;
    try {
      themeResponse = await requestGetSelectedThemeTemplate(
        siteSettings?.user_id
      );
    } catch (error) {
      console.log(error);
    }
    if (themeResponse?.data?.success == true) {
      const themeData = themeResponse?.data?.data;
      setSelectedThemeTemplate(themeData);
      return themeData;
    }
    return null;
  };

  useEffect(() => {
    if (activeIndex === TAB_BAR_INDEX.DESIGN && siteSettings?.user_id) {
      requestSelectedThemeTemplate();
    }
  }, [activeIndex, siteSettings?.user_id]);

  const tabListBody = bodyState?.block_list[0]?.content_attributes?.tabs;

  const newHeaderState = useMemo(() => {
    if (tabListBody) {
      const newHeaderBlock =
        headerState &&
        headerState.block_list &&
        headerState.block_list.map((ele) => {
          return ele.block_type === BLOCK_TYPE_CONFIG.TAB_BAR
            ? {
                ...ele,
                content_attributes: {
                  ...ele.content_attributes,
                  tabListBody,
                },
              }
            : ele;
        });
      return {
        ...headerState,
        block_list: newHeaderBlock,
      };
    } else return headerState;
  }, [headerState, tabListBody]);

  const newFooterState = useMemo(() => {
    if (tabListBody) {
      const newFooterBlock =
        footerState &&
        footerState.block_list &&
        footerState.block_list.map((ele) => {
          return ele.block_type === BLOCK_TYPE_CONFIG.TAB_BAR
            ? {
                ...ele,
                content_attributes: {
                  ...ele.content_attributes,
                  tabListBody,
                },
              }
            : ele;
        });
      return {
        ...footerState,
        block_list: newFooterBlock,
      };
    } else return footerState;
  }, [footerState, tabListBody]);

  const isEnableHeader = !!(
    headerState &&
    headerState.enable &&
    headerState.enable === 1
  );
  const isEnableBody = !!(
    bodyState &&
    bodyState.enable &&
    bodyState.enable === 1
  );

  const tabMenuStyle = useMemo(() => {
    const blockListHeader = headerState && headerState.block_list;
    const tabBarBlock =
      blockListHeader &&
      blockListHeader.find((x) => x.block_type === BLOCK_TYPE.TAB_BAR);
    const style_attributes = tabBarBlock && tabBarBlock.style_attributes;
    return style_attributes;
  }, [headerState]);

  useEffect(() => {
    const tabBarHeaderElement = document.querySelector(
      ".tab-bar-design-header"
    ) as HTMLElement;
    const tabBarFooterElement = document.querySelector(
      ".tab-bar-footer"
    ) as HTMLElement;
    const tabMenuAlwaysGhimElement = document.querySelector(
      ".tab-design-always-ghim"
    ) as HTMLElement;

    const timer = setTimeout(() => {
      if (tabBarHeaderElement) {
        setTabBarHeaderHeight(tabBarHeaderElement.offsetHeight);
      } else {
        setTabBarHeaderHeight(null);
      }
      if (tabBarFooterElement) {
        setTabBarFooterHeight(tabBarFooterElement.offsetHeight);
      } else {
        setTabBarFooterHeight(null);
      }
      if (tabMenuAlwaysGhimElement) {
        setTabMenuHeight(tabMenuAlwaysGhimElement.offsetHeight);
      } else {
        setTabMenuHeight(null);
      }
    }, 300);
    return () => clearTimeout(timer);
  });

  const backToPassio = () => {
    if (dynamicConfig?.isSaving) {
      Dialog.alert({
        header: (
          <ExclamationCircleFill
            style={{
              fontSize: 40,
              color: "var(--adm-color-warning)",
            }}
          />
        ),
        confirmText: t("ss_builder_close_panel_edit_text_block"),
        title: t("ss_builder_setting_saving"),
        content: (
          <>
            <div className="text-center">{t("ss_builder_wait_for_saving")}</div>
          </>
        ),
      });
    } else {
      const castWindow = window as any;
      if (castWindow.flutter_inappwebview !== undefined) {
        castWindow.flutter_inappwebview.callHandler("closeWebView");
      } else {
        window.location.href = PASSIO_HOME_PAGE;
      }
    }
  };

  const handleShowResultPublish = (value) => {
    callbackShowResultPublish(value);
  };

  const handleHideLoadingBtn = () => {
    setCheckLoadingBtnPublish(false);
  };

  const handleClickPublishThemeAndChangeGuideIfExist = () => {
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.PUBLISH_SITE,
      "PublishSite",
      domain
    );
    setCheckLoadingBtnPublish(true);
    dispatch(publishSite(handleShowResultPublish, handleHideLoadingBtn));
    if (guideState === FirstGuideStateEnum.PUBLISH) changeFirstGuide();
  };

  const handleReplaceTheme = async () => {
    if (!selectedThemeTemplate) {
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.APPLY_SAMPLE_CONTENT,
        "ApplySampleContent_DoesNotExist",
        domain,
        ACTION_CONFIG.THEME_NOT_EXITS
      );
      Modal.show({
        header: (
          <InformationIcon
            className="w-11 h-11"
            fillColor="#FF8F1F"
            onClick={() => {}}
          />
        ),
        content: (
          <div className="flex flex-col text-center items-center">
            <div className="text-lg font-bold leading-[25px]">
              {t("ss_builder_dont_apply_theme_content_modal_label")}
            </div>
            <div className="">
              {t("ss_builder_dont_apply_theme_content_modal_description")}
            </div>
          </div>
        ),
        className: "modal-error-class",
        closeOnAction: true,
        actions: [
          {
            key: "confirm",
            text: t(
              "ss_builder_dont_apply_theme_content_modal_btn_confirm_label"
            ),
          },
        ],
      });
    } else {
      const data = await requestSelectedThemeTemplate();
      if (data) {
        setIsOpenPreviewAndReplaceTheme(true);
      } else {
        Modal.show({
          header: (
            <InformationIcon
              className="w-11 h-11"
              fillColor="#FF8F1F"
              onClick={() => {}}
            />
          ),
          content: (
            <div className="flex flex-col text-center items-center">
              <div className="text-lg font-bold leading-[25px]">
                {t("ss_builder_dont_apply_theme_content_modal_label")}
              </div>
              <div className="">
                {t("ss_builder_dont_apply_theme_content_modal_description")}
              </div>
            </div>
          ),
          className: "modal-error-class",
          closeOnAction: true,
          actions: [
            {
              key: "confirm",
              text: t(
                "ss_builder_dont_apply_theme_content_modal_btn_confirm_label"
              ),
            },
          ],
        });
      }
    }
  };

  const handleClickPopoverItem = (item: Action) => {
    const key = item && item.key;
    switch (key) {
      case "undo":
        break;
      case "redo":
        break;
      case "restore":
        confirmRestoreTheme();
        break;
      case "replace-theme":
        handleReplaceTheme();
        break;
      case "create-new-theme":
        handleCreateNewTheme();
        break;
      default:
    }
  };

  const handleCreateNewTheme = () => {
    setIsOpenAddNewThemeSelectionPanel(true);
  };

  const handleRestoreTheme = () => {
    const handleShowRestoreSuccessfully = () =>
      Toast.show({
        icon: (
          <CheckCircleIcon
            className="text-center inline-block w-[30px] h-[30px]"
            fillColor="#00B578"
          />
        ),
        content: (
          <div className="text-center">
            {t("ss_builder_restore_theme_successfully_label")}
          </div>
        ),
      });
    dispatch(restoreTheme(handleShowRestoreSuccessfully));
  };

  const contentPopoverManual = () => {
    return (
      <div className="w-[320px] relative max-w-full popover-manual">
        {!isShowTextCustomUserManual && (
          <div
            className="w-[32px] h-[32px] absolute p-1 right-2 top-2 cursor-pointer z-[3]"
            onClick={() => {
              handleHiddenPopoverManual();
              gaEvents.submitEventV2(
                passioPageProfile,
                CATEGORY_CONFIG.PRODUCT_TOUR,
                `ProductTour_Step0`,
                domain,
                `Step 0`
              );
            }}
          >
            <ClosePopupIconV2 fillColor="#fff" />
          </div>
        )}

        <div className="wrapper-image">
          <PlaceHolderImageIcon className="img-placeholder" />
          <img
            className="img-popover relative z-[2]"
            src="https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-17.gif"
          />
        </div>
        <div className="p-3">
          <div className="mb-2 text-lg leading-[25px] font-bold">
            {t("ss_builder_popover_manual_title")}
          </div>

          {isShowTextCustomUserManual ? (
            <>
              <div className="text-[15px] font-normal leading-[140%] text-textColorDefault  mb-5">
                {t("ss_builder_popover_review_manual_content")}
              </div>
              <Button
                color="primary"
                size="middle"
                fill="solid"
                onClick={handleHiddenPopoverManual}
                block
              >
                {t("ss_builder_popover_manual_button_close")}
              </Button>
            </>
          ) : (
            <>
              <div className="text-[15px] font-normal leading-[140%] text-textColorDefault  mb-5">
                {t("ss_builder_popover_manual_content")}
              </div>
              <Button
                color="primary"
                size="middle"
                fill="solid"
                onClick={handleOpenModalManual}
                block
              >
                <span className="flex items-center w-full justify-center gap-2">
                  {t("ss_builder_popover_manual_button")}{" "}
                  <ArrowRightIcon fillColor="#fff" />
                </span>
              </Button>
            </>
          )}
        </div>
      </div>
    );
  };
  const [forceVisiblePopoverHeader, setForceVisiblePopoverHeader] =
    useState(false);

  const confirmRestoreTheme = () =>
    Modal.confirm({
      header: (
        <InformationIcon
          className="w-11 h-11"
          fillColor="var(--adm-color-primary)"
          onClick={() => {}}
        />
      ),
      title: <div>{t("ss_builder_restore_theme_title")}</div>,
      content: (
        <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
          {t("ss_builder_restore_theme_content")}
        </div>
      ),
      confirmText: t("ss_builder_confirm_restore_theme_label"),
      cancelText: t("ss_builder_cancel-restore_theme_label"),
      onConfirm: () => {
        gaEvents.submitEventV2(
          passioPageProfile,
          CATEGORY_CONFIG.RESTORE_EDITS,
          "RestoreEdits_Confirm",
          domain,
          ACTION_CONFIG.CONFIRM
        );
        handleRestoreTheme();
      },
      onCancel: () => {
        gaEvents.submitEventV2(
          passioPageProfile,
          CATEGORY_CONFIG.RESTORE_EDITS,
          "RestoreEdits_Cancel",
          domain,
          ACTION_CONFIG.CANCEL
        );
        return;
      },
    });

  const renderStyleWithTabMenu = useMemo(() => {
    let styleObj = {};
    const position_style = tabMenuStyle && tabMenuStyle.position_style;
    const ghim_style = tabMenuStyle && tabMenuStyle.ghim_style;
    if (ghim_style === TAB_GHIM_STYLE.ALWAYS_GHIM && position_style === "top") {
      styleObj = {
        paddingBottom: `${tabBarFooterHeight}px`,
        paddingTop: `${tabMenuHeight + tabBarHeaderHeight}px`,
      };
    } else if (
      ghim_style === TAB_GHIM_STYLE.ALWAYS_GHIM &&
      position_style === "bottom"
    ) {
      styleObj = {
        paddingTop: `${tabBarHeaderHeight}px`,
      };
    } else {
      styleObj = {
        paddingTop: `${tabBarHeaderHeight}px`,
      };
    }
    return styleObj;
  }, [tabMenuStyle, tabMenuHeight, tabBarHeaderHeight, tabBarFooterHeight]);

  const paddingDesignContent = useMemo(() => {
    const offsetHeightTab = tabMenuHeight ?? 0;
    const position_style = tabMenuStyle && tabMenuStyle.position_style;
    const ghim_style = tabMenuStyle && tabMenuStyle.ghim_style;

    let paddingDesignContent = {
      paddingBottom: `${tabBarFooterHeight}px`,
    };
    if (
      ghim_style === TAB_GHIM_STYLE.ALWAYS_GHIM &&
      position_style === "bottom"
    ) {
      const paddingBottom = offsetHeightTab ? offsetHeightTab + 60 : 60;
      paddingDesignContent = {
        paddingBottom: offsetHeightTab ? `${paddingBottom}px` : `60px`,
      };
    }
    return paddingDesignContent;
  }, [tabMenuStyle, tabMenuHeight, tabBarFooterHeight]);

  const addNewBlockStyle = useMemo(() => {
    const offsetHeightTab = tabMenuHeight ?? 0;
    const bottom = offsetHeightTab ? offsetHeightTab + 68 : 70;
    const position_style = tabMenuStyle && tabMenuStyle.position_style;
    const ghim_style = tabMenuStyle && tabMenuStyle.ghim_style;
    let addNewBlockStyle = {
      bottom: `70px`,
    };
    if (
      ghim_style === TAB_GHIM_STYLE.ALWAYS_GHIM &&
      position_style !== TAB_POSITION.TOP
    ) {
      addNewBlockStyle = {
        bottom: `${bottom}px`,
      };
    }
    return addNewBlockStyle;
  }, [tabMenuStyle, tabMenuHeight]);

  const isEnableLoadingPublishBtn = useMemo(
    () => isSaving || checkLoadingBtnPublish,
    [isSaving, checkLoadingBtnPublish, hasChange]
  );
  const isEnablePublishBtn = useMemo(
    () => isSaving || checkLoadingBtnPublish || !hasChange,
    [isSaving, checkLoadingBtnPublish, hasChange]
  );

  const handleConfirmThemeTemplatePreviewAndReplace = async () => {
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.APPLY_SAMPLE_CONTENT,
      "ApplySampleContent_Confirm",
      domain,
      ACTION_CONFIG.CONFIRM
    );
    let reponseReplaceTheme = null;
    const dataRequest = {
      user_id: siteSettings.user_id,
    };
    try {
      reponseReplaceTheme = await requestReplaceThemeTemplate(dataRequest);
    } catch (error) {
      const statusCode = error?.response?.status;
      if (statusCode == 404) {
        Modal.show({
          header: (
            <InformationIcon
              className="w-11 h-11"
              fillColor="#FF8F1F"
              onClick={() => {}}
            />
          ),
          content: (
            <div className="flex flex-col text-center items-center">
              <div className="text-lg font-bold leading-[25px]">
                {t("ss_builder_dont_apply_theme_content_modal_label")}
              </div>
              <div className="">
                {t("ss_builder_dont_apply_theme_content_modal_description")}
              </div>
            </div>
          ),
          className: "modal-error-class",
          closeOnAction: true,
          actions: [
            {
              key: "confirm",
              text: t(
                "ss_builder_dont_apply_theme_content_modal_btn_confirm_label"
              ),
            },
          ],
        });
      }
    }
    if (/^20[0-9]$/.test(reponseReplaceTheme?.status.toString())) {
      const siteResponseData = reponseReplaceTheme?.data?.data;
      const payloadPosition = siteResponseData?.positions;
      const siteResponseDataClone = cloneDeep(siteResponseData);
      if (siteResponseDataClone) delete siteResponseDataClone?.positions;
      dispatch(setPosition({ positions: payloadPosition }));
      dispatch(setSiteInfo({ sites: siteResponseDataClone }));
      dispatch(
        setParamsTab({
          data: {
            activeKeyTab: null,
          },
        })
      );
      setIsOpenPreviewAndReplaceTheme(false);
      Toast.show({
        icon: (
          <CheckCircleIcon
            className="text-center inline-block"
            fillColor="#00B578"
          />
        ),
        content: (
          <div className="text-center">
            {t("ss_builder_replace_theme_template_successfully_label")}
          </div>
        ),
      });
    } else {
      dispatch(
        setDynamicParams({
          data: { isErrorNetwork: true },
        })
      );
    }
  };

  const handleOpenPositionSelectedList = () => {
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.ADD_BLOCK_BUTTON,
      "AddBlockButton_Click",
      null,
      ACTION_CONFIG.CLICK
    );
    setIsOpenPositionSelectedList(true);
  };

  const buttonPublish = () => {
    let elementPublish = (
      <>
        <SpinLoading
          color="#fff"
          style={{ "--size": "15px" }}
        />
        {t("ss_builder_publish_saving")}
      </>
    );
    if (!isSaving) {
      if (checkLoadingBtnPublish) {
        elementPublish = (
          <>
            <SpinLoading
              color="#fff"
              style={{ "--size": "15px" }}
            />
            {t("ss_builder_publish_button_label")}
          </>
        );
      } else {
        elementPublish = (
          <>
            <PubLishIcon
              width={15}
              height={15}
              className=""
            />
            {t("ss_builder_publish_button_label")}
          </>
        );
      }
    }
    return elementPublish;
  };
  useEffect(() => {
    if (!siteSettings.user_id) {
      return;
    }

    if (
      isSavingProductTour === false &&
      typeof finishProductTour === "number" &&
      finishProductTour !== FINISH_PRODUCT_TOUR
    ) {
      if (!isEnableHeader) {
        const enableValue = 1;
        dispatch(
          disableEnablePosition({
            enableValue,
            positionSlug: POSITION_MAIN_LAYOUT.HEADER,
          })
        );
      }

      Modal.alert({
        image:
          "https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-0.png",
        title: t("ss_builder_product_tour_title_step_0"),
        className: "modal-product-tour",
        content: (
          <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
            {t("ss_builder_product_tour_content_step_0")}
          </div>
        ),
        confirmText: (
          <span className="flex items-center w-full justify-center gap-2">
            {t("ss_builder_product_tour_button_step_0")}
            <ArrowRightIcon fillColor="#fff" />
          </span>
        ),
        onConfirm: () => {
          setIsOpenProductTour(true);
          handleDispatchOpenProductTour();
          return;
        },
      });
    }
  }, [siteSettings.user_id, finishProductTour, isSavingProductTour]);

  const handleHiddenPopoverManual = () => {
    popoverManualRef?.current?.hide();
  };
  const handleOpenModalManual = () => {
    // gaEvents.submitEventV2(
    //   passioPageProfile,
    //   CATEGORY_CONFIG.PRODUCT_TOUR,
    //   `ProductTour_Step0`,
    //   domain,
    //   `Step 0`
    // );
    productTourRef.current.scrollIntoView({});
    handleHiddenPopoverManual();
    setIsOpenProductTour(true);
    setIsProductTourManual(true);
    if (!isEnableHeader) {
      const enableValue = 1;
      dispatch(
        disableEnablePosition({
          enableValue,
          positionSlug: POSITION_MAIN_LAYOUT.HEADER,
        })
      );
    }
    handleDispatchOpenProductTour();
  };
  const handleOpenProductTour = async () => {
    setIsOpenProductTour(false);
    if (!isProductTourManual) {
      setIsShowTextCustomUserManual(true);
      await sleep(300);
      popoverManualRef?.current?.show();
    }
  };
  const onVisibleChangePopoverManual = (visible) => {
    if (!visible) {
      setIsShowTextCustomUserManual(false);
    }
  };
  const handleDispatchOpenProductTour = () => {
    dispatch(setDynamicParams({ data: { isOpenProductTourDynamic: true } }));
  };

  useEffect(() => {
    const castWindow = window as any;
    castWindow.triggerVisiblePopoverHeader = setForceVisiblePopoverHeader;
    () => {
      if (castWindow.triggerVisiblePopoverHeader) {
        castWindow.triggerVisiblePopoverHeader = undefined;
        setForceVisiblePopoverHeader(false);
      }
    };
  }, []);

  const handleOpenPreviewOrShowGuide = () => {
    if (guideState === FirstGuideStateEnum.PREVIEW) {
      showFirstGuide();
    } else {
      handleOpenPreviewAndTrakingGAAndChangeGuideIfExist();
    }
  };

  const handleClosePreview = () => setIsOpenPreviewAndReplaceTheme(false);

  const handleOpenPreviewAndTrakingGAAndChangeGuideIfExist = () => {
    setIsOpenPreview(true);
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.PREVIEW_SITE,
      "PreviewSite",
      domain,
      ACTION_CONFIG.PREVIEW_SITE
    );
    if (guideState === FirstGuideStateEnum.PREVIEW) changeFirstGuide();
  };

  const showFirstGuide = () => {
    modalGuideRef && modalGuideRef.current && modalGuideRef.current.openModal();
  };

  const changeFirstGuide = () => {
    modalGuideRef &&
      modalGuideRef.current &&
      modalGuideRef.current.changeFirstGuide();
  };

  const handleShowFirtGuideOrPublish = () => {
    if (guideState === FirstGuideStateEnum.PUBLISH) {
      showFirstGuide();
    } else {
      handleClickPublishThemeAndChangeGuideIfExist();
    }
  };

  const PrevewAnimation = () => (
    <div className="relative">
      <div className="button-anim-square-fill"></div>
      <Button
        onClick={handleOpenPreviewOrShowGuide}
        className={PRODUCT_TOUR_CLASS_NAME.STEP_11}
        disabled={isEnableLoadingPublishBtn}
        size="small"
        style={{
          padding: "6px",
        }}
      >
        <PreviewThemeIcon className="icon-shake" />
      </Button>
    </div>
  );

  const handleUpdatePosition = (data) => {
    setSelectedBlock({
      position: data?.position,
      uid: data?.uid,
      sort_order: data?.sort_order,
    });
    setIsOpenAddBlockFloatingPanel(true);
  };

  return (
    <DesignContext.Provider
      value={{ setSelectedBlock, setIsOpenAddBlockFloatingPanel }}
    >
      <div
        className="design-class"
        id="passio-design-class"
      >
        <Portal elementId="tabBarHeaderPortal">
          <div
            className="tab-bar-design-header fixed top-0 w-[100%] bg-white px-3 py-2 z-[9998] left-[50%] translate-x-[-50%]"
            style={{
              borderBottom: "1px solid #EEEEEE",
            }}
            ref={designMoreRef}
          >
            <div className="max-width-content-class flex flex-row justify-between items-center mx-auto">
              <div>
                <Button
                  onClick={backToPassio}
                  size="small"
                  style={{
                    padding: "4px",
                  }}
                >
                  <div className="flex flex-row text-center items-center">
                    <BackIcon className="" />
                    <PassioIcon
                      className=""
                      fillColor="var(--adm-color-primary)"
                    />
                  </div>
                </Button>
              </div>
              {isRenderContent && (
                <div className="flex gap-2 items-center">
                  <div className="flex flex-row items-center gap-[15px]">
                    <div className="">
                      <Popover.Menu
                        {...(forceVisiblePopoverHeader
                          ? { visible: true }
                          : {})}
                        className={cls(
                          `${PRODUCT_TOUR_CLASS_NAME.STEP_9} ${PRODUCT_TOUR_CLASS_NAME.STEP_10} `,
                          isOpenProductTourDynamic
                            ? "open-product-tour-popover"
                            : "open-product-tour-popover",
                          isAdminRole ? "admin-role" : ""
                        )}
                        mode="dark"
                        actions={actions}
                        placement="bottom"
                        trigger="click"
                        stopPropagation={["click"]}
                        getContainer={() => designMoreRef.current}
                        onAction={(item: Action) =>
                          handleClickPopoverItem(item)
                        }
                        style={{
                          "--z-index": "9998",
                        }}
                      >
                        <Button
                          style={{
                            padding: "6px",
                          }}
                          size="small"
                          disabled={isEnableLoadingPublishBtn}
                        >
                          <MoreIcon
                            width={15}
                            height={15}
                          />
                        </Button>
                      </Popover.Menu>
                    </div>
                  </div>
                  <div
                    className="user-manual"
                    ref={tabBarHeaderRef}
                  >
                    <Popover
                      className="popover-user-manual"
                      content={contentPopoverManual()}
                      placement="bottom"
                      trigger="click"
                      stopPropagation={["click"]}
                      getContainer={() => tabBarHeaderRef.current}
                      ref={popoverManualRef}
                      onVisibleChange={onVisibleChangePopoverManual}
                    >
                      <Button
                        className={PRODUCT_TOUR_CLASS_NAME.STEP_17}
                        disabled={isEnableLoadingPublishBtn}
                        size="small"
                        style={{
                          padding: "6px",
                        }}
                      >
                        <QuestionIcon className="" />
                      </Button>
                    </Popover>
                  </div>
                  <div>
                    {guideState === FirstGuideStateEnum.PREVIEW ? (
                      <PrevewAnimation />
                    ) : (
                      <Button
                        onClick={handleOpenPreviewOrShowGuide}
                        className={PRODUCT_TOUR_CLASS_NAME.STEP_11}
                        disabled={isEnableLoadingPublishBtn}
                        size="small"
                        style={{
                          padding: "6px",
                        }}
                      >
                        <PreviewThemeIcon className="" />
                      </Button>
                    )}
                  </div>
                  <div className="relative">
                    {guideState === FirstGuideStateEnum.PUBLISH &&
                      !isEnablePublishBtn && (
                        <div
                          className="button-anim-square-fill button-anim-publish"
                          onClick={showFirstGuide}
                        ></div>
                      )}
                    <Button
                      color="primary"
                      size="small"
                      fill="solid"
                      onClick={handleShowFirtGuideOrPublish}
                      disabled={isEnablePublishBtn}
                      style={{
                        display: "flex",
                        padding: "3px 8px",
                      }}
                      className={cls(
                        `flex justify-center items-center gap-2 btn-publish-header`,
                        PRODUCT_TOUR_CLASS_NAME.STEP_4
                      )}
                    >
                      {guideState === FirstGuideStateEnum.PUBLISH &&
                      !isEnablePublishBtn ? (
                        <div className="relative flex items-center gap-2 publish-button-animating">
                          {buttonPublish()}
                        </div>
                      ) : (
                        <>{buttonPublish()}</>
                      )}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Portal>
        <div
          className="design-content-class w-full "
          style={{ ...paddingDesignContent, ...renderStyleWithTabMenu }}
        >
          <>
            {isRenderContent ? (
              <>
                <div ref={productTourRef}></div>
                <DesignMainLayout
                  headerState={newHeaderState}
                  footerState={newFooterState}
                  bodyState={bodyState}
                  isEnableBody={isEnableBody}
                  expandPosition={expandPosition}
                  addNewBlockStyle={addNewBlockStyle}
                  handleOpenPositionSelectedList={
                    handleOpenPositionSelectedList
                  }
                  showFirstGuide={showFirstGuide}
                  onUpdatePositionWhenDragBtn={handleUpdatePosition}
                />
                {!isSavingProductTour && isOpenProductTour && (
                  <ProductTour
                    handleOpenProductTour={handleOpenProductTour}
                    isProductTourManual={isProductTourManual}
                  />
                )}
              </>
            ) : (
              <div
                className="absolute"
                style={{ left: "calc(50% - 16px)", top: "calc(50% - 16px)" }}
              >
                <SpinLoading
                  color="primary"
                  style={{ "--size": "32px" }}
                />
              </div>
            )}
          </>
        </div>
        <Portal elementId="themeTemplateHandlerPortal">
          <ThemeTemplateHandler
            isOpenAddNewThemeSelectionPanel={isOpenAddNewThemeSelectionPanel}
            handleCancelAddNewThemeSelectionPanel={() =>
              setIsOpenAddNewThemeSelectionPanel(false)
            }
            siteSettings={siteSettings}
            user={user}
            handleRedirectTabs={handleRedirectTabs}
          />
        </Portal>
        {isOpenPreviewAndReplaceTheme && (
          <FullScreenPopup
            visible={isOpenPreviewAndReplaceTheme}
            onMaskClick={() => setIsOpenPreviewAndReplaceTheme(false)}
            isLimitWidth={false}
            style={{ background: "#fff" }}
          >
            <ThemeTemplatePreview
              onCancelThemeTemplatePreview={handleClosePreview}
              onConfirmThemeTemplatePreview={
                handleConfirmThemeTemplatePreviewAndReplace
              }
              user={user}
              selectedThemeData={selectedThemeTemplate}
              KEY={THEME_PREVIEW_KEY.PREVIEW_AND_APPLY_CONTENT}
              siteSettingUserRootId={siteSettingUserRootId}
            />
          </FullScreenPopup>
        )}
        {isOpenPreview && (
          <FullScreenPopup
            visible={isOpenPreview}
            onMaskClick={() => {
              setIsOpenPreview(false);
            }}
            isLimitWidth={false}
          >
            <Preview
              onCancelPreview={() => setIsOpenPreview(false)}
              siteSettings={siteSettings}
              user={user}
            />
          </FullScreenPopup>
        )}
        {isOpenAddBlockFloatingPanel && (
          <FullScreenPopup
            anchors={600}
            visible={isOpenAddBlockFloatingPanel}
          >
            <BlockSelectionV2
              cancleFP={() => {
                setIsOpenAddBlockFloatingPanel(false);
                setIsOpenPositionSelectedList(false);
              }}
              backFP={() => setIsOpenAddBlockFloatingPanel(false)}
              positionSlug={selectedBlock?.position}
              tabUid={selectedBlock?.uid}
              sortOrder={selectedBlock?.sort_order}
              tabListBody={tabListBody}
            />
          </FullScreenPopup>
        )}
        <PopupComponent
          visible={isOpenPositionSelectedList}
          onMaskClick={() => setIsOpenPositionSelectedList(false)}
          title={t("ss_builder_select_position_add_block")}
          onClose={() => setIsOpenPositionSelectedList(false)}
          style={{
            borderRadius: "8px 8px 0px 0px",
            maxWidth: outside_space
              ? `${MAX_WIDTH_OUTSIDE_ON}px`
              : `${MAX_WIDTH_CONTENT_LAYOUT}px`,
          }}
        >
          <AddNewBlock
            position={positionState}
            onSelectBlock={(data) => {
              setSelectedBlock(data);
              setIsOpenAddBlockFloatingPanel(true);
            }}
          />
        </PopupComponent>
        <ModalGuide
          onOpenPositionSelection={handleOpenPositionSelectedList}
          onOpenPreview={handleOpenPreviewAndTrakingGAAndChangeGuideIfExist}
          onPublish={handleClickPublishThemeAndChangeGuideIfExist}
          ref={modalGuideRef}
        />
        <IntroductionNewFeature />
        <IntroductionNewFeatureBlock />
      </div>
    </DesignContext.Provider>
  );
};

export default Design;
