import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { List } from "antd-mobile";
import { AngelRightIcon } from "@share/icons/line-icon";
import { DigitalProducticonV2 } from "@share/icons/block-icons";
import { IFRAME_ACTION } from "@features/block/block-editor/DigitalProduct/constants";
import FullScreenPopup from "../full-screen-popup";
import { fetchSiteSetting } from "@share/api";
import { setPosition } from "@features/main/store/positionsSlice";
import cloneDeep from "lodash/cloneDeep";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@app/hooks";
import { COUNTRY_TH } from "@share/configs/const";

export const ManagementServiceComponent = ({
  onClose,
}: {
  onClose?: () => void;
}) => {
  const url_Iframe = process.env.NEXT_PUBLIC_PASSIO_IFRAME_URL;
  const user = useAppSelector((state) => state.user);
  const userId = user?.kol?._id;
  const country = user?.kol?.country;
  const { t } = useTranslation();
  const [isOpenPopupIframe, setOpenPopupIframe] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const dispatch = useDispatch();
  const handleSetIsUpdated = (value) => {
    setIsUpdated(value);
  };
  const handleFetchSiteSetting = async (userId) => {
    let responseSite = null;
    try {
      responseSite = await fetchSiteSetting(userId);
    } catch (error) {
      console.log(error);
    }
    if (responseSite?.positions) {
      const payloadPosition = responseSite.positions;
      const responseSiteClone = cloneDeep(responseSite);
      if (responseSiteClone) delete responseSiteClone.positions;
      console.log("payloadPosition", payloadPosition);

      dispatch(setPosition({ positions: payloadPosition }));
    }
  };

  const handleOpenPopupIfame = (value) => {
    setOpenPopupIframe(value);
    const iframe = document.getElementById(
      "select-list-iframe"
    ) as HTMLIFrameElement;
    console.log("iframe", iframe);
    if (!iframe) {
      return;
    }
    iframe.contentWindow.postMessage(
      {
        key: IFRAME_ACTION.LIST_DATA,
        data: "",
      },
      "*"
    );
  };
  const handleMessage = (event) => {
    switch (event.data.key) {
      case IFRAME_ACTION.CLOSE_IFRAME:
        handleSetIsUpdated(true);
        handleOpenPopupIfame(false);
        break;
      case IFRAME_ACTION.RECIVED_DATA:
      case IFRAME_ACTION.FETCH_DATA:
        handleSetIsUpdated(true);
        break;
    }
  };
  useEffect(() => {
    window.addEventListener("message", handleMessage, false);
    return () => window.removeEventListener("message", handleMessage);
  }, []);
  useEffect(() => {
    if (isUpdated) {
      handleFetchSiteSetting(userId);
    }
  }, [isUpdated, userId]);

  if (country === COUNTRY_TH) {
    return null;
  }

  return (
    <div className="popup-auto rating-popup-content">
      <List>
        <List.Item
          prefix={
            <DigitalProducticonV2
              className="rounded-lg"
              width={32}
              height={32}
            />
          }
          onClick={() => handleOpenPopupIfame(true)}
          arrow={<AngelRightIcon />}
          description={t("ss_builder_management_digital_product_description")}
        >
          <div className="text-[17px]">
            {t("ss_builder_management_digital_product_title")}
          </div>
        </List.Item>
      </List>
      {isOpenPopupIframe && (
        <FullScreenPopup visible={true}>
          <div
            className="w-full fixed min-h-screen top-0 left-0"
            style={{ zIndex: 999999 }}
          >
            <iframe
              id={"select-list-iframe"}
              src={`${url_Iframe}kol/digital-product/iframe?isIframe=true&isSetting=true`}
              className="min-h-screen w-full"
            />
          </div>
        </FullScreenPopup>
      )}
    </div>
  );
};

export default ManagementServiceComponent;
