import { useAppSelector } from "@app/hooks";
import { useHasChange, useOnCancel } from "@features/setting/hooks";
import FloatingPanelComponent from "@share/components/FloatingPanel";
import {
  BIG_BUTTON_SETTING_DEFAULT_VALUE,
  BUTTON_SETTING_DEFAULT_VALUE,
  BUTTON_SETTING_RADIUS_STYLE,
  MEDIUM_BUTTON_SETTING_DEFAULT_VALUE,
  SMALL_BUTTON_SETTING_DEFAULT_VALUE,
  BUTTON_SETTING_SHADOW_STYLE,
  MAX_WIDTH_CONTENT_LAYOUT,
} from "@share/configs/const";
import { saveButtonThemeSetting } from "@share/thunk/setting";
import {
  Button,
  Collapse,
  Divider,
  Selector,
  Slider,
  Switch,
} from "antd-mobile";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import { SettingIcon } from "@share/icons";
import {
  ButtonIcon,
  CustomIcon,
  SquareIcon,
  RoundedIcon,
  NoShadowRoundedIcon,
  NoShadowSquareIcon,
  NoShadowCustomIcon,
  SoftShadowRoundedIcon,
  SoftShadowSquareIcon,
  SoftShadowCustomIcon,
  HardShadowRoundedIcon,
  HardShadowSquareIcon,
  HardShadowCustomIcon,
  IconGeneral,
} from "../../Icons";
import { ColorPickerV2 } from "../ColorPicker";
import SettingHeader from "../SettingHeader";
import DetailButtonSetting, {
  ButtonDetailSettingInterface,
} from "./components/DetailButtonSetting";
import * as generateColor from "@share/lib/generateColor";
import DisplayColor from "@share/components/DisplayColor";
import gaEvents, { CATEGORY_CONFIG, LABEL_CONFIG } from "@share/lib/ga-events";
import { handleCapitalizeFirstLetter } from "@share/lib";
import { DropDownIcon1, DropDownIcon2 } from "@share/icons";
import cls from "classnames";
import PopupComponent from "@share/components/PopupComponent";
import { useToast } from "@share/hooks/toast";
import {selectProfile} from "@features/user/userSlice";

interface ButtonSettingInterface {
  enable_border?: boolean;
  border_color?: string;
  border_width?: number;
  background_color?: string;
  radius_type?: string;
  radius_value?: number;
  text_color?: string;
  big_button_setting?: ButtonDetailSettingInterface;
  medium_button_setting?: ButtonDetailSettingInterface;
  small_button_setting?: ButtonDetailSettingInterface;
  button_bg_color?: object;
  button_border_color?: object;
  shadow_type?: string;
  shadow_color?: string;
}

const defaultBigButtonSetting: ButtonDetailSettingInterface = {
  font_size: BIG_BUTTON_SETTING_DEFAULT_VALUE.FONT_SIZE,
  line_height: BIG_BUTTON_SETTING_DEFAULT_VALUE.LINE_HEIGHT,
  font_weight: BIG_BUTTON_SETTING_DEFAULT_VALUE.FONT_WEIGHT,
  type: "big",
};

const defaultMediumButtonSetting: ButtonDetailSettingInterface = {
  font_size: MEDIUM_BUTTON_SETTING_DEFAULT_VALUE.FONT_SIZE,
  line_height: MEDIUM_BUTTON_SETTING_DEFAULT_VALUE.LINE_HEIGHT,
  font_weight: MEDIUM_BUTTON_SETTING_DEFAULT_VALUE.FONT_WEIGHT,
  type: "medium",
};

const defaultSmallButtonSetting: ButtonDetailSettingInterface = {
  font_size: SMALL_BUTTON_SETTING_DEFAULT_VALUE.FONT_SIZE,
  line_height: SMALL_BUTTON_SETTING_DEFAULT_VALUE.LINE_HEIGHT,
  font_weight: SMALL_BUTTON_SETTING_DEFAULT_VALUE.FONT_WEIGHT,
  type: "small",
};

// const RADIUS_STYLE_SQUARE = "square";
// const RADIUS_STYLE_ROUNDED = "circle";
// const RADIUS_STYLE_CUSTOM = "custom";

const masks = {
  0: 0,
  2: "",
  4: "",
  6: "",
  8: "",
  10: "",
  12: "",
  14: "",
  16: "",
  18: "",
  20: "",
  22: "",
  24: "",
  26: "",
  28: "",
  30: "",
  32: "",
  34: "",
  36: "",
  38: "",
  40: "",
  42: "",
  44: "",
  46: "",
  48: 48,
};
const defaultButtonSettingData: ButtonSettingInterface = {
  enable_border: BUTTON_SETTING_DEFAULT_VALUE.ENABLE_BORDER,
  border_color: BUTTON_SETTING_DEFAULT_VALUE.BORDER_COLOR,
  background_color: BUTTON_SETTING_DEFAULT_VALUE.BACKGROUND_COLOR,
  text_color: BUTTON_SETTING_DEFAULT_VALUE.TEXT_COLOR,
  border_width: BUTTON_SETTING_DEFAULT_VALUE.BORDER_WIDTH,
  radius_type: BUTTON_SETTING_DEFAULT_VALUE.RADIUS_TYPE,
  radius_value: BUTTON_SETTING_DEFAULT_VALUE.RADIUS_VALUE,
  big_button_setting: defaultBigButtonSetting,
  medium_button_setting: defaultMediumButtonSetting,
  small_button_setting: defaultSmallButtonSetting,
  button_bg_color: {},
  button_border_color: {},
};
const COLOR_LENGTH_DEFAULT = 9;

export default function ButtonSetting(props) {
  const { backToAllSetting, type } = props;
  const buttonSettingDataState = useAppSelector(
    (state) => state?.siteSettings?.display_setting?.button_setting
  );
  const [buttonSettingData, setButtonSettingData] =
    useState<ButtonSettingInterface>(
      buttonSettingDataState ?? defaultButtonSettingData
    );
  const [isOpenBorderColorPicker, setIsOpenBorderColorPicker] = useState(false);
  const [isOpenShadowColorPicker, setIsOpenShadowColorPicker] = useState(false);
  const [isOpenBackgroundColorPicker, setIsOpenBackgroundColorPicker] =
    useState(false);
  const [isOpenTextColorPicker, setIsOpenTextColorPicker] = useState(false);
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);

  const hasChange = useHasChange([buttonSettingData]);

  const onCancel = useOnCancel(hasChange, backToAllSetting, type);

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { SuccessToast } = useToast();
  const {
    enable_border = BUTTON_SETTING_DEFAULT_VALUE.ENABLE_BORDER,
    border_color = BUTTON_SETTING_DEFAULT_VALUE.BORDER_COLOR,
    background_color = BUTTON_SETTING_DEFAULT_VALUE.BACKGROUND_COLOR,
    text_color = BUTTON_SETTING_DEFAULT_VALUE.TEXT_COLOR,
    border_width = BUTTON_SETTING_DEFAULT_VALUE.BORDER_WIDTH,
    radius_type = BUTTON_SETTING_DEFAULT_VALUE.RADIUS_VALUE,
    radius_value = BUTTON_SETTING_DEFAULT_VALUE.RADIUS_VALUE,
    big_button_setting = defaultBigButtonSetting,
    medium_button_setting = defaultMediumButtonSetting,
    small_button_setting = defaultSmallButtonSetting,
    button_bg_color = {},
    button_border_color = {},
    shadow_type = BUTTON_SETTING_DEFAULT_VALUE.SHADOW_TYPE,
    shadow_color = shadow_type === BUTTON_SETTING_SHADOW_STYLE.SOFT_SHADOW
      ? BUTTON_SETTING_DEFAULT_VALUE.SOFT_SHADOW_COLOR
      : BUTTON_SETTING_DEFAULT_VALUE.HARD_SHADOW_COLOR,
  } = buttonSettingData;

  const new_big_button_setting = {
    type: defaultBigButtonSetting.type,
    ...big_button_setting,
  };
  const new_medium_button_setting = {
    type: defaultMediumButtonSetting.type,
    ...medium_button_setting,
  };
  const new_small_button_setting = {
    type: defaultSmallButtonSetting.type,
    ...small_button_setting,
  };
  const { font_size, line_height, font_weight } =
    new_medium_button_setting || defaultMediumButtonSetting;

  const onUpdateButtonSettingData = (key, value) => {
    setButtonSettingData((state) => ({ ...state, [key]: value }));
  };

  const updateButtonSetting = (buttonKey, key, value) => {
    setButtonSettingData((state) => ({
      ...state,
      [buttonKey]: {
        ...state[buttonKey],
        [key]: value,
      },
    }));
  };
  //generate background color
  const button_bg_color_default =
    background_color ?? defaultButtonSettingData.background_color;
  const opacityColor =
    button_bg_color_default.length == COLOR_LENGTH_DEFAULT
      ? button_bg_color_default.slice(-2)
      : "";
  const parseColorValues = generateColor.parseColorValues(
    button_bg_color_default
  );

  const calculateShades = generateColor.calculateShades(
    parseColorValues[0] ?? button_bg_color_default.replace("#", "")
  );
  const calculateTints = generateColor.calculateTints(
    parseColorValues[0] ?? button_bg_color_default.replace("#", "")
  );
  const calculateShadesAndTints = [
    ...calculateTints.reverse(),
    ...calculateShades,
  ];
  const button_bg_color_setting = useMemo(() => {
    const buttonColorObj = {};
    calculateShadesAndTints.forEach((ele, index) => {
      buttonColorObj[`button-bg-color-${index + 1}`] = ele + opacityColor;
    });
    return buttonColorObj;
  }, [calculateShadesAndTints]);

  //generate button color
  const button_border_color_default =
    border_color ?? defaultButtonSettingData.border_color;
  const opacityBorderColor =
    button_border_color_default.length == COLOR_LENGTH_DEFAULT
      ? button_border_color_default.slice(-2)
      : "";
  const parseColorValuesBorder = generateColor.parseColorValues(
    button_border_color_default
  );

  const calculateShadesBorder = generateColor.calculateShades(
    parseColorValuesBorder[0] ?? button_border_color_default.replace("#", "")
  );
  const calculateTintsBorder = generateColor.calculateTints(
    parseColorValuesBorder[0] ?? button_border_color_default.replace("#", "")
  );
  const calculateShadesAndTintsBorder = [
    ...calculateTintsBorder.reverse(),
    ...calculateShadesBorder,
  ];
  const button_border_color_setting = useMemo(() => {
    const buttonBorderColorObj = {};
    calculateShadesAndTintsBorder.forEach((ele, index) => {
      buttonBorderColorObj[`button-border-color-${index + 1}`] =
        ele + opacityBorderColor;
    });
    return buttonBorderColorObj;
  }, [calculateShadesAndTintsBorder]);

  const onSave = () => {
    dispatch(
      saveButtonThemeSetting({
        enable_border: enable_border ?? defaultButtonSettingData.enable_border,
        border_color: border_color ?? defaultButtonSettingData.border_color,
        background_color:
          background_color ?? defaultButtonSettingData.background_color,
        text_color: text_color ?? defaultButtonSettingData.text_color,
        border_width: border_width ?? defaultButtonSettingData.border_width,
        radius_type: radius_type ?? defaultButtonSettingData.radius_type,
        radius_value: radius_value ?? defaultButtonSettingData.radius_value,
        big_button_setting:
          new_big_button_setting ?? defaultButtonSettingData.big_button_setting,
        medium_button_setting:
          new_medium_button_setting ??
          defaultButtonSettingData.medium_button_setting,
        small_button_setting:
          new_small_button_setting ??
          defaultButtonSettingData.small_button_setting,
        button_bg_color: button_bg_color_setting,
        button_border_color: button_border_color_setting,
        shadow_type: shadow_type ?? defaultButtonSettingData.shadow_type,
        shadow_color: shadow_color ?? defaultButtonSettingData.shadow_color,
      })
    );
    backToAllSetting();
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.THEME_SETTINGS,
      `ThemeSettings_${handleCapitalizeFirstLetter(type)}_${LABEL_CONFIG.EDIT}`,
      LABEL_CONFIG.EDIT,
      handleCapitalizeFirstLetter(type)
    );
    SuccessToast(t("ss_builder_save_succesfully_label"));
  };

  const noShadowIcon = (radius_type) => {
    let iconElement = null;
    switch (radius_type) {
      case BUTTON_SETTING_RADIUS_STYLE.SQUARE:
        iconElement = <NoShadowSquareIcon className={"m-auto"} />;
        break;
      case BUTTON_SETTING_RADIUS_STYLE.ROUNDED:
        iconElement = <NoShadowRoundedIcon className={"m-auto"} />;
        break;
      case BUTTON_SETTING_RADIUS_STYLE.CUSTOM:
        iconElement = <NoShadowCustomIcon className={"m-auto"} />;
        break;
      default:
        iconElement = <NoShadowSquareIcon className={"m-auto"} />;
    }
    return iconElement;
  };
  const softShadowIcon = (radius_type) => {
    let iconElement = null;
    switch (radius_type) {
      case BUTTON_SETTING_RADIUS_STYLE.SQUARE:
        iconElement = <SoftShadowSquareIcon className={"m-auto"} />;
        break;
      case BUTTON_SETTING_RADIUS_STYLE.ROUNDED:
        iconElement = <SoftShadowRoundedIcon className={"m-auto"} />;
        break;
      case BUTTON_SETTING_RADIUS_STYLE.CUSTOM:
        iconElement = <SoftShadowCustomIcon className={"m-auto"} />;
        break;
      default:
        iconElement = <SoftShadowSquareIcon className={"m-auto"} />;
    }
    return iconElement;
  };
  const hardShadowIcon = (radius_type) => {
    let iconElement = null;
    switch (radius_type) {
      case BUTTON_SETTING_RADIUS_STYLE.SQUARE:
        iconElement = <HardShadowSquareIcon className={"m-auto"} />;
        break;
      case BUTTON_SETTING_RADIUS_STYLE.ROUNDED:
        iconElement = <HardShadowRoundedIcon className={"m-auto"} />;
        break;
      case BUTTON_SETTING_RADIUS_STYLE.CUSTOM:
        iconElement = <HardShadowCustomIcon className={"m-auto"} />;
        break;
      default:
        iconElement = <HardShadowSquareIcon className={"m-auto"} />;
    }
    return iconElement;
  };

  const borderRadiusValue = useMemo(() => {
    let value = "0px";
    if (radius_type === "rounded") {
      value = "4px";
    } else if (radius_type === "custom") {
      value = `${radius_value}px`;
    }
    return value;
  }, [radius_type, radius_value]);

  const styleBorder =
    enable_border === true
      ? {
          borderWidth: border_width + "px",
          borderStyle: `${radius_type}`,
          borderColor: border_color,
        }
      : { border: "none" };

  const styleShadow = (shadow_type) => {
    let styles = {};
    switch (shadow_type) {
      case BUTTON_SETTING_SHADOW_STYLE.NO_SHADOW:
        styles = { boxShadow: "none" };
        break;
      case BUTTON_SETTING_SHADOW_STYLE.SOFT_SHADOW:
        styles = { boxShadow: `0px 5px 12px ${shadow_color}` };
        break;
      case BUTTON_SETTING_SHADOW_STYLE.HARD_SHADOW:
        styles = { boxShadow: `5px 5px 0px ${shadow_color}` };
        break;
      default:
        styles = { boxShadow: "none" };
    }
    return styles;
  };

  return (
    <>
      <div className="w-full pb-14">
        <SettingHeader
          title={t("ss_builder_button_setting_title")}
          Icon={ButtonIcon}
        />
        <Divider style={{ margin: "0" }} />
        <div className="preview-button-block mb-[13px] bg-white sticky z-[999] top-0">
          <div className=" py-3 px-4 w-full h-full preview-general-block text-center flex justify-center">
            <div
              className="w-full h-full flex justify-center py-3 rounded"
              style={{
                border: "1px solid #EEEEEE",
                backgroundColor: "#FAFAFA",
              }}
            >
              <Button
                color="primary"
                className={cls(
                  "flex items-center adm-button adm-button-primary adm-button-shape-default gap-2"
                )}
                size="middle"
                style={{
                  backgroundColor: background_color,
                  color: text_color,
                  fontSize: font_size,
                  lineHeight: line_height,
                  fontWeight: font_weight,
                  borderRadius: borderRadiusValue,
                  ...styleBorder,
                  ...styleShadow(shadow_type),
                }}
              >
                <SettingIcon
                  width={font_size}
                  height={font_size}
                  fillColor={text_color}
                />
                {t("ss_builder_button_title")}
              </Button>
            </div>
          </div>
        </div>
        <Collapse
          defaultActiveKey={[
            "general",
            "big_button",
            "medium_button",
            "small_button",
          ]}
          accordion={false}
          arrow={(active) => (active ? <DropDownIcon1 /> : <DropDownIcon2 />)}
        >
          <Collapse.Panel
            key="general"
            title={
              <>
                <div className="flex">
                  <span className="mr-1">
                    <IconGeneral />
                  </span>
                  <span>{t("ss_builder_theme_button_setting_general")}</span>
                </div>
              </>
            }
          >
            <div className="mb-3">
              <div className="text-base mb-4">
                {t("ss_builder_enable_button_border")}
              </div>
              <Switch
                defaultChecked={enable_border}
                uncheckedText={t("ss_builder_off")}
                checkedText={t("ss_builder_on")}
                onChange={(value) =>
                  onUpdateButtonSettingData("enable_border", value)
                }
              />
            </div>
            <Divider />
            <div className="flex mb-3">
              {enable_border && (
                <>
                  <div className="mr-3">
                    <DisplayColor
                      backgroundColor={border_color}
                      onClickOpenColorPicker={setIsOpenBorderColorPicker}
                    />
                    <div className="text-sm mb-4 text-black mt-3">
                      {t("ss_builder_button_border_color")}
                    </div>
                  </div>
                </>
              )}

              <div className="mr-3">
                <div className="mb-3">
                  <DisplayColor
                    backgroundColor={background_color}
                    onClickOpenColorPicker={setIsOpenBackgroundColorPicker}
                  />
                  <div className="text-sm mb-4 text-black mt-3">
                    {t("ss_builder_button_background_color")}
                  </div>
                </div>
              </div>
              <div className="mr-3">
                <div className="mb-3">
                  <DisplayColor
                    backgroundColor={text_color}
                    onClickOpenColorPicker={setIsOpenTextColorPicker}
                  />
                  <div className="text-sm mb-4 text-black mt-3">
                    {t("ss_builder_button_text_color")}
                  </div>
                </div>
              </div>
            </div>
            {enable_border && (
              <>
                <Divider />
                <div className="mb-3">
                  <div className="text-base mb-4">
                    {t("ss_builder_button_border_width")}
                  </div>
                  <Slider
                    onChange={(val) =>
                      onUpdateButtonSettingData(
                        "border_width",
                        parseInt(val.toString())
                      )
                    }
                    marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6 }}
                    min={1}
                    max={6}
                    step={1}
                    value={border_width}
                  />
                </div>
              </>
            )}

            <Divider />
            <div className="text-base mb-1">
              {t("ss_builder_border_radius_style")}
            </div>
            <Selector
              className="mb-3"
              columns={3}
              onChange={(val) => {
                if (val.length) {
                  onUpdateButtonSettingData(
                    "radius_type",
                    val[0] ?? BUTTON_SETTING_RADIUS_STYLE.SQUARE
                  );
                }
              }}
              options={[
                {
                  label: <NoShadowSquareIcon className={"m-auto"} />,
                  description: t("ss_builder_square"),
                  value: BUTTON_SETTING_RADIUS_STYLE.SQUARE,
                },
                {
                  label: <NoShadowRoundedIcon className={"m-auto"} />,
                  description: t("ss_builder_rounded"),
                  value: BUTTON_SETTING_RADIUS_STYLE.ROUNDED,
                },
                {
                  label: <NoShadowCustomIcon className={"m-auto"} />,
                  description: t("ss_builder_custom"),
                  value: BUTTON_SETTING_RADIUS_STYLE.CUSTOM,
                },
              ]}
              value={[radius_type]}
              style={{
                "--checked-color": "#fff",
              }}
            />
            {radius_type == BUTTON_SETTING_RADIUS_STYLE.CUSTOM && (
              <div className="mb-3">
                <Divider style={{ marginTop: "24px" }} />
                <div className="text-base mb-2 flex justify-between">
                  <span>{t("ss_builder_border_radius_label")}</span>
                  <span className="primary-color text-sm">
                    {radius_value} px
                  </span>
                </div>
                <Slider
                  onChange={(val) =>
                    onUpdateButtonSettingData(
                      "radius_value",
                      parseInt(val.toString())
                    )
                  }
                  marks={masks}
                  min={0}
                  step={2}
                  max={48}
                  popover={true}
                  defaultValue={radius_value}
                />
              </div>
            )}
            <Divider style={{ marginTop: "24px" }} />
            <div className="text-base mb-1">
              {t("ss_builder_theme_setting_shadow")}
            </div>
            <Selector
              className="mb-3"
              columns={3}
              onChange={(val) => {
                if (val.length) {
                  onUpdateButtonSettingData(
                    "shadow_type",
                    val[0] ?? BUTTON_SETTING_SHADOW_STYLE.NO_SHADOW
                  );
                }
              }}
              options={[
                {
                  label: noShadowIcon(radius_type),
                  description: t("ss_builder_theme_setting_no_shadow"),
                  value: BUTTON_SETTING_SHADOW_STYLE.NO_SHADOW,
                },
                {
                  label: softShadowIcon(radius_type),
                  description: t("ss_builder_theme_setting_soft_shadow"),
                  value: BUTTON_SETTING_SHADOW_STYLE.SOFT_SHADOW,
                },
                {
                  label: hardShadowIcon(radius_type),
                  description: t("ss_builder_theme_setting_hard_shadow"),
                  value: BUTTON_SETTING_SHADOW_STYLE.HARD_SHADOW,
                },
              ]}
              value={[shadow_type]}
              style={{
                "--checked-color": "#fff",
              }}
            />
            <Divider style={{ marginTop: "24px" }} />
            <div className="text-base mb-1">
              {t("ss_builder_theme_setting_shadow_color")}
            </div>
            <DisplayColor
              backgroundColor={shadow_color}
              onClickOpenColorPicker={setIsOpenShadowColorPicker}
            />
          </Collapse.Panel>
          <Collapse.Panel
            key="medium_button"
            title={
              <>
                <div className="flex">
                  <span className="mr-1">
                    <ButtonIcon />
                  </span>
                  <span>{t("ss_builder_theme_setting_text")}</span>
                </div>
              </>
            }
          >
            <DetailButtonSetting
              value={new_medium_button_setting ?? defaultMediumButtonSetting}
              onUpdate={(key, value) => {
                updateButtonSetting("medium_button_setting", key, value);
              }}
              buttonSettingData={buttonSettingData ?? {}}
            />
          </Collapse.Panel>
        </Collapse>
      </div>
      <div className="max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[10] bg-[#FFFFFF]">
        <div className="w-1/2 text-center">
          <Button
            onClick={onCancel}
            className="adm-button adm-button-default adm-button-shape-default w-full"
          >
            {t("ss_builder_close_panel")}
          </Button>
        </div>
        <div className="w-1/2 text-center">
          <Button
            color="primary"
            disabled={!hasChange}
            className="adm-button adm-button-primary adm-button-shape-default w-full"
            onClick={() => {
              onSave();
            }}
          >
            {t("ss_builder_save")}
          </Button>
        </div>
      </div>
      <PopupComponent
        visible={isOpenBorderColorPicker}
        onMaskClick={() => setIsOpenBorderColorPicker(false)}
        title={t("ss_builder_color_picker_panel_title")}
        onClose={() => setIsOpenBorderColorPicker(false)}
        classNameBody="h-[60vh] popup-hard"
      >
        <ColorPickerV2
          defaultColor={border_color}
          onCancel={() => {
            setIsOpenBorderColorPicker(false);
          }}
          onColorSelected={(value) =>
            onUpdateButtonSettingData("border_color", value)
          }
        />
      </PopupComponent>

      <PopupComponent
        visible={isOpenBackgroundColorPicker}
        onMaskClick={() => setIsOpenBackgroundColorPicker(false)}
        title={t("ss_builder_color_picker_panel_title")}
        onClose={() => setIsOpenBackgroundColorPicker(false)}
        classNameBody="h-[60vh] popup-hard"
      >
        <ColorPickerV2
          defaultColor={background_color}
          onCancel={() => {
            setIsOpenBackgroundColorPicker(false);
          }}
          onColorSelected={(value) =>
            onUpdateButtonSettingData("background_color", value)
          }
        />
      </PopupComponent>

      <PopupComponent
        visible={isOpenTextColorPicker}
        onMaskClick={() => setIsOpenTextColorPicker(false)}
        title={t("ss_builder_color_picker_panel_title")}
        onClose={() => setIsOpenTextColorPicker(false)}
        classNameBody="h-[60vh] popup-hard"
      >
        <ColorPickerV2
          defaultColor={text_color}
          onCancel={() => {
            setIsOpenTextColorPicker(false);
          }}
          onColorSelected={(value) =>
            onUpdateButtonSettingData("text_color", value)
          }
        />
      </PopupComponent>

      <PopupComponent
        visible={isOpenShadowColorPicker}
        onMaskClick={() => setIsOpenShadowColorPicker(false)}
        title={t("ss_builder_color_picker_panel_title")}
        onClose={() => setIsOpenShadowColorPicker(false)}
        classNameBody="h-[60vh] popup-hard"
      >
        <ColorPickerV2
          defaultColor={shadow_color}
          onCancel={() => {
            setIsOpenShadowColorPicker(false);
          }}
          onColorSelected={(value) =>
            onUpdateButtonSettingData("shadow_color", value)
          }
        />
      </PopupComponent>
    </>
  );
}
