import React, { useEffect } from "react";

const TextColor = ({ textColorGenerate }) => {
  useEffect(() => {
    if (textColorGenerate) {
      for (var i = 1; i <= 10; i++) {
        if (textColorGenerate[`text-color-${i}`]) {
          document.documentElement.style.setProperty(
            `--text-color-${i}`,
            textColorGenerate[`text-color-${i}`]
          );
        }
      }
    }
  }, [textColorGenerate]);
  return null;
};

export default TextColor;
