import {
  THEME_PREVIEW_KEY,
} from "@share/configs/const";
import {
  BackAngelIcon,
  CrownIcon,
  CrownIconNoColor,
  CrownIconV2,
  InformationTriangleIcon,
  PreviewThemeIcon,
  ThemeStoreIcon,
  XIconV2,
} from "@share/icons";
import { Button, Input, Modal, Popover } from "antd-mobile";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "usehooks-ts";
import gaEvents, { ACTION_CONFIG, CATEGORY_CONFIG, EVENT_CONFIG } from "@share/lib/ga-events";
import { InfoCirclesIcon } from "@share/icons/line-icon";
import { useAppSelector } from "@app/hooks";
import { CREATOR_ROLE_ENUM } from "@features/main/store/interface";
import { useModal } from "@share/hooks/modal";
import { getPackageOfUser, getPackageSass } from "@share/api";
import FullScreenPopup from "@share/components/full-screen-popup";
import { useDispatch } from "react-redux";
import { setProfile } from "@features/user/userSlice";
import ButtonViewTheme from "./component/ButtonViewTheme";
import ListItemScrollHorizontal from "../../ListItemScrollHorizontal";
import cloneDeep from "lodash/cloneDeep";
import style from "./style.module.scss";
import classNames from "classnames";
import { handleCapitalizeFirstLetter } from "@share/lib";

const BUTTON_VIEW_THEME = {
  VIEW: "view",
  PREVIEW: "preview",
}

const ThemeTemplatePreview = (props) => {
  const {
    selectedThemeData,
    user,
    onCancelThemeTemplatePreview,
    onConfirmThemeTemplatePreview,
    KEY,
    siteSettingUserRootId = null,
    userRootId = null,
    isViewTheme = false,
  } = props;
  const dispatch = useDispatch();
  const KOL_PRICING = process.env.NEXT_PUBLIC_PASSIO_SASS_KOL_PRICING;
  const [dataTheme, setDataTheme] = useState(selectedThemeData);
  const [packageUser, setPackageUser] = useState({
    package_type: user?.kol?.package_type,
    package_name: user?.kol?.package_name,
  });
  const [flag, setFlag] = useState(false);
  const [openIframe, setOpenIframe] = useState(false);
  const { t } = useTranslation();
  const themeName = dataTheme?.title;
  const { ConfirmModal } = useModal();

  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);
  const creator_role = passioPageProfile?.creator_role;
  const isAdminRole = useMemo(
    () => creator_role === CREATOR_ROLE_ENUM.ADMIN,
    [creator_role]
  );

  useEffect(() => {
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.THEME_TEMPLATE,
      `ThemeTemplate_${ACTION_CONFIG.VIEW}_${themeName}`,
      themeName,
      ACTION_CONFIG.VIEW
    );
  }, []);

  useEffect(() => {
    const fetchPermission = async () => {
      try {
        const getPackageOfUse = await getPackageOfUser();
        const packageOfUser = {
          package_type: getPackageOfUse?.data?.data?.language?.id,
          package_name: getPackageOfUse?.data?.data?.language?.name,
        };
        dispatch(setProfile({ ...user?.kol, ...packageOfUser }));
        setPackageUser(packageOfUser);
      } catch (e) {
        console.log(e);
      }
    };
    const fetchPackageSASS = async () => {
      try {
        const dataSass = (await getPackageSass()) as any;
        const listThemPremium = dataSass?.data?.data?.packages?.find(
          (item) => item.id === 1
        )?.params?.passio_page_service?.premium_theme;
        setDataTheme({
          ...selectedThemeData,
          is_premium: listThemPremium?.includes(selectedThemeData?.id),
        });
      } catch (e) {
        console.log(e);
      }
    };
    fetchPermission();
    fetchPackageSASS();
  }, [openIframe]);

  const CONFIRM_TEXT = "OK";

  const [valueConfirmText, setValueConfirmText] = useState(null);
  const [isOpenConfirmEnterText, setIsOpenConfirmEnterText] = useState(false);
  const [isViewThemeState, setIsViewThemeState] = useState(false);
  const debouncedValueConfirmText = useDebounce(valueConfirmText, 500);
  const [popOverState, setpopOverState] = useState({
    preview: false,
    view: false,
  });
  const handleCompareConfirmText = (val) => {
    setValueConfirmText(val);
  };
  const handleCloseModalConfirmText = () => {
    setIsOpenConfirmEnterText(false);
    setValueConfirmText(null);
  };
  const isEnableConfirm = useMemo(
    () =>
      !(
        typeof debouncedValueConfirmText === "string" &&
        debouncedValueConfirmText.toLowerCase() === CONFIRM_TEXT.toLowerCase()
      ),
    [debouncedValueConfirmText]
  );

  const previewUrl = useMemo(() => {
    const domain = user?.kol?.domain;
    const siteSettingId = dataTheme?.site_setting_id;
    if (isViewThemeState) {
      return `https://${domain}/preview?id=${siteSettingId}&isDisableLink=true`;
    }
    if (siteSettingUserRootId && userRootId) {
      return `https://${domain}/preview?id=${siteSettingId}&site_setting_user_root_id=${siteSettingUserRootId}&user_root_id=${userRootId}&isDisableLink=true`;
    }
    if (siteSettingUserRootId && !userRootId) {
      return `https://${domain}/preview?id=${siteSettingId}&site_setting_user_root_id=${siteSettingUserRootId}&isDisableLink=true`;
    }

    return `https://${domain}/preview?id=${siteSettingId}&isDisableLink=true`;
  }, [user, dataTheme, siteSettingUserRootId, userRootId, isViewThemeState]);

  const hasPermission = useMemo(() => {
    if (user?.kol?.country !== "PH") return true;
    if (packageUser?.package_type === 1 || isAdminRole) return true;
    return packageUser?.package_type === 2 && !dataTheme?.is_premium;
  }, [user, dataTheme, isAdminRole, packageUser]);

  const isShowIcon = useMemo(() => {
    return (
      (packageUser?.package_type === 1 || isAdminRole) && dataTheme?.is_premium
    );
  }, [packageUser, dataTheme, isAdminRole]);

  const handleConfirmThemeTemplatePreview = async () => {
    let hasPermissionFetchNew = false;
    try {
      const getPackageOfUse = await getPackageOfUser();
      const dataSass = (await getPackageSass()) as any;
      const listThemPremiumFetchNew = dataSass?.data?.data?.packages?.find(
        (item) => item.id === 1
      )?.params?.passio_page_service?.premium_theme;
      const packageOfUserFetchNew = {
        package_type: getPackageOfUse?.data?.data?.language?.id,
        package_name: getPackageOfUse?.data?.data?.language?.name,
      };
      const dataThemeFetchNew = {
        ...selectedThemeData,
        is_premium: listThemPremiumFetchNew?.includes(selectedThemeData?.id),
      };
      dispatch(setProfile({ ...user?.kol, ...packageOfUserFetchNew }));
      setPackageUser(packageOfUserFetchNew);
      if (packageOfUserFetchNew?.package_type === 1 || isAdminRole) {
        hasPermissionFetchNew = true;
      } else {
        hasPermissionFetchNew =
          packageUser?.package_type === 2 && !dataThemeFetchNew?.is_premium;
      }
      if (user?.kol?.country !== "PH") hasPermissionFetchNew = true;
    } catch (e) {
      console.log(e);
    }
    if (!hasPermissionFetchNew) {
      Modal.alert({
        className: "confirm-preview-apply-content ",
        bodyClassName: "custom-button-confirm-modal-alert",
        header: (
          <CrownIcon
            width={60}
            height={60}
          />
        ),
        title: <div>{t("ss_builder_apply_theme_premium_warning_title")}</div>,
        content: (
          <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
            {t("ss_builder_apply_theme_premium_warning")}
          </div>
        ),
        confirmText: t("ss_builder_update_account"),
        showCloseButton: true,
        onConfirm: () => {
          gaEvents.submitEventV2(
            passioPageProfile,
            CATEGORY_CONFIG.THEME_TEMPLATE,
            `${EVENT_CONFIG.THEME_TEMPLATE}_${handleCapitalizeFirstLetter(ACTION_CONFIG.UPGRADE_PLAN)}_${selectedThemeData?.title}`,
            '',
            ACTION_CONFIG.UPGRADE_PLAN
          );
          setOpenIframe(true);
        },
      });
    } else {
      if (KEY === THEME_PREVIEW_KEY.PREVIEW_AND_APPLY_CONTENT) {
        ConfirmModal({
          title: t("ss_builder_replace_theme_modal_title"),
          className: "confirm-preview-apply-content",
          description: t("ss_builder_replace_theme_modal_content"),
          confirmText: t("ss_builder_confirm_replace_theme_template_btn_label"),
          cancelText: t("ss_builder_cancel_replace_theme_template_btn_label"),
          onConfirm: () => setIsOpenConfirmEnterText(true),
          onCancel: () => {
            gaEvents.submitEventV2(
              passioPageProfile,
              CATEGORY_CONFIG.APPLY_SAMPLE_CONTENT,
              "ApplySampleContent_Cancel",
              user?.kol?.domain,
              ACTION_CONFIG.CANCEL
            );
            return;
          },
        });
      }
      if (KEY === THEME_PREVIEW_KEY.PREVIEW_AND_APPLY) {
        ConfirmModal({
          title: t("ss_builder_confirm_apply_theme_template_title"),
          icon: "info-primary-triangle",
          // className: "confirm-preview-apply-content",
          description: t("ss_builder_confirm_apply_theme_template_content"),
          confirmText: t("ss_builder_confirm_apply_theme_template_btn_label"),
          cancelText: t("ss_builder_cancel_apply_theme_template_btn_label"),
          onConfirm: () => {
            onConfirmThemeTemplatePreview();
            gaEvents.submitEventV2(
              passioPageProfile,
              CATEGORY_CONFIG.THEME_TEMPLATE,
              `ThemeTemplate_${ACTION_CONFIG.APPLY}_${themeName}`,
              themeName,
              ACTION_CONFIG.APPLY
            );
          },
          onCancel: () => {
            return;
          },
        });
      }
    }
  };

  const handleCancelThemeTemplatePreview = () => {
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.THEME_TEMPLATE,
      `ThemeTemplate_${ACTION_CONFIG.CANCEL}_${themeName}`,
      themeName,
      ACTION_CONFIG.CANCEL
    );
    onCancelThemeTemplatePreview();
  };
  const handleSwapViewTheme = (isView, nameButton) => {
    if (isViewThemeState !== isView) {
      setIsViewThemeState(isView);
      const popOverStateTmp = cloneDeep(popOverState);
      for (let key in popOverStateTmp) {
        if (key !== nameButton) {
          popOverStateTmp[key] = false;
        }
      }
      setpopOverState(popOverStateTmp);
    }
  };

  const handleMessage = (event) => {
    const data = event.data?.key;
    const isMatchDomain = event.origin === process.env.NEXT_PUBLIC_PASSIO_ACCOUNT;
    if (!!data && isMatchDomain) setOpenIframe(false);
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage, false);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  const handleShowPopOver = (visible, nameButton) => {
    const popOverStateTmp = cloneDeep(popOverState);
    for (let key in popOverStateTmp) {
      if (key === nameButton) {
        popOverStateTmp[key] = visible;
      } else if (key !== nameButton && visible) {
        popOverStateTmp[key] = !visible;
      }
    }

    setpopOverState(popOverStateTmp);
  }

  return (
    <>
      <div
        className="bg-white"
        style={{
          borderBottom: "1px solid rgb(238, 238, 238)",
        }}
      >
        <div className="flex flex-row text-center items-center justify-center py-1.5 gap-[10px] sticky top-0 max-width-content-class ml-auto mr-auto">
          <div className="text-[18px] leading-6">
            <span
              className="p-1 text-center absolute top-[50%] translate-y-[-50%] left-0 cursor-pointer"
              onClick={() => handleCancelThemeTemplatePreview()}
            >
              <BackAngelIcon />
            </span>
            <ListItemScrollHorizontal>
              <ButtonViewTheme
                isActive={!isViewThemeState}
                icon={<PreviewThemeIcon width={16} height={16} fillColor={!isViewThemeState ? "#FFFFFF" : "#333333"} />}
                label={t("ss_builder_preview")}
                extra={
                  <Popover
                    content={t("ss_builder_theme_template_preview_info")}
                    trigger="click"
                    placement="bottom"
                    mode="dark"
                    visible={popOverState.preview}
                    onVisibleChange={(visible) => handleShowPopOver(visible, BUTTON_VIEW_THEME.PREVIEW)}
                  >
                    <div
                      className="w-[31px] flex items-center justify-center h-full py-2"
                    >
                      <InfoCirclesIcon
                        className="cursor-pointer"
                        width={15}
                        height={15}
                        fillColor={!isViewThemeState ? "#FFFFFF" : "#333333"}
                      />
                    </div>
                  </Popover>
                }
                onClick={() => handleSwapViewTheme(false, BUTTON_VIEW_THEME.PREVIEW)}
              />

              <ButtonViewTheme
                isActive={isViewThemeState}
                icon={<ThemeStoreIcon width={16} height={16} fillColor={isViewThemeState ? "#FFFFFF" : "#333333"} />}
                label={t("ss_builder_theme_template_view_theme_title")}
                extra={
                  <Popover
                    content={t("ss_builder_theme_template_view_theme_info")}
                    trigger="click"
                    placement="bottom"
                    mode="dark"
                    visible={popOverState.view}
                    onVisibleChange={(visible) => handleShowPopOver(visible, BUTTON_VIEW_THEME.VIEW)}
                  >
                    <div
                      className="w-[31px] flex items-center justify-center h-full py-2"
                    >
                      <InfoCirclesIcon
                        className="cursor-pointer"
                        width={15}
                        height={15}
                        fillColor={isViewThemeState ? "#FFFFFF" : "#333333"}
                      />
                    </div>
                  </Popover>
                }
                onClick={() => handleSwapViewTheme(true, BUTTON_VIEW_THEME.VIEW)}
              />
            </ListItemScrollHorizontal>
          </div>
        </div>
      </div>
      <div
        className=""
        style={{
          height: !isViewThemeState ? "calc(100% - 105px)" : "calc(100% - 45px)",
        }}
      >
        <iframe
          id="theme-preview-iframe"
          src={previewUrl}
          className="h-full w-full"
        />
      </div>
      <div className={classNames("fixed bottom-0 p-2 w-full bg-[#FFFFFF]", {
        [style.showing]: !isViewThemeState,
        [style.notShowing]: isViewThemeState,
      })}>
        <div className={classNames("max-width-content-class fixed left-[50%] translate-x-[-50%] bottom-0 p-2 flex w-full justify-between gap-2 z-[1] bg-[#FFFFFF]", {
          [style.showing]: !isViewThemeState,
          [style.notShowing]: isViewThemeState,
        })}>
          <div className="w-1/2 text-center">
            <Button
              className="w-full"
              onClick={handleCancelThemeTemplatePreview}
            >
              {t("ss_builder_editor_btn-cancel")}
            </Button>
          </div>
          {hasPermission ? (
            <div className="w-1/2 text-center">
              <Button
                className="w-full"
                color={"primary"}
                onClick={() => {
                  setFlag(!flag);
                  handleConfirmThemeTemplatePreview();
                }}
              >
                <div className="flex gap-2 justify-center text-center items-center">
                  {isShowIcon && <CrownIconV2 className="my-auto" />}
                  {t(
                    "ss_builder_confirm_theme_template_preview_mode_btn_label"
                  )}
                </div>
              </Button>
            </div>
          ) : (
            <div className="w-1/2 text-center">
              <Button
                className="w-full"
                onClick={() => {
                  setFlag(!flag);
                  handleConfirmThemeTemplatePreview();
                }}
                style={{ backgroundColor: "#fa8c16", color: "white" }}
              >
                <div className="flex gap-2 justify-center text-center items-center">
                  <CrownIconNoColor className="my-auto" />
                  <span className="text-white">
                    {t(
                      "ss_builder_confirm_theme_template_preview_mode_btn_label"
                    )}
                  </span>
                </div>
              </Button>
            </div>
          )}
        </div>
      </div>

      {isOpenConfirmEnterText && (
        <Modal
          visible={isOpenConfirmEnterText}
          content={
            <>
              <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
                {t("ss_builder_confirm_replace_theme_template_content")}
              </div>
              <div className="mt-3 mb-1 text-[#666]">
                {t("ss_builder_confirm_replace_theme_template_label_enter_ok")}
              </div>
              <Input
                placeholder={t(
                  "ss_builder_confirm_replace_theme_template_input_placeholder"
                )}
                onChange={(val) => {
                  handleCompareConfirmText(val);
                }}
                className="input-rounded"
                maxLength={20}
                minLength={1}
              />
            </>
          }
          closeOnAction
          onClose={() => {
            gaEvents.submitEventV2(
              passioPageProfile,
              CATEGORY_CONFIG.APPLY_SAMPLE_CONTENT,
              "ApplySampleContent_Cancel",
              user?.kol?.domain,
              ACTION_CONFIG.CANCEL
            );
            handleCloseModalConfirmText();
          }}
          className="confirm-text-preview-apply-content"
          header={
            <InformationTriangleIcon
              className="w-11 h-11"
              fillColor="#FF8F1F"
              onClick={() => { }}
            />
          }
          title={
            <div>{t("ss_builder_confirm_replace_theme_template_warining")}</div>
          }
          actions={[
            {
              key: "confirm",
              text: t("ss_builder_confirm_replace_theme_template_understood"),
              className: "confirm-text",
              primary: true,
              disabled: isEnableConfirm,
              onClick: () => onConfirmThemeTemplatePreview(),
            },
            {
              key: "cancel",
              text: t("ss_builder_cancel_replace_theme_template_btn_label"),
              className: "cancel-text",
            },
          ]}
        />
      )}

      <FullScreenPopup
        visible={openIframe}
        onMaskClick={() => setOpenIframe(false)}
      >
        <div className="h-full w-full relative max-w-[414px]">
          <iframe
            id="iframe-sass"
            src={KOL_PRICING}
            className="h-full w-full"
          />
          <div
            className="absolute top-4 right-4 cursor-pointer"
            onClick={() => setOpenIframe(false)}
          >
            <XIconV2 />
          </div>
        </div>
      </FullScreenPopup>
    </>
  );
};

export default ThemeTemplatePreview;
