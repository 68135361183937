import React from "react";
import { Image, Button } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { AddIcon, EmptyIcon } from "@share/icons";
import { useDesignContext } from "../../context/context";
const EmptyBlock = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  const { setSelectedBlock, setIsOpenAddBlockFloatingPanel } =
    useDesignContext();

  const handleAddBlock = () => {
    // setSelectedBlock({position: 'body', uid: 'my-choice-26573-Cw8tLdT6tv'})
    setSelectedBlock(data);
    setIsOpenAddBlockFloatingPanel(true);
  };
  return (
    <div
      className=" items-center w-[auto]  block-class mb-3"
      style={{ padding: "20px 0px" }}
    >
      <div>
        {/*<Image*/}
        {/*  src="https://image.passio.eco/page-builder/page-builder/empty-block/image-placeholder%20_-%20Change%20image%20here.png"*/}
        {/*  fit="cover"*/}
        {/*  width="260px"*/}
        {/*  height="80px"*/}
        {/*  className="m-auto"*/}
        {/*/>*/}
        <EmptyIcon className="m-auto" />
      </div>
      <div
        className="mt-[8px] default-font-family text-center design-class lead-2"
        style={{ color: "var(--text-color-6)" }}
      >
        {t("ss_builder_empty_block")}
      </div>
      <div
        className="mt-[4px] text-center default-font-family design-class"
        style={{ color: "var(--text-color-5)" }}
      >
        {t("ss_build_no_blocks_have_been_added")}
      </div>
      <div className="mt-[8px] flex justify-center">
        <Button
          style={{
            backgroundColor: "#2563EB",
            display: "flex",
            color: "#FFFFFF",
            border: "none",
            fontSize: "15px",
            fontWeight: "21px",
            gap: "8px",
          }}
          className=" default-font-family font-normal"
          onClick={() => handleAddBlock()}
        >
          <AddIcon
            className="m-auto"
            width={15}
            height={15}
          />
          <span> {t("ss_builder_add_block_label")}</span>
        </Button>
      </div>
    </div>
  );
};
export default EmptyBlock;
