import {
  createManyTagThemeTemplate,
  createTagThemeTemplate,
  requestSearchTag,
  requestSearchThemeTemplate,
} from "@share/api";
import FullScreenPopup from "@share/components/full-screen-popup";
import {
  MAX_WIDTH_CONTENT_LAYOUT,
  THEME_PREVIEW_BY_IMAGE_KEY,
  THEME_TEMPLATE_KEY,
} from "@share/configs/const";
import {
  InformationIcon,
  InformationTriangleIcon,
  PreviewThemeIcon,
  SaveIcon,
  UploadIcon,
} from "@share/icons";
import { mockUpload } from "@share/lib";
import {
  Button,
  Divider,
  Image,
  ImageUploader,
  Input,
  Modal,
} from "antd-mobile";
import React, {
  KeyboardEventHandler,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { themeTemplateInterface } from "../ThemeTemplateLists";
import ThemeTemplatePreviewByImage from "../ThemeTemplatePreviewByImage";
import CreatableSelect from "react-select/creatable";
import style from "./style.module.scss";
import { useDebounce } from "usehooks-ts";
import { useToast } from "@share/hooks/toast";
import { useAppSelector } from "@app/hooks";
type Props = {
  handleCancelThemeSaving: any;
  KEY: string;
  themeTemplateData?: themeTemplateInterface;
  handleConfirmThemeSaving: any;
};
interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string) => ({
  label,
  value: label,
});

const THEME_NAME_MAXIMUM = 25;
const KEY_ENTER = "Enter";
const KEY_COMMA = ",";

const ThemeTemplateSaving = (props: Props) => {
  const {
    handleCancelThemeSaving,
    KEY,
    themeTemplateData,
    handleConfirmThemeSaving,
  } = props;
  const { t } = useTranslation();
  const themeImageDefault = themeTemplateData?.image;
  const themeTitleDefault = themeTemplateData?.title;
  const user = useAppSelector((state) => state.user);
  const userId = user?.kol?._id;
  const tagsDefault = themeTemplateData?.tags;
  const tags = tagsDefault?.map((ele) => {
    return {
      label: ele,
      value: ele,
    };
  });
  const regexPattern = /\s+/g;
  const [themeNameValue, setThemeNameValue] = useState(themeTitleDefault);
  const [valueUrlImage, setValueUrlImage] = useState(themeImageDefault);
  const [valueNameImage, setValueNameImage] = useState("");
  const [valueImageBase64, setValueImageBase64] = useState(null);
  const [uploadedImageArr, setUploadedImageArr] = useState([]);
  const [isValidFileFormat, setIsValidFileFormat] = useState(false);
  const [isValidFileSize, setIsValidFileSize] = useState(false);
  const [isOpenThemeTemplatePreview, setIsOpenThemeTemplatePreview] =
    useState(false);
  const [themeTemplateArr, setThemeTemplateArr] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [inputValueTag, setInputValueTag] = useState("");
  const [valueTag, setValueTag] = React.useState<Option[]>(tags ?? []);
  const [tagThemeTemplate, setTagThemeTemplate] = useState(tagsDefault);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const requestTagList = async (keyword = "") => {
    let listTagResponse = null;
    const dataRequest = {
      per_page: 12,
      keyword: keyword
        ? keyword.trim().replaceAll(regexPattern, " ").replaceAll(KEY_COMMA, "")
        : "",
    };
    try {
      listTagResponse = await requestSearchTag(dataRequest);
    } catch (error) {
      console.log(error);
    }
    const options = listTagResponse?.data.map((ele) => {
      return {
        value: ele.title,
        label: ele.title,
      };
    });
    setSuggestions(options ?? []);
  };
  useEffect(() => {
    const requestThemeTemplateList = async () => {
      let listThemeResponse = null;
      const dataRequest = {
        per_page: 100,
        keyword: "",
      };
      try {
        listThemeResponse = await requestSearchThemeTemplate(dataRequest);
      } catch (error) {
        console.log(error);
      }
      const listThemeArr = listThemeResponse?.data;
      if (listThemeArr) setThemeTemplateArr(listThemeArr);
    };
    requestThemeTemplateList();
  }, []);

  const checkDuplicateNameTheme = useMemo(() => {
    let themeDuplicatedName = null;
    if (KEY === THEME_TEMPLATE_KEY.SAVE_NEW) {
      themeDuplicatedName = themeTemplateArr?.find(
        (x) =>
          x?.title?.trim()?.toLowerCase() ===
          themeNameValue?.trim()?.toLowerCase()
      );
    } else if (
      KEY === THEME_TEMPLATE_KEY.EDIT ||
      KEY === THEME_TEMPLATE_KEY.OVERWRITE
    ) {
      themeDuplicatedName = themeTemplateArr
        ?.filter((a) => a.id !== themeTemplateData?.id)
        ?.find(
          (x) =>
            x?.title?.trim()?.toLowerCase() ===
            themeNameValue?.trim()?.toLowerCase()
        );
    }
    if (themeDuplicatedName) {
      return true;
    } else return false;
  }, [themeTemplateArr, themeNameValue]);

  const handleImageUrl = (value) => {
    const imgFileItems = value && value[value.length - 1];
    if (imgFileItems?.isValidFormat) {
      setIsValidFileFormat(true);
      setIsValidFileSize(false);
    } else if (imgFileItems?.isValidSize) {
      setIsValidFileFormat(false);
      setIsValidFileSize(true);
    } else {
      setUploadedImageArr(value);
      setValueImageBase64(imgFileItems.dataBase64);
      setValueUrlImage(imgFileItems.url);
      setValueNameImage(imgFileItems.fileName);
      setIsValidFileFormat(false);
      setIsValidFileSize(false);
    }
  };

  const handleChangeThemeTemplateName = (val) => {
    if (val.length <= THEME_NAME_MAXIMUM) {
      setThemeNameValue(val);
    }
  };

  const titlePageRender = useMemo(() => {
    let titleValue = "";
    switch (KEY) {
      case THEME_TEMPLATE_KEY.SAVE_NEW:
        titleValue = t("ss_builder_save_new_theme_template_title");
        break;
      case THEME_TEMPLATE_KEY.OVERWRITE:
        titleValue = t("ss_builder_overwrite_theme_template_title");
        break;
      case THEME_TEMPLATE_KEY.EDIT:
        titleValue = t("ss_builder_edit_theme_template_title");
        break;
    }
    return titleValue;
  }, [KEY]);

  const buttonSaveLabelRender = useMemo(() => {
    let btnLabel = "";
    switch (KEY) {
      case THEME_TEMPLATE_KEY.SAVE_NEW:
        btnLabel = t("ss_builder_add_new_theme_btn_label");
        break;
      case THEME_TEMPLATE_KEY.OVERWRITE:
        btnLabel = t("ss_builder_overwrite_theme_btn_label");
        break;
      case THEME_TEMPLATE_KEY.EDIT:
        btnLabel = t("ss_builder_edit_theme_btn_label");
        break;
    }
    return btnLabel;
  }, [KEY]);

  const handleOpenThemeTemplatePreview = () => {
    setIsOpenThemeTemplatePreview(true);
  };

  const isDisableSaveBtn = useMemo(() => {
    if (
      (valueUrlImage && themeNameValue && !checkDuplicateNameTheme) ||
      (KEY === THEME_TEMPLATE_KEY.EDIT &&
        !checkDuplicateNameTheme &&
        ((valueUrlImage && valueUrlImage !== themeImageDefault) ||
          (themeNameValue && themeNameValue !== themeTitleDefault))) ||
      tagThemeTemplate !== tagsDefault
    ) {
      return false;
    } else return true;
  }, [
    valueUrlImage,
    themeNameValue,
    themeImageDefault,
    themeTitleDefault,
    KEY,
    checkDuplicateNameTheme,
  ]);

  const handleSaveTheme = () => {
    handleConfirmThemeSaving({
      image: valueUrlImage?.startsWith("http")
        ? valueUrlImage
        : valueImageBase64,
      image_name: valueNameImage,
      title: themeNameValue,
      id: themeTemplateData?.id,
      tags: tagThemeTemplate ?? [],
    });
    createManyTag(tagThemeTemplate);
  };

  const isEditDataStatus = useMemo(() => {
    if (
      themeNameValue !== themeTitleDefault ||
      valueUrlImage !== themeImageDefault
    ) {
      return true;
    } else return false;
  }, [themeNameValue, themeTitleDefault, valueUrlImage, themeImageDefault]);

  const handleCancelSaveTheme = () => {
    if (isEditDataStatus) {
      Modal.confirm({
        header: (
          <InformationIcon
            className="w-11 h-11"
            fillColor="#FF8F1F"
            onClick={() => {}}
          />
        ),
        title: <div>{t("ss_builder_cancel_edit_block_title")}</div>,
        content: (
          <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
            {t("ss_builder_cancel_edit_block_content")}
          </div>
        ),
        confirmText: t("ss_builder_continue_edit_block_label"),
        cancelText: t("ss_builder_cancel_block_btn_label"),
        onConfirm: () => {
          return;
        },
        onCancel: () => handleCancelThemeSaving(),
      });
    } else {
      handleCancelThemeSaving();
    }
  };

  const createManyTag = async (value) => {
    let createTagResponse = null;
    const dataRequest = {
      user_id: userId,
      list_tags: value,
    };
    try {
      createTagResponse = await createManyTagThemeTemplate(dataRequest);
    } catch (error) {
      console.log(error);
    }
    const listTagArr = createTagResponse?.data;
    if (!listTagArr.error) {
      return true;
    }

    return false;
  };

  const handleKeyDown: KeyboardEventHandler = async (event) => {
    if (!inputValueTag) return;

    if (event.key === KEY_ENTER || event.key === KEY_COMMA) {
      const inputValueTagTrim = inputValueTag
        .trim()
        .replaceAll(regexPattern, " ")
        .replaceAll(KEY_COMMA, "");
      if (!suggestions.some((x) => x.value === inputValueTagTrim)) {
        handeSetValueTag([...valueTag, createOption(inputValueTagTrim)]);
        setInputValueTag("");
      } else {
        if (!valueTag.some((x) => x.value === inputValueTagTrim)) {
          handeSetValueTag([...valueTag, createOption(inputValueTagTrim)]);
          setInputValueTag("");
        }
      }

      event.preventDefault();
      return;
    }
  };
  const handeSetValueTag = (value) => {
    const newValue = value.map((ele) => {
      return {
        ...ele,
        value: ele.value
          .trim()
          .replaceAll(regexPattern, " ")
          .replaceAll(KEY_COMMA, ""),
        label: ele.label
          .trim()
          .replaceAll(regexPattern, " ")
          .replaceAll(KEY_COMMA, ""),
      };
    });
    setValueTag(newValue);
    const tagTheme = newValue.map((ele) => {
      return ele.value;
    });
    setTagThemeTemplate(tagTheme);
  };
  const useDebounceSearch = useDebounce(inputValueTag, 500);
  useEffect(() => {
    if (useDebounceSearch) {
      requestTagList(inputValueTag);
    }
  }, [useDebounceSearch]);

  const handeInputChange = (options, { action }) => {
    setInputValueTag(options);
    setIsOpenMenu(options.length > 1);
  };

  return (
    <div className="pb-[60px]">
      <div
        className="flex flex-row text-center items-center justify-center py-3 gap-[10px] relative bg-white"
        style={{
          boxShadow: "inset 0px -1px 0px #EEEEEE",
        }}
      >
        <SaveIcon className="w-[22px] h-[22px]" />
        <div className="text-[18px] leading-6">{titlePageRender}</div>
      </div>
      <div className="px-4 py-3 bg-white">
        <div className="text-[15px] text-[#666666]">
          <span className="text-red-600">*</span>
          {t("ss_builder_add_new_theme_template_image_title")}
        </div>
        <Image
          src={
            valueUrlImage ||
            "https://image.passio.eco/page-builder/page-builder/images-default/image-validate-default-alert.png"
          }
          fit="cover"
          style={{
            backgroundSize: "cover",
            aspectRatio: "16/9",
            border: "1px solid #D9D9D9",
          }}
        />
        {isValidFileFormat && (
          <div className="flex flex-row gap-1 items-center mt-1">
            <InformationTriangleIcon
              fillColor="#FF3141"
              className="w-4 h-4"
            />
            <div className="text-[#FF3141]">
              {t("ss_builder_upload_file_format_invalid_content")}
            </div>
          </div>
        )}
        {isValidFileSize && (
          <div className="flex flex-row gap-1 items-center mt-1">
            <InformationTriangleIcon
              fillColor="#FF3141"
              className="w-4 h-4"
            />
            <div className="text-[#FF3141]">
              {t("ss_builder_upload_file_size_invalid_content")}
            </div>
          </div>
        )}
        <div className="flex gap-2 mt-4">
          <Button
            size="middle"
            color="primary"
            fill="outline"
            className=""
            onClick={valueUrlImage ? handleOpenThemeTemplatePreview : () => {}}
            style={{
              opacity: !valueUrlImage ? "0.4" : "",
            }}
          >
            <PreviewThemeIcon
              className="w-[17px] h-[17px]"
              fillColor="#EE3244"
            />
          </Button>
          <ImageUploader
            onChange={handleImageUrl}
            upload={(file) => mockUpload(file, 514)}
            className={`image-slider-upload ${
              uploadedImageArr.length > 0 ? "image-uploaded-hidden" : ""
            }`}
            value={uploadedImageArr}
            // disableUpload={isDisableUploadBtn}
            children={
              <Button
                block
                color="primary"
                fill="solid"
                size="middle"
                className="w-full grow"
                //   onClick={handleSaveBlockText}
                // disabled={isDisableUploadBtn}
              >
                <div className="flex flex-row gap-2 justify-center items-center">
                  <UploadIcon
                    className="w-[17px] h-[17px]"
                    fillColor="white"
                  />
                  {t("ss_builder_upload_button_label")}
                </div>
              </Button>
            }
          />
        </div>
        <Divider />
        <div>
          <div className="text-[15px] text-[#666666]">
            <span className="text-red-600">*</span>
            {t("ss_builder_add_new_theme_template_name_title")}
          </div>
          <Input
            placeholder={t(
              "ss_builder_add_new_theme_template_name_placeholder"
            )}
            onChange={handleChangeThemeTemplateName}
            value={themeNameValue}
            maxLength={THEME_NAME_MAXIMUM}
          />
          {checkDuplicateNameTheme && (
            <div className="text-[13px] leading-[17px] text-[#FF3141]">
              {t("ss_builder_duplicated_theme_template_name_warning")}
            </div>
          )}
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
            themeNameValue ? themeNameValue.length : 0
          }/${THEME_NAME_MAXIMUM}`}</div>
        </div>
        <Divider />
        <div>
          <div className="text-[15px] text-[#666666] leading-[150%]">
            {t("ss_builder_theme_template_label_tag")}
          </div>
          <div>
            <CreatableSelect
              isClearable
              isMulti
              options={suggestions}
              inputValue={inputValueTag}
              onChange={(newValue) => handeSetValueTag(newValue)}
              onInputChange={(newValue, { action }) =>
                handeInputChange(newValue, { action })
              }
              onKeyDown={handleKeyDown}
              value={valueTag}
              placeholder={t("ss_builder_theme_template_label_tag_placeholder")}
              classNames={{
                control: (state) => `${style.borderTag}`,
              }}
              className="react-select-container"
              classNamePrefix="react-select"
              menuIsOpen={isOpenMenu}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              formatCreateLabel={(userInput) =>
                `${t(
                  "ss_builder_theme_template_format_create_label"
                )} "${userInput}"`
              }
              noOptionsMessage={() => null}
            />
          </div>
        </div>
      </div>

      <div className="max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[1] bg-[#FFFFFF]">
        <div className="w-1/2 text-center">
          <Button
            className="w-full"
            onClick={handleCancelSaveTheme}
          >
            {t("ss_builder_editor_btn-cancel")}
          </Button>
        </div>
        <div className="w-1/2 text-center">
          <Button
            className="w-full"
            color={"primary"}
            onClick={() => handleSaveTheme()}
            disabled={isDisableSaveBtn}
          >
            {buttonSaveLabelRender}
          </Button>
        </div>
      </div>
      {isOpenThemeTemplatePreview && (
        <FullScreenPopup
          visible={isOpenThemeTemplatePreview}
          onMaskClick={() => {
            setIsOpenThemeTemplatePreview(false);
          }}
        >
          <ThemeTemplatePreviewByImage
            handleCancelThemeTemplatePreview={() =>
              setIsOpenThemeTemplatePreview(false)
            }
            imageSrc={valueUrlImage}
            KEY={THEME_PREVIEW_BY_IMAGE_KEY.PREVIEW}
          />
        </FullScreenPopup>
      )}
    </div>
  );
};

export default ThemeTemplateSaving;
