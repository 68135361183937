import React from "react";
import { Modal, Button } from "antd-mobile";
import Portal from "@share/HOC/portal";
import { useTranslation } from "react-i18next";

interface ModalBrandRatingGoliveProps {
  visible: boolean;
  onAddBlock: () => void;
  onReject: () => void;
}

const ModalBrandRatingGolive: React.FunctionComponent<
  ModalBrandRatingGoliveProps
> = (props) => {
  const { visible, onAddBlock, onReject } = props;
  const { t } = useTranslation();
  const image =
    "https://i.ecomobi.com/ssp/mini-store/Brand-Rating/Brand-rating-popup.png";

  const ModalContent = () => (
    <div className="h-full w-full bg-[#fff] text-center">
      <div className="mb-2 text-[18px] leading-[25px] font-bold text-[#333333]">
        {t("ss_builder_intro_brand_rating_floating_title")}
      </div>
      <p className="text-[15px] font-normal leading-[140%] text-textColorDefault text-[#333333]">
        {t("ss_builder_intro_brand_rating_floating_description")}
      </p>
      <div className="mt-5">
        <Button
          color="primary"
          size="middle"
          fill="solid"
          block
          onClick={onAddBlock}
        >
          <span>{t("ss_builder_intro_brand_gift_floating_continue")}</span>
        </Button>
        <Button
          style={{
            marginTop: "8px",
            border: "1px solid #EEEEEE"
          }}
          size="middle"
          block
          onClick={onReject}
          fill="none"
        >
          <span>{t("ss_builder_intro_brand_gift_floating_reject")}</span>
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <Portal elementId="modalPortal">
        <Modal
          className="modal-brand-gift-golive"
          visible={visible}
          forceRender={true}
          onClose={onReject}
          closeOnMaskClick={true}
          image={image}
          content={<ModalContent />}
        />
      </Portal>
    </>
  );
};

export default ModalBrandRatingGolive;
