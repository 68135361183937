import React, { Fragment, useMemo } from "react";
import {
  BACKGROUND_SETTING_DEFAULT_VALUE,
  BLOCK_SETTING_DEFAULT_VALUE,
  FONT_OPTIONS,
  GG_FONT_URI,
  PARAGRAPH_SETTING_DEFAULT_VALUE,
} from "@share/configs/const";
import { useTranslation } from "react-i18next";
import Head from "next/head";
import { useAppSelector } from "@app/hooks";
import { SpinLoading } from "antd-mobile";

export default function Preview({
  paragraphSettingData,
  background,
  blockSetting,
}) {
  const BACKGROUND_TYPE_IMAGE = "image";
  const BACKGROUND_TYPE_SOLID = "solid";
  const BACKGROUND_TYPE_GRADENT = "gradient";
  const BACKGROUND_TYPE_COLOR = "color";
  const { t } = useTranslation();

  const isFontLoading = useAppSelector(
    (state) => state?.externalState?.is_font_loading || false
  );

  const {
    font_family_preview = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_FAMILY,
    font_color = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_COLOR,
    scale = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_SCALE,
    font_size = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_SIZE,
    font_weight = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_WEIGHT,
    line_height = PARAGRAPH_SETTING_DEFAULT_VALUE.LINE_HEIGHT,
    using_font_custom = PARAGRAPH_SETTING_DEFAULT_VALUE.USING_FONT_CUSTOM,
    font_family_custom = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_FAMILY_CUSTOM,
  } = paragraphSettingData || {};

  const lead1 = font_size * scale * scale;
  const lead2 = font_size * scale;
  const body = font_size;
  const small = font_size / scale;
  const fontSizeArr = [lead1, lead2, body, small];
  const titleParagrah = (index) => {
    switch (index) {
      case 1:
        return "Lead 2";
      case 2:
        return "Body";
      case 3:
        return "Small";
      default:
        return "Lead 1";
    }
  };

  const {
    type,
    color,
    gradient,
    image,
    enable_overlay,
    overlay_color,
    blur: blurBackgroundSetting = BACKGROUND_SETTING_DEFAULT_VALUE.BLUR,
  } = background || {};

  const { block_style, block_background, block_border, block_shadow } =
    blockSetting || {};
  const {
    radius_type = BLOCK_SETTING_DEFAULT_VALUE.BLOCK_STYLE,
    radius_value = BLOCK_SETTING_DEFAULT_VALUE.BORDER_RADIUS,
    inner_space = BLOCK_SETTING_DEFAULT_VALUE.INNER_SPACE,
    inner_space_number = BLOCK_SETTING_DEFAULT_VALUE.INNER_SPACE_NUMBER,
    outside_space = BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE,
  } = block_style || {};
  const {
    color: blockBackgroundColor = BLOCK_SETTING_DEFAULT_VALUE.BACKGROUND_COLOR,
    blur = BLOCK_SETTING_DEFAULT_VALUE.BACKGROUND_BLUR,
  } = block_background || {};
  const {
    enable: borderEnable = BLOCK_SETTING_DEFAULT_VALUE.BORDER_ENABLE,
    border_width = BLOCK_SETTING_DEFAULT_VALUE.BORDER_WIDTH,
    color: borderColor = BLOCK_SETTING_DEFAULT_VALUE.BORDER_COLOR,
    type: borderType = BLOCK_SETTING_DEFAULT_VALUE.BORDER_STYLE,
  } = block_border || {};
  const {
    enable: shadowEnable = BLOCK_SETTING_DEFAULT_VALUE.SHADOW_ENABLE,
    color: shadowColor = BLOCK_SETTING_DEFAULT_VALUE.SHADOW_COLOR,
  } = block_shadow || {};

  const boxShadowStyle =
    shadowEnable === true ? { boxShadow: "0px 4px 4px " + shadowColor } : {};

  const borderRadiusValue = useMemo(() => {
    let value = "0px";
    if (radius_type === "rounded") {
      value = "8px";
    } else if (radius_type === "custom") {
      value = `${radius_value}px`;
    }
    return value;
  }, [radius_type, radius_value]);

  const styleBorder =
    borderEnable === true
      ? {
          borderWidth: border_width + "px",
          borderStyle: borderType,
          borderColor: borderColor,
        }
      : { border: "none" };
  const styleBackgroundImage =
    type === BACKGROUND_TYPE_IMAGE
      ? {
          backgroundPosition: "center center",
          filter: `blur(${blurBackgroundSetting}px) `,
          WebkitFilter: `blur(${blurBackgroundSetting}px) `,
          width: `calc(100% + ${blurBackgroundSetting * 2}px) `,
          height: `calc(100% + ${blurBackgroundSetting * 2}px) `,
          transform: `translate(-${blurBackgroundSetting}px, -${blurBackgroundSetting}px)`,
        }
      : "";
  const checkStyleBackgroundImage = () => {
    switch (type) {
      case BACKGROUND_TYPE_IMAGE:
        return `url("${image}")`;
      case BACKGROUND_TYPE_GRADENT:
        return gradient;
      default:
        return "";
    }
  };
  const innerSpaceNumber =
    typeof inner_space_number === "number"
      ? `${inner_space_number}px`
      : inner_space
      ? `${BLOCK_SETTING_DEFAULT_VALUE.INNER_SPACE_NUMBER}px`
      : 0;
  const styleOutsideSpace = outside_space
    ? {
        marginLeft: `${BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER}px`,
        marginRight: `${BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER}px`,
        width: `calc(100% - ${
          BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER * 2
        }px) `,
      }
    : "";

  const fontLinkArr = useMemo(() => {
    return FONT_OPTIONS.filter((x) => x?.value !== font_family_preview);
  }, [font_family_preview]);

  return (
    <>
      <Head>
        {/* Tự động thêm các link google font vào thẻ head để hiển thị đúng dạng font trong selection cho người dùng lựa chọn */}
        {fontLinkArr?.map((font) => (
          <Fragment key={font.value}>
            <link
              href={`${GG_FONT_URI}?family=${font.value.replace(/\s+/g, "+")}`}
              rel="stylesheet"
            />
          </Fragment>
        ))}
      </Head>
      <div className="mb-2 bg-white">
        {/*<div className="text-[#666666] text-[15px]">*/}
        {/*  {t("ss_builder_preview")}*/}
        {/*</div>*/}

        <div className="mb-3 mt-1 pt-4 pb-4 w-full h-full relative overflow-hidden">
          {type === BACKGROUND_TYPE_IMAGE && enable_overlay === true ? (
            <div
              className="absolute top-0 left-[50%] translate-x-[-50%] block w-full h-full z-[1]"
              style={{ backgroundColor: overlay_color }}
            ></div>
          ) : (
            ""
          )}

          <div
            className="absolute top-0 left-0 w-full h-full bg-cover bg-no-repeat object-cover"
            style={{
              backgroundColor:
                type === BACKGROUND_TYPE_SOLID || type === BACKGROUND_TYPE_COLOR
                  ? color
                  : "",
              backgroundImage: `${checkStyleBackgroundImage()}`,
              borderBottom: "1px solid var(--adm-color-border)",
              ...styleBackgroundImage,
            }}
          ></div>
          <div
            className="relative z-[3]"
            style={{ ...styleOutsideSpace, opacity: isFontLoading ? 0 : "" }}
          >
            <div
              className={"pt-3 pb-3"}
              style={{
                backgroundColor: blockBackgroundColor,
                borderRadius: borderRadiusValue,
                backdropFilter: `blur(${blur}px) `,
                WebkitBackdropFilter: `blur(${blur}px) `,
                paddingLeft: innerSpaceNumber,
                paddingRight: innerSpaceNumber,
                ...styleBorder,
                ...boxShadowStyle,
              }}
            >
              <div className="mt-1 preview-text-block ">
                <div className="pr-3 grid overflow-hidden">
                  <ul className=" table w-full  overflow-hidden">
                    {fontSizeArr &&
                      fontSizeArr.map((ele, index) => (
                        <li
                          key={index}
                          className="table-row item-preview-text"
                        >
                          <div
                            className="ml-3 w-[50px] pr-2 table-cell align-middle whitespace-nowrap"
                            style={{
                              color: font_color,
                              opacity: 0.8,
                            }}
                          >
                            {`${titleParagrah(index)}`}
                          </div>
                          <div
                            className="table-cell whitespace-nowrap py-2 scale-preview-text align-middle"
                            style={{
                              color: font_color,
                              fontSize: ele,
                              lineHeight: line_height,
                              fontWeight: font_weight,
                              fontFamily: font_family_preview,
                            }}
                          >
                            {t("ss_builder_deep_blue_sky")}
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {isFontLoading && (
            <SpinLoading
              color={`${font_color}`}
              style={{ "--size": "48px" }}
              className="absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] z-[3]"
            />
          )}
        </div>
      </div>
    </>
  );
}
