import { BLOCK_SETTING_DEFAULT_VALUE } from "@share/configs/const";
import { Divider } from "antd-mobile";
import { default as React } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import MappingTypeComponent from "@features/design/components/MappingTypeComponent";
import { Block } from "@features/main/store/interface";
import cls from "classnames";
import PlaceholderCustom, { PlaceholderProps } from "../../PlaceholderCustom";
import isEmpty from "lodash/isEmpty";
import { useAppSelector } from "@app/hooks";
import BlockDraggingSpace from "../../BlockDraggingSpace";

interface BlockGroupProps {
  isDrag: boolean;
  blocks: Array<Block>;
  positionSlug: string;
  addNewBlockButtonDrag: boolean;
  listOrderedBlock: Array<Block>;
  droppableId: string;
  placeholderProps: PlaceholderProps;
}

const BlockGroup: React.FunctionComponent<BlockGroupProps> = (props) => {
  const {
    blocks,
    positionSlug,
    listOrderedBlock,
    addNewBlockButtonDrag,
    droppableId,
    isDrag,
    placeholderProps,
  } = props;

  const block_style = useAppSelector(
    (state) => state.siteSettings?.display_setting?.block_setting?.block_style
  );

  const {
    block_space = BLOCK_SETTING_DEFAULT_VALUE.BLOCK_SPACE,
    outside_space,
  } = (block_style as any) ?? {};

  const isDropDisabled =
    (!isDrag && !addNewBlockButtonDrag) ||
    (addNewBlockButtonDrag && blocks?.length === 0);

  const placeholderPropsCustom = {
    ...placeholderProps,
    clientY: placeholderProps.clientY + 12,
  };

  return (
    <>
      <Droppable
        droppableId={droppableId}
        isDropDisabled={isDropDisabled}
      >
        {(provided, snapshot): JSX.Element => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {blocks.length > 0 &&
              blocks.map((block, index) => {
                return (
                  <section
                    id={block.uid}
                    key={block.uid}
                  >
                    <Draggable
                      draggableId={`designs-${block.uid}`}
                      index={index}
                      key={`designs-${block.uid}`}
                    >
                      {(provided, snapshot): JSX.Element => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={cls(
                            // "outside-space",
                            snapshot.isDragging &&
                              "adm-item-custom-design blur-none"
                          )}
                        >
                          <BlockDraggingSpace
                            addNewBlockButtonDrag={addNewBlockButtonDrag}
                          >
                            <MappingTypeComponent
                              blockData={block}
                              positionSlug={positionSlug}
                              index={index}
                              listBlocksSortOrder={listOrderedBlock}
                            />
                          </BlockDraggingSpace>
                          {!addNewBlockButtonDrag &&
                            index !== blocks?.length - 1 && (
                              <Divider
                                style={{
                                  color: "#1890FF",
                                  borderColor: "#1890FF",
                                  borderStyle: "dashed",
                                  height: `${block_space}px`,
                                  margin: "0px",
                                  visibility:
                                    isDrag ||
                                    addNewBlockButtonDrag ||
                                    index === blocks.length - 1
                                      ? "hidden"
                                      : "unset",
                                  opacity: isDrag ? 0 : 1,
                                  paddingLeft: outside_space
                                    ? `${BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER}px`
                                    : "",
                                  paddingRight: outside_space
                                    ? `${BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER}px`
                                    : "",
                                }}
                              />
                            )}
                        </div>
                      )}
                    </Draggable>
                  </section>
                );
              })}
            {provided.placeholder}
            {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
              <PlaceholderCustom
                placeholderProps={placeholderPropsCustom}
                isFullBlock={listOrderedBlock?.length >= 5}
              />
            )}
          </div>
        )}
      </Droppable>
      {/* <div
        style={{
          height: `${block_space}px`,
          marginTop: `-${block_space}px`,
          marginBottom: `-${block_space}px`,
        }}
      /> */}
    </>
  );
};

export default BlockGroup;
