import React, { useEffect } from "react";

const GenerateColor = ({ generatorSetting }) => {
  const { primary_color_generate, secondary_color_generate } = generatorSetting;

  useEffect(() => {
    for (var i = 1; i <= 10; i++) {
      if (primary_color_generate[`primary-${i}`]) {
        document.documentElement.style.setProperty(
          `--primary-color-${i}`,
          primary_color_generate[`primary-${i}`]
        );
      }
      if (secondary_color_generate[`secondary-${i}`]) {
        document.documentElement.style.setProperty(
          `--secondary-${i}`,
          secondary_color_generate[`secondary-${i}`]
        );
      }
    }
  }, [primary_color_generate, secondary_color_generate]);
  return null;
};

export default GenerateColor;
