import { FONT_OPTIONS } from "@share/configs/const";
import { Selector } from "antd-mobile";
import React from "react";

const COMMON_FONT_FAMILYS = FONT_OPTIONS.slice(0, 6);

export default function CommonFontSelector(props: {
  selectedValue: string;
  onSelect: (val) => void;
}) {
  const { selectedValue, onSelect } = props;
  const fontOptions = COMMON_FONT_FAMILYS.map((item) => {
    return {
      ...item,
      label: <span style={{ fontFamily: item.value }}>{item.label}</span>,
    };
  });
  const onChangeOption = (vals) => {
    if (vals.length) {
      onSelect(vals[0]);
    }
  };
  return (
    <Selector
      columns={2}
      options={fontOptions}
      value={[selectedValue]}
      onChange={onChangeOption}
      style={{
        "--border": "solid transparent 1px",
        "--checked-border": "solid var(--adm-color-primary) 1px",
        "--checked-color": "#FFFFFF",
      }}
    />
  );
}
