import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";
import {selectProfile} from "@features/user/userSlice";
import {useAppSelector} from "@app/hooks";
import { Button, ImageUploader, Toast } from "antd-mobile";
import { AUTH_PAGE } from "@share/configs/pages";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import { KEY_TOKEN } from "@share/configs/system";

export default function RejectAccess(props) {
  const { t } = useTranslation();
  const router = useRouter();
  const customerToken = Cookies.get(KEY_TOKEN)
  const login = () => {
    router.push(AUTH_PAGE.login)
  }
  return (
    <div className="flex flex-col items-center justify-center">
        <img src="https://i.ecomobi.com/ssp/brand-rating/noAccessAllow.jpg" />
        <span className="mt-2 text-[15.6px] font-normal">
            {t('ss_brand_cpc_no_access_allow')}
        </span>
        {!customerToken
        ? <div className="mt-6">
              <Button color="primary" fill="solid" size="middle" className="w-fit" onClick={login}>
                  {t("login")}
              </Button>
          </div>
        : <></>
        }
    </div>
  )
}