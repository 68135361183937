import { stepsInterface } from "@share/components/ProductTourComponent/interface";

export const SETTING_TOUR_CLASS_NAME = {
  STEP_1: "setting-tour-link",
  STEP_2: "setting-tour-setting-interface",
  STEP_3: "setting-tour-setting-page",
};

export const STEP_INDEX = {
  STEP_1: 0,
  STEP_2: 1,
  STEP_3: 2,
};

export const steps: stepsInterface[] = [
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/setting-tour/setting-tour-1.gif",
    title_key: "ss_builder_setting_tour_title_step_1",
    intro_key: "ss_builder_setting_tour_content_step_1",
    element_class: SETTING_TOUR_CLASS_NAME.STEP_1,
    tooltip_class: "show-label-text",
    position: "bottom",
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/setting-tour/setting-tour-2.gif",
    title_key: "ss_builder_setting_tour_title_step_2",
    intro_key: "ss_builder_setting_tour_content_step_2",
    element_class: SETTING_TOUR_CLASS_NAME.STEP_2,
    tooltip_class: "",
    position: "bottom",
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/setting-tour/setting-tour-3.gif",
    title_key: "ss_builder_setting_tour_title_step_3",
    intro_key: "ss_builder_setting_tour_content_step_3",
    element_class: SETTING_TOUR_CLASS_NAME.STEP_3,
    tooltip_class: "auto show-label-text product-tour-custom",
    position: "top",
  },
];
